import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { getOrders } from "../../../utils/api";
import BOOK from "../../../images/Book.gif";
import { toast } from "react-toastify";
import axios from "axios";
import CustomToastContent from "../../../components/CustomToastContent";
import SuccessIcon from "../../../images/success_icon.svg";
import InfoIcon from "../../../images/info_icon.svg";

const VirtualOrdersDesktop = () => {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const access_token = localStorage.getItem("access_token");
  const broker = "virtual";
  const client_id = localStorage.getItem("client_id");
  // const [symbol, setSymbol] = useState("");
  // const [qty, setQty] = useState(0);
  // this state is added to keep the track of checkbox
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getOrders(access_token, client_id, broker);
        console.log("My orders are:", response);
        if (response) {
          setOrders(response);
        } else {
          setOrders([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const statusValue = (status) => {
    switch (status) {
      case 1:
        return "Canceled";
      case 2:
        return "Traded / Filled";
      case 3:
        return "Not used currently";
      case 4:
        return "Transit";
      case 5:
        return "Rejected";
      case 6:
        return "Pending";
      case 7:
        return "Expired";
      default:
        return "";
    }
  };

  //changing date format. In received object it is different than what is shown in design
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const time = dateTime.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    const date = dateTime.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    return `${time}, ${date}`;
  };

  const filteredOrders = orders.filter((order) => {
    if (filter === "executed" && order.status !== "Executed") {
      return false; // Skip non-executed orders
    }
    if (filter === "pending" && order.status !== "Pending") {
      return false; // Skip non-pending orders
    }
    if (filter === "rejected" && order.status !== "Rejected") {
      return false; // Skip non-rejected orders
    }
    return true;
  });

  const isActiveButton = (buttonName) => {
    return filter === buttonName ? "active" : "";
  };

  // const hasPendingOrders = filteredOrders.some((order) => order.status === 6)/

  useEffect(() => {
    const hasPendingOrders = filteredOrders.some(
      (order) => order.status === "Pending"
    );
    setCheckboxEnabled(hasPendingOrders);
  }, [filteredOrders]);

  const handleCheckboxChange = (orderId) => {
    const order = orders.find((order) => order.id === orderId);
    // console.log("Order:", order);
    if (order && order.status === "Pending") {
      const isChecked = selectedCheckboxes.includes(orderId);
      if (isChecked) {
        setSelectedCheckboxes(
          selectedCheckboxes.filter((id) => id !== orderId)
        );
        setOrderList(orderList.filter((id) => id !== orderId));
      } else {
        setSelectedCheckboxes([...selectedCheckboxes, orderId]);
        setOrderList([...orderList, order]);
      }
    }
  };

  const handleCancelSelected = async () => {
    try {
      if (selectedCheckboxes.length > 0) {
        const response = await axios.post(
          "https://9pmtao5xc1.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_cancelorders",
          {
            broker: broker,
            access_token: access_token,
            client_id: client_id,
            order_list: orderList,
          }
        );

        if (response.data.status) {
          // If the response status is true, fetch the updated orders
          const updatedOrders = await getOrders(
            access_token,
            client_id,
            broker
          );
          setOrders(updatedOrders);
        } else {
          console.log("Failed to cancel orders:", response.data.message);
          toast.error(
            <CustomToastContent
              heading="Failed to cancel orders!"
              message={response.data.message}
            />,
            {
              className: "custom-error-toast",
              icon: <img src={InfoIcon} alt="Success Icon" />,
            }
          );
        }
      } else {
        console.log("No orders selected to cancel.");
      }
    } catch (error) {
      console.error("Error cancelling orders:", error);
      return "";
    } finally {
      setSelectedCheckboxes([]);
      setOrderList([]);
    }
  };

  const handleOKClick = async () => {
    try {
      await handleCancelSelected(); // Wait for the cancellation process to complete
      const overlay = document.querySelector(".overlay");
      overlay.classList.remove("active");
      toast.success(
        <CustomToastContent
          heading="Order cancelled successfully!"
          message="Successfully cancelled all selected orders!"
        />,
        {
          className: "custom-success-toast",
          icon: <img src={SuccessIcon} alt="Success Icon" />,
        }
      );
    } catch (error) {
      console.error("Error handling cancel operation:", error);
    }
    console.log("Orders after cancellig", orders);
  };

  const handleCloseClick = () => {
    //console.log("Clicked closed!");
    const overlay = document.querySelector(".overlay");
    overlay.classList.remove("active"); // Remove the "active" class to hide the overlay
    toast.dismiss();
  };

  const showToast = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.add("active"); // Add the "active" class to show the overlay

    toast(
      <div className="d-flex align-items-center justify-content-between">
        <div>Sure, you want to delete this order?</div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              onClick={handleCloseClick}>
              <path
                d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                stroke="#FF3908"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="confirm-popup-ok d-flex justify-content-center align-items-center"
            onClick={handleOKClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none">
              <path
                d="M1 6.65685L6.65685 12.3137L17.9706 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>,
      {
        position: "bottom-center",
        closeButton: false, // Hide the built-in close button
        icon: false,
        className: "confirm-popup-toast", // Apply the custom CSS class
        hideProgressBar: true,
        draggable: true,
        autoClose: false,
      }
    );
  };

  const showErrorToast = () => {
    toast.error(
      <CustomToastContent
        heading="Failed to cancel orders!"
        message="We cannot cancel this order as for now!"
      />,
      {
        className: "custom-error-toast",
        icon: <img src={InfoIcon} alt="Info Icon" />,
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className="overlay"></div>
      <div className="orders-page">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            <div className="btn-group-1 d-flex">
              <button
                className={`btn-1 all ${isActiveButton("all")}`}
                onClick={() => setFilter("all")}>
                All
              </button>
              <button
                className={`btn-1 executed ${isActiveButton("executed")}`}
                onClick={() => setFilter("executed")}>
                Executed
              </button>
              <button
                className={`btn-1 pending ${isActiveButton("pending")}`}
                onClick={() => setFilter("pending")}>
                Pending
              </button>
              <button
                className={`btn-1 rejected ${isActiveButton("rejected")}`}
                onClick={() => setFilter("rejected")}>
                Rejected
              </button>
            </div>
          </div>
          <div>
            <button
              className={`btn-1 exit ${
                selectedCheckboxes.length > 0 ? "enabled" : "disabled"
              }`}
              disabled={selectedCheckboxes.length === 0}
              onClick={showToast}>
              Cancel Selected
            </button>
          </div>
        </div>
        {loading && (
          <div className="loading-container text-center">
            <img src={BOOK} alt="Loading..." />
          </div>
        )}
        {filteredOrders.length === 0 ? (
          <h2 className="mt-5 fw-bolder text-center">
            You don't have any {filter !== "all" ? filter : ""} orders!
          </h2>
        ) : (
          <table className="table table-borderless mt-3">
            <thead className="table-head">
              <tr className="mb-5">
                <th scope="col">
                  <input className="form-check-input" type="checkbox" />
                </th>
                <th scope="col">Instrument</th>
                <th scope="col">Product</th>
                <th scope="col">Type</th>
                <th scope="col">Filled Qty / Qty</th>
                <th scope="col">Avg price</th>
                <th scope="col">Trigger price</th>
                <th scope="col">B/S</th>
                <th scope="col">Order time</th>
                <th scope="col">Order ID</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <tr key={order.id}>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={order.status !== "Pending"} // Disable for non-pending orders
                      checked={selectedCheckboxes.includes(order.id)}
                      onChange={() => handleCheckboxChange(order.id)}
                    />
                  </td>
                  <td>{order.symbol}</td>
                  <td className="my-1">
                    <div className="product text-center d-flex align-items-center justify-content-center">
                      {order.productType}
                    </div>
                  </td>
                  {/* <td className="product text-center">{order.productType}</td> */}
                  <td>Market</td>
                  <td className="text-center">{`${order.filledQty}/${order.qty}`}</td>
                  <td className="text-center">{order.tradedPrice}</td>
                  <td className="text-center">{order.stopPrice}</td>
                  <td
                    className={
                      order.side === -1 ? "text-danger" : "text-success"
                    }>
                    {order.side === -1 ? "SELL" : "BUY"}
                  </td>
                  <td>{order.orderDateTime}</td>
                  <td>{order.id}</td>
                  <td>
                    <div className="d-flex">
                      <span>{order.status}</span>
                      <span
                        role="button"
                        className="ms-2 info-icon rounded-circle d-flex align-items-center justify-content-center"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={order.message}>
                        i
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {/* <button onClick={showErrorToast}>Show error popup</button> */}
    </>
  );
};

export default VirtualOrdersDesktop;
