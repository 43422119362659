import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Helmet } from "react-helmet-async";
import shield from "../../images/shield.svg";
import pencil from "../../images/pencil.svg";
import questionmark from "../../images/questionmark.svg";
import PositionsTable from "../../components/PositionsTable";
import axios, { all } from "axios";
import BuySellModal from "../../modals/BuySellModal";
import ABWebSocketContext from "../../context/ABWebsocketContext";
import { getSymbols } from "../../utils/api";
import { useVisibility } from "../../context/VisibilityContext";
import AnalyseMobile from "../../modals/AnalyseMobile";
import OptionChainMobile from "../../screens/OptionChain/OptionChainMobile";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGraphContext } from "../../context/GraphContext";
import PositionMetricsComputations from "../../components/PositionMetricsComputations";


import CustomToastContent from "../../components/CustomToastContent";
import SuccessIcon from "../../images/success_icon.svg";
import InfoIcon from "../../images/info_icon.svg";


const access_token = localStorage.getItem("access_token");
const client_id = localStorage.getItem("client_id");
const broker = localStorage.getItem("broker");

let triggerSubscribe = true;
let connectionEstablised = false;

const PositionsMobile = () => {
  const [activeButton, setActiveButton] = useState("groupbyunderlying");
  // This useEffect will be executed once when the component mounts
  const [metrics, setMetrics] = useState({
    total_positions: 0,
    open_positions: 0,
  });
  const [nsesubscribeSymbols, setNSESubscribeSymbols] = useState({});
  const [nfosubscribeSymbols, setNFOSubscribeSymbols] = useState({});
  const [allPositions, setAllPositions] = useState([]);
  const [strategyBased, setStrategyBased] = useState([]);
  const [underlyingBased, setUnderlyingBased] = useState([]);
  const [symbols, setSymbols] = useState([]);

  const [netProfit, setNetProfit] = useState(0);
  const [potentialProfit, setPotentialProfit] = useState(0);
  const [potentialLoss, setPotentialLoss] = useState(0);
  const [pendingProfit, setPendingProfit] = useState(0);
  const [achievedPercent, setAchievedPercent] = useState(0);
  const [selectedOption, setSelectedOption] = useState("buy");
  const metricsComputations = PositionMetricsComputations();
  const handleBuyButtonClick = () => {
    setSelectedOption("buy");
  };

  const handleSellButtonClick = () => {
    setSelectedOption("sell");
  };

  const [modalData, setModalData] = useState(null);
  const handleClick = (data) => {
    setModalData(data);
  };

  let totalMaxProfit = 0;
  let totalMaxLoss = 0;

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(ABWebSocketContext);
  const {
    x_axis,
    y_axis,
    graphData,
    maxProfit,
    maxLoss,
    riskReward,
    breakevenRange,
    strategyDirection,
  } = useGraphContext();
  
  useEffect(() => {
    fetchPositions();
  }, []);

  const fetchPositions = async () => {
    const apiEndpoint = `https://y0tp4rr2a5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_positions`;
    const body = {
      access_token: access_token,
      broker: broker,
      client_id: client_id,
    };
    try {
      const response = await axios.post(apiEndpoint, body);
      if (response.data?.nse_subscribe_symbols) {
        setNSESubscribeSymbols(response.data?.nse_subscribe_symbols);
        setNFOSubscribeSymbols(response.data?.nfo_subscribe_symbols);
        setMetrics(response.data?.metrics);
      }
      setAllPositions(response.data.all_positions);
      const symbols = await getSymbols();
      setSymbols(symbols);
      setUnderlyingBased(response?.data.groupby_underlying);
      setStrategyBased(response?.data.groupby_strategy); //
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  const exitPositions = async (positions) => {
    const apiEndpoint = "https://snji3aqoq9.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_exitpositions";
    const body = {
      access_token: access_token,
      broker: broker,
      client_id: client_id,
      positions: positions
    };
    try {
      const response = await axios.post(apiEndpoint, body);
      if (response?.data.status) {
        setUnderlyingBased([]);
        setStrategyBased([]);
        fetchPositions();
        toast.success(
          <CustomToastContent
            heading={response.data.message_header}
            message={response.data.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.data.message_header}
            message={response.data.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={InfoIcon} alt="Success Icon" />,
          }
        );
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    computeCardMetrics(underlyingBased, "underlying");
  }, [underlyingBased]);

  useEffect(() => {
    computeCardMetrics(strategyBased, "strategy");
  }, [strategyBased]);

  const handleCallback = async ([rowData]) => {};

  const callBackToUpdateAlloption = (newOptions) => {};

  const computeCardMetrics = (group_data, type) => {
    if (type == "underlying") {
      let totalMaxLoss = 0;
      let totalMaxProfit = 0;
      let achievedPer = 0;

      for (let i = 0; i < group_data.length; i++) {
        let data = group_data[i];
        if (!data.hasOwnProperty("barType")) {
          const computedData = metricsComputations.computeXandYAxis({
            selectedData: data?.positions,
            ltp: symbolData[data?.code]?.ltp,
            leastExpiry: null,
          });
          data.breakeven = computedData?.breakevenRange;
          let resp = calculatePointerPosition(
            computedData?.greenRange,
            computedData
          );
          data.greenBarWidth = resp?.width;
          data.leftSpace = resp?.leftSpace;
          data.pointerPosition = resp?.pointerPosition;
          data.x_axis = computedData?.x_axis;
          data.barType = resp?.barType;
          data.maxProfit = computedData?.maxProfit;
          data.maxLoss = computedData?.maxLoss;
          data.computedData = computedData;
          underlyingBased[i] = data;
          setUnderlyingBased(underlyingBased);

          if (computedData?.maxLoss === "Unlimited") {
            totalMaxLoss = "Unlimited";
          }
          if (computedData?.maxProfit === "Unlimited") {
            totalMaxProfit = "Unlimited";
          }

          if (totalMaxLoss !== "Unlimited") {
            totalMaxLoss = totalMaxLoss + computedData?.maxLoss;
          }
          if (totalMaxProfit !== "Unlimited") {
            totalMaxProfit = totalMaxProfit + computedData?.maxProfit;
          }
        }
      }
      setPotentialLoss(totalMaxLoss);
      setPotentialProfit(totalMaxProfit);
    } else if (type == "strategy") {
      for (let i = 0; i < group_data.length; i++) {
        let data = group_data[i];
        if (!data.hasOwnProperty("barType")) {
          const computedData = metricsComputations.computeXandYAxis({
            selectedData: data?.positions,
            ltp: symbolData[data?.code]?.ltp,
            leastExpiry: null,
          });
          data.breakeven = computedData?.breakevenRange;
          let resp = calculatePointerPosition(
            computedData?.greenRange,
            computedData
          );
          data.greenBarWidth = resp?.width;
          data.leftSpace = resp?.leftSpace;
          data.pointerPosition = resp?.pointerPosition;
          data.x_axis = computedData?.x_axis;
          data.barType = resp?.barType;
          data.maxProfit = computedData?.maxProfit;
          data.maxLoss = computedData?.maxLoss;
          data.computedData = computedData;
          strategyBased[i] = data;
          setStrategyBased(strategyBased);
        }
      }
    }
  };

  useEffect(() => {
    let pendingProfit = 0;
    let totalPnl = 0;
    allPositions?.map((position, index) => {
      let ltp = symbolData[position?.code]?.ltp;
      if (ltp) {
        let pnl =
          (parseFloat(ltp) - parseFloat(position?.average_prc)) *
          parseFloat(position?.net_quantity);
        totalPnl = totalPnl + pnl;
      }
    });
    setNetProfit(totalPnl.toFixed(2));
    if (potentialProfit !== "Unlimited") {
      pendingProfit = potentialProfit - totalPnl;
      setPendingProfit(pendingProfit.toFixed(2));
      setAchievedPercent(((totalPnl / potentialProfit) * 100).toFixed(2) + "%");
    }
  }, [symbolData, allPositions, potentialProfit]);

  useLayoutEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised) {
      if (triggerSubscribe) {
        if (Object.keys(nsesubscribeSymbols).length !== 0) {
          subscribeToSymbols(nsesubscribeSymbols, "NSE");
        }
        if (Object.keys(nfosubscribeSymbols).length !== 0) {
          subscribeToSymbols(nfosubscribeSymbols, "NFO");
        }
        if (
          Object.keys(nfosubscribeSymbols).length !== 0 &&
          Object.keys(nsesubscribeSymbols).length !== 0
        ) {
          triggerSubscribe = false;
        } else {
          triggerSubscribe = true;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData, triggerSubscribe, nsesubscribeSymbols, nfosubscribeSymbols]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    setActiveTable(button);
    // Update the exit button enabled status
    setExitButtonEnabled(isAnyCheckboxChecked);
  };

  const [showButtons, setShowButtons] = useState(false);

  const handleMouseEnter = () => {
    setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setShowButtons(false);
  };

  // For tables in watchlis
  const [showTables, setShowTables] = useState(false);
  const handleToggleTables = () => {
    setShowTables((prevShowTables) => !prevShowTables);
    setShowButtons(true);
  };

  const [selectedAllPositions, setSelectedAllPositions] = useState([]);
  const [selectedUnderlying, setSelectedUnderlying] = useState([]);
  const [selectedExecutedStrategies, setSelectedExecutedStrategies] = useState(
    []
  );

  const [
    isExitSelectedEnabledAllPositions,
    setIsExitSelectedEnabledAllPositions,
  ] = useState(false);

  const [isExitSelectedEnabledUnderlying, setIsExitSelectedEnabledUnderlying] = useState([false]);
  const [isExitSelectedEnabledExecutedStrategies, setIsExitSelectedEnabledExecutedStrategies] = useState([false]);

  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

  const [exitButtonEnabled, setExitButtonEnabled] = useState(false);

  // Define state variables for each table
  const [isAllPositionsButtonsVisible, setIsAllPositionsButtonsVisible] =
    useState(false);
  const [isUnderlyingButtonsVisible, setIsUnderlyingButtonsVisible] =
    useState(false);

  const [
    isExecutedStrategiesButtonsVisible,
    setIsExecutedStrategiesButtonsVisible,
  ] = useState(false);

  // Function to check and set the visibility of buttons for each table
  const checkCheckboxStatus = () => {
    const isAllPositionsChecked = selectedAllPositions.some(
      (isSelected) => isSelected
    );
    const isUnderlyingChecked = selectedUnderlying.some(
      (isSelected) => isSelected
    );
    const isExecutedStrategiesChecked = selectedExecutedStrategies.some(
      (isSelected) => isSelected
    );

    setIsAllPositionsButtonsVisible(isAllPositionsChecked);
    setIsUnderlyingButtonsVisible(isUnderlyingChecked);
    setIsExecutedStrategiesButtonsVisible(isExecutedStrategiesChecked);
  };

  const handleCheckboxChange = (table, index, row_index) => {
    switch (table) {
      case "groupbyunderlying":
        setUnderlyingBased(prevPositions => {
          const newPositions = [...prevPositions];
          newPositions[index].positions[row_index].selected = !newPositions[index].positions[row_index].selected;
          const isAnySelected = newPositions[index].positions.some(position => position.selected);
          setIsExitSelectedEnabledUnderlying(prevState => {
            const newState = [...prevState];
            newState[index] = isAnySelected; // Toggle the boolean value
            return newState;
          });
          setIsUnderlyingButtonsVisible(isAnySelected);
          return newPositions;
        });
        break;
      case "executedstrategies":
        setStrategyBased(prevPositions => {
          const newPositions = [...prevPositions];
          newPositions[index].positions[row_index].selected = !newPositions[index].positions[row_index].selected;
          const isAnySelected = newPositions[index].positions.some(position => position.selected);
          setIsExitSelectedEnabledExecutedStrategies(prevState => {
            const newState = [...prevState];
            newState[index] = isAnySelected; // Toggle the boolean value
            return newState;
          });
          setIsExecutedStrategiesButtonsVisible(isAnySelected);
          return newPositions;
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Checking the checkbox status
    checkCheckboxStatus();
  }, [selectedAllPositions, selectedUnderlying, selectedExecutedStrategies]);

  const handleExitSelected = (table) => {
    switch (table) {
      case "groupbyunderlying":
        for(let index=0;index<underlyingBased.length;index++){
          let selectedUnderlyingPositions = underlyingBased[index].positions
            .filter(position => position.selected)
            .map(position => ({ ...position, ltp: symbolData[position.code]?.ltp}));
          exitPositions(selectedUnderlyingPositions);
          setIsUnderlyingButtonsVisible(false);
        }
        
        break;
      case "executedstrategies":
        for(let index=0;index<underlyingBased.length;index++){
          let selectedSelectedPositions = strategyBased[index].positions
            .filter(position => position.selected)
            .map(position => ({ ...position, ltp: symbolData[position.code]?.ltp}));
          exitPositions(selectedSelectedPositions);
          setIsExecutedStrategiesButtonsVisible(false);
        }
        break;
      default:
        break;
    }
  };

  const [activeTable, setActiveTable] = useState("allpositions");

  function formatNumberWithFontWeight(number) {
    if (number === "Unlimited") {
      return <span style={{ fontWeight: 500 }}>Unlimited</span>;
    }
    const formattedValue = parseFloat(number).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const formattedFraction = (
      <span style={{ fontWeight: 400 }}>{parts[1]}</span>
    );

    return (
      <span>
        {parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}.{""}
        {formattedFraction}
      </span>
    );
  }

  const { setButtonsVisibility } = useVisibility();
  const areButtonsVisible =
    isAllPositionsButtonsVisible ||
    isUnderlyingButtonsVisible ||
    isExecutedStrategiesButtonsVisible;
  setButtonsVisibility(areButtonsVisible);

  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const handleOpenBasket = () => {
    setIsBasketOpen(true);
  };

  const handleCloseBasket = () => {
    setIsBasketOpen(false);
  };
  const navigate = useNavigate();

  const redirectToOptionChain = (symbol) => {
    navigate(`/optionchain`);
  };
  const [activeIndex, setActiveIndex] = useState(0);

  const handleBulletClick = (index) => {
    setActiveIndex(index);
  };

  const handleOKClick = (location) => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.remove("active");
    handleExitSelected(location);
  };

  const handleCloseClick = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.remove("active");
    toast.dismiss();
  };
  
  const showToast = (location) => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.add("active");

    toast(
      <div className="d-flex align-items-center justify-content-between">
        <div>Sure, you want to exit all the selected postions?</div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              onClick={handleCloseClick}>
              <path
                d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                stroke="#FF3908"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="confirm-popup-ok d-flex justify-content-center align-items-center"
            onClick={()=>handleOKClick(location)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none">
              <path
                d="M1 6.65685L6.65685 12.3137L17.9706 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>,
      {
        position: "bottom-center",
        closeButton: false,
        icon: false,
        className: "confirm-popup-toast",
        hideProgressBar: true,
        draggable: true,
        autoClose: false,
      }
    );
  };

  const exitAllPositions = () => {
    setSelectedUnderlying([]);
    setSelectedExecutedStrategies([]);
    setIsExitSelectedEnabledUnderlying(true);
    setIsExitSelectedEnabledExecutedStrategies(true);
  };
  
  const calculateLtpPointerPosition = (x_axis, ltp) => {
    try {
      const range =
        parseFloat(x_axis[x_axis?.length - 1]) - parseFloat(x_axis[0]);
      let ltpFromLeft = ltp - x_axis[0];
      let pointerPosition = (ltpFromLeft / range) * 100;
      return pointerPosition;
    } catch (e) {
      return 0;
    }
  };

  const calculatePointerPosition = (greenRange, item) => {
    if (item?.x_axis?.length > 0) {
      const range =
        parseFloat(item?.x_axis[item?.x_axis?.length - 1]) -
        parseFloat(item?.x_axis[0]);
      let barPosition;
      let greenBarWidth;
      let thumbWidthPercentage;
      let pointerPosition;
      let leftSpace;
      let barType;

      if (greenRange?.length == 2) {
        greenBarWidth = greenRange[1] - greenRange[0];
        barPosition = greenRange[0];
        thumbWidthPercentage = (greenBarWidth / range) * 100;
        barPosition = (greenRange[0] / item?.x_axis[0]) * 100;
        let leftDifference = greenRange[0] - item?.x_axis[0];
        leftSpace = (leftDifference / range) * 100;

        let ltp = symbolData[item?.code]?.ltp;
        let ltpFromLeft = ltp - item?.x_axis[0];
        pointerPosition = (ltpFromLeft / range) * 100;
        barType = "green";
      }
      if (greenRange?.length == 4) {
        greenBarWidth = greenRange[2] - greenRange[1];
        barPosition = greenRange[2];

        thumbWidthPercentage = (greenBarWidth / range) * 100;
        barPosition = (greenRange[1] / item?.x_axis[0]) * 100;
        let leftDifference = greenRange[1] - item?.x_axis[0];
        leftSpace = (leftDifference / range) * 100;

        let ltp = symbolData[item?.code]?.ltp;
        let ltpFromLeft = ltp - item?.x_axis[0];
        pointerPosition = (ltpFromLeft / range) * 100;
        barType = "red";
      }

      return {
        width: thumbWidthPercentage,
        leftSpace: leftSpace,
        pointerPosition: pointerPosition,
        barType: barType,
      };
    }
  };

  // const [isCollapsed, setCollapsed] = useState(false);
  // const [isCollapsedExecuted, setIsCollapsedExecuted] = useState(false);

  // const handleCollapseToggle = () => {
  //   setCollapsed(!isCollapsed);
  // };
  // const handleCollapseToggleExecuted = () => {
  //   setIsCollapsedExecuted(!isCollapsedExecuted);
  // };

  const [collapseStates, setCollapseStates] = useState([]);
  useEffect(() => {
    setCollapseStates(Array(underlyingBased.length).fill(true));
  }, []);

  const handleCollapseToggle = (index) => {
    const newCollapseStates = [...collapseStates];
    newCollapseStates[index] = !newCollapseStates[index];
    setCollapseStates(newCollapseStates);
  };

  useEffect(() => {
    const carousel = new window.bootstrap.Carousel(
      document.getElementById("carouselExampleIndicators"),
      {
        interval: 2000,
      }
    );

    //pause
    document
      .getElementById("carouselExampleIndicators")
      .addEventListener("mouseenter", () => {
        carousel.pause();
      });

    document
      .getElementById("carouselExampleIndicators")
      .addEventListener("mouseleave", () => {
        carousel.cycle();
      });

    return () => {
      carousel.dispose();
    };
  }, []);

  const [fundsData, setFundsData] = useState({
    marginAvailable: 0,
    marginUtilized: 0,
    totalFunds: 0,
    payment_url: "",
  });
  useEffect(() => {
    fetchBalanceAPI();
  }, []);

  const fetchBalanceAPI = async () => {
    try {
      const clientId = localStorage.getItem("client_id");
      const accessToken = localStorage.getItem("access_token");
      let broker = localStorage.getItem("broker");

      // console.log("from funds", clientId, accessToken, broker);
      if (!broker) {
        console.error("Broker name not found in local storage.");
        return;
      }

      broker = broker.toLowerCase().replace(/\s+/g, "");

      const response = await axios.post(
        "https://h5x8c7zqd4.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_balance",
        {
          client_id: clientId,
          access_token: accessToken,
          broker: broker,
        }
      );

      // Update state with API response
      console.log(response);
      setFundsData(response.data);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };
  let totalFunds = fundsData.totalFunds;
  let utilisedFunds = fundsData.marginUtilized;
  let utilisedPercentage = 0;
  if (totalFunds !== 0) {
    utilisedPercentage = (utilisedFunds / totalFunds) * 100;
  }

  if (isNaN(utilisedPercentage)) {
    utilisedPercentage = 0;
  }

  const groupTables = document.querySelectorAll(".group-table");
  if (groupTables.length > 0) {
    groupTables.forEach((groupTable) => {
      groupTable.classList.remove("mb-5", "pb-4");
    });

    const lastGroupTable = groupTables[groupTables.length - 1];
    lastGroupTable && lastGroupTable.classList.add("mb-5", "pb-4");
  }

  return (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>
      <div className="overlay"></div>
      {/* <div className="pos-menu-container strategy-menu d-flex justify-content-start align-items-center gap-4 flex-wrap mt-3 py-1"> */}

      <div id="carouselExampleIndicators" class="carousel slide">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="pos-left d-flex flex-column mt-3">
              <div className="position-analytic px-3 pt-2">
                Position Analytics
              </div>
              <div className="pos-strategy-menu d-flex justify-content-start align-items-start px-3 gap-3">
                <div className="d-flex flex-column justify-content-start gap-2">
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Overall P&L</span>
                    <span
                      className="pos-strategy-menu-value"
                      style={{
                        color: netProfit > 0 ? "#5CA81D" : "#DD6565",
                      }}>
                      {formatNumberWithFontWeight(netProfit)}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">
                      Potential profit <img src={questionmark} alt="" />
                    </span>
                    <span className="pos-strategy-menu-value">
                      {formatNumberWithFontWeight(pendingProfit)}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-start gap-2">
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Achieved P&L</span>
                    <span className="pos-strategy-menu-value">
                      {String(achievedPercent).split(".")[0]}.
                      <span style={{ fontWeight: 400 }}>
                        {String(achievedPercent).split(".")[1]}
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">
                      Potential loss <img src={questionmark} alt="" />
                    </span>
                    <span className="pos-strategy-menu-value">
                      {potentialLoss === "Unlimited"
                        ? potentialLoss
                        : String(potentialLoss).split(".")[0]}
                      {potentialLoss === "Unlimited" ? null : "."}
                      <span style={{ fontWeight: 400 }}>
                        {potentialLoss === "Unlimited"
                          ? null
                          : String(potentialLoss).split(".")[1]}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div className="pos-left pos-funds mt-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <div className="funds-text">Funds</div>
                  <div className="funds-text-description">
                    Fund details of your account
                  </div>
                </div>
                <Link
                  className="text-decoration-none"
                  target="_blank"
                  to={fundsData.payment_url}>
                  <button className="add-funds-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none">
                      <path
                        d="M1.88145 1.51676L9.38207 0.904044M9.38207 0.904044L10.5053 8.34535M9.38207 0.904044L1.33146 11.1113"
                        stroke="#383FEC"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Add funds</span>
                  </button>
                </Link>
              </div>
              <div className="custom-range-container mt-2">
                <div className="w-100">
                  <div className="custom-range-pos-left">
                    <div
                      className="slider-pos-left"
                      style={{
                        width: `${utilisedPercentage}%`,
                        backgroundColor: "#5CA81D",
                      }}></div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-1">
                <div className="d-flex flex-column">
                  <span className="creator-bottom-title"> Total funds</span>
                  <span className="pos-strategy-menu-value">
                    {formatNumberWithFontWeight(fundsData.totalFunds)}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <span className="creator-bottom-title">Utilized</span>
                  <span className="pos-strategy-menu-value">
                    {formatNumberWithFontWeight(fundsData.marginUtilized)}
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <span className="creator-bottom-title">Available</span>
                  <span className="pos-strategy-menu-value">
                    {formatNumberWithFontWeight(fundsData.marginAvailable)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-indicators border-2" style={{ bottom: "-40px" }}>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active custom-indicator"
            aria-current="true"
            aria-label="Slide 1"></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            class="custom-indicator"
            aria-label="Slide 2"></button>
        </div>
      </div>

      {/* </div> */}
      <div className="graph-btns d-flex gap-2 mt-3">
        <div className="d-flex flex-column ">
          <span className="fandopositions">F&O Positions</span>
          <span className="fandopositions-description">
            Positions grouped by underlying
          </span>
        </div>

        {/* {activeButton === "groupbyunderlying" && (
          <button className="exit enabled mt-1" onClick={() => showToast()}>
            Exit all positions
          </button>
        )}

        {activeButton === "executedstrategies" && (
          <button className="exit enabled mt-1" onClick={() => showToast()}>
            Exit all positions
          </button>
        )} */}
      </div>
      <div className="graph-btns d-flex gap-2 mt-3">
        <button
          className={`filter-btns ${
            activeButton === "groupbyunderlying" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("groupbyunderlying")}>
          Group by underlying
        </button>
        <button
          className={`filter-btns ${
            activeButton === "executedstrategies" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("executedstrategies")}>
          Executed strategies
        </button>
      </div>

      {/* Group by underlying table */}
      {activeButton == "groupbyunderlying" &&
        underlyingBased &&
        underlyingBased.map((data, index) => {
          let groupNetProfit = 0;
          data?.positions?.map((position, index) => {
            let ltp = symbolData[position?.code]?.ltp;
            if (ltp) {
              let pnl =
                (parseFloat(ltp) - parseFloat(position?.average_prc)) *
                parseFloat(position?.net_quantity);
              groupNetProfit = groupNetProfit + pnl;
            }
          });
          return (
            <div
              className="group-table d-flex flex-column gap-3"
              style={{ marginTop: "12px" }}>
              <div className="group-container">
                <div className="d-flex flex-column justify-content-center align-items-start py-2 gap-2">
                  <div
                    className="border-0 d-flex justify-content-between align-items-center px-3 gap-4"
                    style={{ width: "-webkit-fill-available" }}>
                    <div className="symbol-info d-flex flex-column gap-2">
                      <div className="symbol-name d-flex justify-content-between">
                        <div>{data?.scrip}</div>
                      </div>
                      <div>
                        <span className="lp">
                          {symbolData[data?.code]?.ltp}
                        </span>
                        <span
                          className="ch-chp me-2"
                          style={{
                            color:
                              symbolData[data?.code]?.change > 0
                                ? "#5CA81D" // Green color for positive change
                                : "#DD6565", // Red color for negative change
                          }}>
                          {symbolData[data?.code]?.change > 0 ? "+" : ""}
                          {symbolData[data?.code]?.change}(
                          {symbolData[data?.code]?.change_per}%)
                        </span>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <div
                        className={`d-flex flex-column pos-table-more dropdown collapse-icon ${
                          collapseStates[index] ? "collapsed" : ""
                        }`}
                        onClick={() => handleCollapseToggle(index)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="9"
                          viewBox="0 0 14 9"
                          fill="none"
                          className={`rotate-${collapseStates[index] ? "180" : "0"}`}>
                          <path
                            d="M12.6053 1.5L6.80264 7.30264L1 1.5"
                            stroke="#1E2028"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column pos-table-more dropdown">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          className="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <path
                            d="M1 1H15M1 5.5H15M1 10H15"
                            stroke="#14142B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="strategy-table-dropdown dropdown-menu  py-0">
                          <li className="dropdown-item px-2">
                            <button
                              className="create-strategy-btn"
                              onClick={redirectToOptionChain}>
                              Option Chain
                            </button>
                          </li>
                          <li className="dropdown-item px-2">
                            <button
                              onClick={() => handleClick(data)}
                              className="create-strategy-btn analyse-op"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropAnalyseMobile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                viewBox="0 0 14 18"
                                fill="#000000"
                                className="mt-2">
                                <g clip-path="url(#clip0_454_199)">
                                  <path
                                    d="M8.78566 6.78402C8.78566 6.45052 9.05776 6.17977 9.39293 6.17977C9.7279 6.17977 10 6.45052 10 6.78402V8.5008C10 8.91289 9.83045 9.28842 9.55776 9.55996C9.28507 9.83129 8.90766 10 8.49332 10H1.50668C1.09234 10 0.714931 9.83129 0.44224 9.55996C0.169548 9.28842 0 8.91289 0 8.5008V1.4992C0 1.08691 0.169548 0.711381 0.44224 0.440044C0.714931 0.168706 1.09234 0 1.50668 0H3.21552C3.55069 0 3.82259 0.270751 3.82259 0.604058C3.82259 0.937561 3.55069 1.20831 3.21552 1.20831H1.50668C1.42672 1.20831 1.35305 1.24135 1.3002 1.29374C1.24754 1.34633 1.21434 1.41963 1.21434 1.4992V8.5008C1.21434 8.58017 1.24754 8.65367 1.3002 8.70606C1.35305 8.75845 1.42672 8.79169 1.50668 8.79169H8.49332C8.57328 8.79169 8.64695 8.75845 8.6998 8.70606C8.75246 8.65367 8.78566 8.58017 8.78566 8.5008V6.78402ZM8.78566 2.02272L6.05403 4.77538C5.82004 5.01232 5.43615 5.01544 5.19823 4.78262C4.96031 4.54979 4.95697 4.16781 5.19096 3.93107L7.89332 1.20831H6.08448C5.74931 1.20831 5.47741 0.937561 5.47741 0.604058C5.47741 0.270751 5.74931 0 6.08448 0H9.39293C9.7279 0 10 0.270751 10 0.604058V3.83039C10 4.1639 9.7279 4.43445 9.39293 4.43445C9.05776 4.43445 8.78566 4.1639 8.78566 3.83039V2.02272Z"
                                    fill="#000000"></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_454_199">
                                    <rect
                                      width="10"
                                      height="10"
                                      fill="white"></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                              Analyse
                            </button>
                          </li>
                        </div>
                        <AnalyseMobile
                          data={modalData?.computedData}
                          selectedData={modalData?.positions}
                          selectedSymbol={
                            symbols?.filter(
                              (option) => option.scrip === data?.scrip
                            )[0]
                          }
                          type="symbol"
                          index={index}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`collapsing-div ${collapseStates[index] ? "collapsed" : ""}`}
                    style={{ width: "-webkit-fill-available" }}>
                    <div className="d-flex justify-content-between px-3 w-100">
                      <div className="d-flex flex-column">
                        <span className="creator-bottom-title">Net P&L</span>
                        <span
                          className="pos-strategy-menu-value"
                          style={{
                            color: groupNetProfit > 0 ? "#5CA81D" : "#DD6565",
                          }}>
                          {formatNumberWithFontWeight(groupNetProfit)}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="creator-bottom-title">
                          Max profit{" "}
                        </span>
                        <span className="pos-strategy-menu-value">
                          {formatNumberWithFontWeight(data.maxProfit)}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="creator-bottom-title">Max loss</span>
                        <span className="pos-strategy-menu-value">
                          {data?.maxLoss}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-column px-3 mt-1">
                      <span className="creator-bottom-title">Breakeven </span>
                      <span className="strategy-menu-value">
                        {data?.breakeven}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column w-100 mt-2 mb-3 px-3">
                    <div className="custom-range-container">
                      <div className="w-100">
                        <div className="d-flex justify-content-start mb-3 creator-bottom-title">
                          P&L range by underlying
                        </div>
                        <div className="custom-range-container">
                          <div className="w-100 ">
                            <div
                              className="custom-range"
                              style={{
                                "--slider-color": "#5CA81D",
                                background: "#DD6565",
                              }}>
                              <div
                                className="slider"
                                style={{
                                  width: `${data?.greenBarWidth}%`,
                                  left: `${data?.leftSpace}%`,
                                  backgroundColor: "var(--slider-color)",
                                  top: "50%",
                                }}></div>
                            </div>
                            <div
                              style={{
                                left: `calc(${calculateLtpPointerPosition(
                                  data?.x_axis,
                                  symbolData[data?.code]?.ltp
                                )}% - 1.8%)`,
                                position: "absolute",
                                top: "2px",
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="7"
                                viewBox="0 0 11 7"
                                fill="none">
                                <path
                                  d="M4.40608 0.401811C4.90098 -0.133937 5.74756 -0.133937 6.24246 0.401811L10.3143 4.80965C11.0538 5.61025 10.486 6.90784 9.39606 6.90784L1.25248 6.90784C0.162566 6.90784 -0.405272 5.61025 0.334291 4.80965L4.40608 0.401811Z"
                                  fill="#1E2028"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <table className="position-table w-100">
                  <thead>
                    <tr className="text-center tr-strategy-headerr position-table-header ">
                      <tr
                        className="d-flex justify-content-start align-items-center px-2 gap-2"
                        style={{ width: "90vw" }}>
                        <th scope="col d-flex align-itmes-center justify-content-center">
                          <input
                            type="checkbox"
                            className="d-none align-itmes-center justify-content-center"
                          />
                        </th>
                        <th scope="col" className="text-start">
                          <span className="ps-3">Instruments</span>
                        </th>

                        <th
                          scope="col"
                          style={{ marginLeft: "auto", marginRight: "12px" }}>
                          Product
                        </th>
                        <th scope="col">Qty</th>
                      </tr>

                      <tr
                        className="d-flex justify-content-between align-items-center px-2"
                        style={{ width: "90vw" }}>
                        <th scope="col" style={{ marginLeft: "24px" }}>
                          Avg price
                        </th>
                        <th scope="col">LTP</th>
                        <th scope="col"> P&L</th>
                      </tr>
                    </tr>
                  </thead>
                  <tbody>
                    {data.positions &&
                      data.positions.map((obj, row_index) => (
                        <tr className="position-table-row text-center">
                          <tr
                            className="d-flex  align-items-center justify-content-start px-2 pb-2 gap-2"
                            style={{ width: "90vw" }}>
                            <td className="d-flex align-items-center justify-content-center">
                              <input
                                type="checkbox"
                                className="d-flex align-items-center justify-content-center"
                                checked={
                                  data?.selected
                                }
                                onChange={() =>
                                  handleCheckboxChange(
                                    "groupbyunderlying",
                                    index,
                                    row_index
                                  )
                                }
                              />
                            </td>
                            <td className="table-data text-start">
                              {obj.instrument_name}
                            </td>

                            <td
                              className="d-flex align-items-center justify-content-center my-1"
                              style={{
                                marginLeft: "auto",
                                backgroundColor:
                                  obj.product_type === "Normal"
                                    ? "#DBDCFF"
                                    : "#FFEDCB",
                                color:
                                  obj.product_type === "Normal"
                                    ? "#383FEC"
                                    : "#E2A838",
                              }}>
                              <div className="product-type text-center d-flex align-items-center justify-content-center">
                                {obj.product_type}
                              </div>
                            </td>

                            <td
                              className="table-data"
                              style={{
                                color:
                                  parseFloat(obj.net_quantity) > 0
                                    ? "#5CA81D"
                                    : "#DD6565",
                              }}>
                              {obj.net_quantity}
                            </td>
                          </tr>
                          <tr
                            className="d-flex  align-items-center justify-content-between px-2 pb-2"
                            style={{ width: "90vw" }}>
                            <td
                              className="table-data"
                              style={{ marginLeft: "30px" }}>
                              {String(obj.average_prc).split(".")[0]}.
                              <span style={{ fontWeight: 400 }}>
                                {String(obj.average_prc).split(".")[1]}
                              </span>
                            </td>
                            <td className="table-data">
                              {String(symbolData[obj.code]?.ltp).split(".")[0]}.
                              <span style={{ fontWeight: 400 }}>
                                {
                                  String(symbolData[obj.code]?.ltp).split(
                                    "."
                                  )[1]
                                }
                              </span>
                            </td>
                            <td
                              className="table-data"
                              style={{
                                color:
                                  parseFloat(
                                    (symbolData[obj.code]?.ltp -
                                      obj.average_prc) *
                                      obj.net_quantity
                                  ) > 0
                                    ? "#5CA81D"
                                    : "#DD6565",
                              }}>
                              {formatNumberWithFontWeight(
                                (symbolData[obj.code]?.ltp - obj.average_prc) *
                                  obj.net_quantity
                              )}
                            </td>
                          </tr>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}

      {/* Executed strategy table */}
      {activeButton === "executedstrategies" &&
        strategyBased &&
        strategyBased.map((data, index) => {
          let groupNetProfit = 0;
          data?.positions?.map((position, index) => {
            let ltp = symbolData[position?.code]?.ltp;
            if (ltp) {
              let pnl =
                (parseFloat(ltp) - parseFloat(position?.average_prc)) *
                parseFloat(position?.net_quantity);
              groupNetProfit = groupNetProfit + pnl;
            }
          });

          return (
            <div
              className="group-table executed-table  d-flex flex-column gap-3"
              style={{ marginTop: "12px" }}>
              <div className="group-container">
                <div className="strategy-name px-3 py-1">
                    <div className="creator-bottom-title">Strategy</div>
                    <div className="pos-strategy-menu-value">
                      {data.strategy}
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-start py-2 gap-2">
                  <div
                    className="border-0 d-flex justify-content-between align-items-center px-3 gap-4"
                    style={{ width: "-webkit-fill-available" }}>
                    <div className="symbol-info d-flex flex-column gap-2">
                      <div className="symbol-name d-flex justify-content-between">
                        <div>{data?.scrip}</div>
                      </div>
                      <div>
                        <span className="lp">
                          {symbolData[data?.code]?.ltp}
                        </span>
                        <span
                          className="ch-chp me-2"
                          style={{
                            color:
                              symbolData[data?.code]?.change > 0
                                ? "#5CA81D" // Green color for positive change
                                : "#DD6565", // Red color for negative change
                          }}>
                          {symbolData[data?.code]?.change > 0 ? "+" : ""}
                          {symbolData[data?.code]?.change}(
                          {symbolData[data?.code]?.change_per}%)
                        </span>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <div
                        className={`d-flex flex-column pos-table-more dropdown collapse-icon ${
                          collapseStates[index] ? "collapsed" : ""
                        }`}
                        onClick={() => handleCollapseToggle(index)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="9"
                          viewBox="0 0 14 9"
                          fill="none"
                          className={`rotate-${collapseStates[index] ? "180" : "0"}`}>
                          <path
                            d="M12.6053 1.5L6.80264 7.30264L1 1.5"
                            stroke="#1E2028"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column pos-table-more dropdown">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          className="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <path
                            d="M1 1H15M1 5.5H15M1 10H15"
                            stroke="#14142B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="strategy-table-dropdown dropdown-menu  py-0">
                          <li className="dropdown-item px-2">
                            <button
                              className="create-strategy-btn"
                              onClick={redirectToOptionChain}>
                              Option Chain
                            </button>
                          </li>
                          <li className="dropdown-item px-2">
                            <button
                              onClick={() => handleClick(data)}
                              className="create-strategy-btn analyse-op"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropAnalyseMobile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                viewBox="0 0 14 18"
                                fill="#000000"
                                className="mt-2">
                                <g clip-path="url(#clip0_454_199)">
                                  <path
                                    d="M8.78566 6.78402C8.78566 6.45052 9.05776 6.17977 9.39293 6.17977C9.7279 6.17977 10 6.45052 10 6.78402V8.5008C10 8.91289 9.83045 9.28842 9.55776 9.55996C9.28507 9.83129 8.90766 10 8.49332 10H1.50668C1.09234 10 0.714931 9.83129 0.44224 9.55996C0.169548 9.28842 0 8.91289 0 8.5008V1.4992C0 1.08691 0.169548 0.711381 0.44224 0.440044C0.714931 0.168706 1.09234 0 1.50668 0H3.21552C3.55069 0 3.82259 0.270751 3.82259 0.604058C3.82259 0.937561 3.55069 1.20831 3.21552 1.20831H1.50668C1.42672 1.20831 1.35305 1.24135 1.3002 1.29374C1.24754 1.34633 1.21434 1.41963 1.21434 1.4992V8.5008C1.21434 8.58017 1.24754 8.65367 1.3002 8.70606C1.35305 8.75845 1.42672 8.79169 1.50668 8.79169H8.49332C8.57328 8.79169 8.64695 8.75845 8.6998 8.70606C8.75246 8.65367 8.78566 8.58017 8.78566 8.5008V6.78402ZM8.78566 2.02272L6.05403 4.77538C5.82004 5.01232 5.43615 5.01544 5.19823 4.78262C4.96031 4.54979 4.95697 4.16781 5.19096 3.93107L7.89332 1.20831H6.08448C5.74931 1.20831 5.47741 0.937561 5.47741 0.604058C5.47741 0.270751 5.74931 0 6.08448 0H9.39293C9.7279 0 10 0.270751 10 0.604058V3.83039C10 4.1639 9.7279 4.43445 9.39293 4.43445C9.05776 4.43445 8.78566 4.1639 8.78566 3.83039V2.02272Z"
                                    fill="#000000"></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_454_199">
                                    <rect
                                      width="10"
                                      height="10"
                                      fill="white"></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                              Analyse
                            </button>
                          </li>
                        </div>
                        <AnalyseMobile
                          data={modalData?.computedData}
                          selectedData={modalData?.positions}
                          selectedSymbol={
                            symbols?.filter(
                              (option) => option.scrip === data?.scrip
                            )[0]
                          }
                          type="strategy"
                          index={index}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`collapsing-div ${collapseStates[index] ? "collapsed" : ""}`}
                    style={{ width: "-webkit-fill-available" }}>
                    <div className="d-flex justify-content-between px-3 w-100">
                      <div className="d-flex flex-column">
                        <span className="creator-bottom-title">Net P&L</span>
                        <span
                          className="pos-strategy-menu-value"
                          style={{
                            color: groupNetProfit > 0 ? "#5CA81D" : "#DD6565",
                          }}>
                          {formatNumberWithFontWeight(groupNetProfit)}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="creator-bottom-title">
                          Max profit{" "}
                        </span>
                        <span className="pos-strategy-menu-value">
                          {formatNumberWithFontWeight(data?.maxProfit)}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="creator-bottom-title">Max loss</span>
                        <span className="pos-strategy-menu-value">
                          {data?.maxLoss}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-column px-3 mt-1">
                      <span className="creator-bottom-title">Breakeven </span>
                      <span className="strategy-menu-value">
                        {data?.breakeven}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column w-100 mt-2 mb-3 px-3">
                    <div className="custom-range-container">
                      <div className="w-100">
                        <div className="d-flex justify-content-start mb-3 creator-bottom-title">
                          P&L range by underlying
                        </div>
                        <div className="custom-range-container">
                          <div className="w-100 ">
                            <div
                              className="custom-range"
                              style={{
                                "--slider-color": "#5CA81D",
                                background: "#DD6565",
                              }}>
                              <div
                                className="slider"
                                style={{
                                  width: `${data?.greenBarWidth}%`,
                                  left: `${data?.leftSpace}%`,
                                  backgroundColor: "var(--slider-color)",
                                  top: "50%",
                                }}></div>
                            </div>
                            <div
                              style={{
                                left: `calc(${calculateLtpPointerPosition(
                                  data?.x_axis,
                                  symbolData[data?.code]?.ltp
                                )}% - 1.8%)`,
                                position: "absolute",
                                top: "2px",
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="7"
                                viewBox="0 0 11 7"
                                fill="none">
                                <path
                                  d="M4.40608 0.401811C4.90098 -0.133937 5.74756 -0.133937 6.24246 0.401811L10.3143 4.80965C11.0538 5.61025 10.486 6.90784 9.39606 6.90784L1.25248 6.90784C0.162566 6.90784 -0.405272 5.61025 0.334291 4.80965L4.40608 0.401811Z"
                                  fill="#1E2028"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <table className="position-table w-100">
                  <thead>
                    <tr className="text-center tr-strategy-headerr position-table-header ">
                      <tr
                        className="d-flex justify-content-start align-items-center px-2 gap-2"
                        style={{ width: "90vw" }}>
                        <th scope="col d-flex align-itmes-center justify-content-center">
                          <input
                            type="checkbox"
                            className="d-none align-itmes-center justify-content-center"
                          />
                        </th>
                        <th scope="col" className="text-start">
                          <span className="ps-3">Instruments</span>
                        </th>

                        <th
                          scope="col"
                          style={{ marginLeft: "auto", marginRight: "12px" }}>
                          Product
                        </th>
                        <th scope="col">Qty</th>
                      </tr>

                      <tr
                        className="d-flex justify-content-between align-items-center px-2"
                        style={{ width: "90vw" }}>
                        <th scope="col" style={{ marginLeft: "24px" }}>
                          Avg price
                        </th>
                        <th scope="col">LTP</th>
                        <th scope="col"> P&L</th>
                      </tr>
                    </tr>
                  </thead>
                  <tbody>
                    {data.positions &&
                      data.positions.map((obj, row_index) => (
                        <tr className="position-table-row text-center">
                          <tr
                            className="d-flex  align-items-center justify-content-start px-2 pb-2 gap-2"
                            style={{ width: "90vw" }}>
                            <td className="d-flex align-items-center justify-content-center">
                              <input
                                type="checkbox"
                                className="d-flex align-items-center justify-content-center"
                                checked={
                                  data?.selected
                                }
                                onChange={() =>
                                  handleCheckboxChange(
                                    "executedstrategies",
                                    index,
                                    row_index
                                  )
                                }
                              />
                            </td>
                            <td className="table-data text-start">
                              {obj.instrument_name}
                            </td>

                            <td
                              className="d-flex align-items-center justify-content-center my-1"
                              style={{
                                marginLeft: "auto",
                                backgroundColor:
                                  obj.product_type === "Normal"
                                    ? "#DBDCFF"
                                    : "#FFEDCB",
                                color:
                                  obj.product_type === "Normal"
                                    ? "#383FEC"
                                    : "#E2A838",
                              }}>
                              <div className="product-type text-center d-flex align-items-center justify-content-center">
                                {obj.product_type}
                              </div>
                            </td>

                            <td
                              className="table-data"
                              style={{
                                color:
                                  parseFloat(obj.net_quantity) > 0
                                    ? "#5CA81D"
                                    : "#DD6565",
                              }}>
                              {obj.net_quantity}
                            </td>
                          </tr>
                          <tr
                            className="d-flex  align-items-center justify-content-between px-2 pb-2"
                            style={{ width: "90vw" }}>
                            <td
                              className="table-data"
                              style={{ marginLeft: "30px" }}>
                              {String(obj.average_prc).split(".")[0]}.
                              <span style={{ fontWeight: 400 }}>
                                {String(obj.average_prc).split(".")[1]}
                              </span>
                            </td>
                            <td className="table-data">
                              {String(symbolData[obj.code]?.ltp).split(".")[0]}.
                              <span style={{ fontWeight: 400 }}>
                                {
                                  String(symbolData[obj.code]?.ltp).split(
                                    "."
                                  )[1]
                                }
                              </span>
                            </td>
                            <td
                              className="table-data"
                              style={{
                                color:
                                  parseFloat(
                                    (symbolData[obj.code]?.ltp -
                                      obj.average_prc) *
                                      obj.net_quantity
                                  ) > 0
                                    ? "#5CA81D"
                                    : "#DD6565",
                              }}>
                              {formatNumberWithFontWeight(
                                (symbolData[obj.code]?.ltp - obj.average_prc) *
                                  obj.net_quantity
                              )}
                            </td>
                          </tr>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      {activeButton === "allpositions" && isAllPositionsButtonsVisible && (
        <div className="shadow-container">
          <div className="pos-check-buttons d-flex justify-content-between px-3 ">
            {/* <button className="pos-button one-click">One-click reverse</button> */}
            <button
              className="pos-button exit-pos"
              onClick={() => handleExitSelected("allpositions")}>
              Exit Position
            </button>
          </div>
        </div>
      )}
      {activeButton === "groupbyunderlying" && isUnderlyingButtonsVisible && (
        <div className="shadow-container">
          <div className="pos-check-buttons d-flex justify-content-between px-3">
            {/* <button className="pos-button one-click">One-click reverse</button> */}
            <button
              className="pos-button exit-pos"
              onClick={() => showToast("groupbyunderlying")}>
              Exit Position
            </button>
          </div>
        </div>
      )}
      {activeButton === "executedstrategies" &&
        isExecutedStrategiesButtonsVisible && (
          <div className="shadow-container">
            <div className="pos-check-buttons d-flex justify-content-between px-3">
              {/* <button className="pos-button one-click">
                One-click reverse
              </button> */}
              <button
                className="pos-button exit-pos"
                onClick={() => showToast("executedstrategies")}>
                Exit Position
              </button>
            </div>
          </div>
        )}
    </>
  );
};

export default PositionsMobile;
