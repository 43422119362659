import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../images/Logo.svg";
import "../../css/style.css";
import UserIcon from "../../images/user-icon.svg";
import Banner from "./Banner";
import HeaderLogic from "./HeaderLogic";

const HeaderMobile = () => {
  const { symbolData } = HeaderLogic();
  const symbols = ["", "", ""].map((symbol, index) => ({
    id: index,
    symbol,
  }));
  return (
    <>
      {/* <Banner symbols={symbols} speed={5000} symbolData={symbolData} /> */}
      {/* <nav
        className="header fixed-top navbar navbar-expand-lg bg-white mb-3"
        style={{ padding: "0 12px" }}>
        <div className="container-fluid bg-white justify-content-between">
          <Link
            className="navbar-brand d-flex align-items-center justify-content-center"
            to="/">
            <img src={Logo} alt="logo" className="app-logo" />
          </Link>

          <div className=" dropdown-center mt-md-0" role="button">
            <img
              src={UserIcon}
              alt="User Profile Pic"
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
              }}
              className="dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />

            <div className="dropdown-menu user-profile-dropdown-menu py-0">
              <li className="dropdown-item px-2">
                Hi, <br /> XU01019
              </li>
              <li className="dropdown-item px-2 d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none">
                  <path
                    d="M9.53553 3.10508C11.4882 5.13991 11.4882 8.43903 9.53553 10.4739C7.58291 12.5087 4.41709 12.5087 2.46447 10.4739C0.511845 8.43903 0.511845 5.13991 2.46447 3.10508M5.99999 1V6.78947"
                    stroke="#14142B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
                Logout
              </li>
            </div>
          </div>
        </div>
      </nav> */}
    </>
  );
};

export default HeaderMobile;
