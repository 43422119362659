import React from "react";
import HeaderLogic from "./HeaderLogic";
import "../../css/style.css";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";
import FooterMobile from "../FooterMobile";

const Header = () => {
  const { symbolData } = HeaderLogic();

  return (
    <div>
      <div className="mobile-header">
        <HeaderMobile symbolData={symbolData} />
      </div>
      <div className="desktop-header">
        <HeaderDesktop symbolData={symbolData} />
      </div>

      <div className="mobile-header">
        <FooterMobile />
      </div>
    </div>
  );
};

export default Header;
