import React, { useState } from "react";

const ExpiryDates = ({
  selectedSymbol,
  handleSymbolSelection,
  expiryDays,
  activeDate,
  showTitle = true,
  showDaysInButton = true,
}) => {
  const [selectedExpiry, setSelectedExpiry] = useState(
    selectedSymbol?.option_expiries?.[0] || ""
  );

  const handleExpirySelection = (expiryDate) => {
    setSelectedExpiry(expiryDate);
    handleSymbolSelection(selectedSymbol, expiryDate);
  };

  return (
    <div className="symbol-date mt-md-2 mt-md-3">
      <div className="d-flex align-items-center gap-1">
        {showTitle && (
          <div className="pe-1 pe-md-0 expiry-date-title">Expiry date:</div>
        )}
        <div className="dropdown">
          <button
            className="dropdown-toggle date-dropdown-btn d-flex align-items-center"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            type="button">
            {new Date(activeDate).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
            })}
            {showDaysInButton && (
              <span className="expiry-days ms-1">
                ({expiryDays} {expiryDays === 1 ? "day" : "days"})
              </span>
            )}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="7"
              viewBox="0 0 13 7"
              fill="none"
              className="ms-2">
              <path
                d="M6.32426 7L0.262079 0.25L12.3864 0.25L6.32426 7Z"
                fill="black"
              />
            </svg>
          </button>

          <ul className="dropdown-menu oc-date-dropdown-menu py-0">
            {selectedSymbol?.option_expiries &&
              Array.isArray(selectedSymbol.option_expiries) &&
              selectedSymbol.option_expiries.map((expiryDate, index) => (
                <li
                  key={index}
                  className={`dropdown-item cursor-pointer ${
                    expiryDate === selectedExpiry ? "active" : ""
                  }`}
                  onClick={() => handleExpirySelection(expiryDate)}>
                  {new Date(expiryDate).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                  })}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ExpiryDates;
