import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import SymbolSelection from "../../components/SymbolSelection";
// import WebSocketContext from "../context/WebSocketContext";
import SearchModal from "../../modals/SearchModal";
import { getSymbols } from "../../utils/api";
import axios from "axios";
import IronConder from "../../images/Screenshot 2023-05-15 at 1.34.png";
import ScreenerModal from "../../modals/ScreenerModal";
import StrategyMenu from "../../components/StrategyMenu";
import StrategyGraph from "../../components/StrategyGraph";
import OptionChainMobile from "../../modals/OptionChainMobile";
import { useLtpContext } from "../../context/LtpContext";
import ABWebSocketContext from "../../context/ABWebsocketContext";
import { useGraphContext } from "../../context/GraphContext";
import CustomToastContent from "../../components/CustomToastContent";
import { toast } from "react-toastify";
import SuccessIcon from "../../images/success_icon.svg";
import ErrorIcon from "../../images/error_icon.svg";
import ScreenerMobile from "../../modals/ScreenerMobile";
import StrategiesMobile from "../../modals/StrategiesMobile";
import { useFundsData } from "../../context/FundsDataContext";
import MetricsComputations from "../../components/MetricsComputations";

let triggerSubscribe = true;
let connectionEstablised = false;

const StrategyCreatorMobile = () => {
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [optionData, setOptionData] = useState(null);
  const [tempOptionData, setTempOptionData] = useState({});
  const [allOptionData, setAllOptionData] = useState({});
  const [selectedExpiry, setSelectedExpiry] = useState(null);
  const [allTradingSymbols, setAllTradingSymbols] = useState({});
  const [selectedData, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeDate, setActiveDate] = useState("");
  const [selectedStrategy, setSelectedStrategy] = useState("buy_call");
  const { totalGreeks, strategyDirection, premium } = useGraphContext();
  const [inputValues, setInputValues] = useState([]);

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(ABWebSocketContext);

  const broker = localStorage.getItem("broker");
  const access_token = localStorage.getItem("access_token");
  const client_id = localStorage.getItem("client_id");
  const { fundsData } = useFundsData();

  const { ltpValue } = useLtpContext();
  var iv = require("implied-volatility");
  var greeks = require("greeks");

  const metricsComputations = MetricsComputations();
  const [computedData, setComputedData] = useState();

  useEffect(() => {
    connectionEstablised = checkWebsocketConnection();
    if (connectionEstablised && symbols.length > 0) {
      if (triggerSubscribe) {
        subscribeToSymbols(symbols, "NSE");
        if (Object.keys(symbolList).length !== 0) {
          triggerSubscribe = false;
        }
      }
    } else {
      triggerSubscribe = true;
    }
  }, [symbolData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const metrics = await metricsComputations.computeXandYAxis({
          selectedData: selectedData,
          ltp: symbolData[selectedSymbol?.code]?.ltp,
          symbol: selectedSymbol,
          leastExpiry: selectedSymbol?.option_expiries[0],
          lotsVal: optionData?.options[0].call.lot_size,
          allOptionData: allOptionData,
        });
        setComputedData(metrics);
      } catch (error) {
        console.error("Error:", error.message);
        // Handle the error here
      }
    };
    if (selectedData?.length > 0) {
      fetchData();
    }
  }, [selectedData, selectedSymbol]);

  useEffect(() => {
    const storedSymbol = sessionStorage.getItem("selectedSymbol");
    if (storedSymbol) {
      setSelectedSymbol(JSON.parse(storedSymbol));
    } else {
      const fetchSymbols = async () => {
        console.log("getting");
        const symbols = await getSymbols();
        if (symbols) {
          const niftySymbol = symbols.find(
            (symbol) => symbol.scrip === "NIFTY"
          );
          setSelectedSymbol(niftySymbol);
          sessionStorage.setItem("selectedSymbol", JSON.stringify(niftySymbol));
        }
      };
      fetchSymbols();
    }
  }, []);

  function addHedge() {
    let call_put_array = [];
    let select_index = [];
    let lots_array = [];
    let hedge_buy = [];
    for (let index = 0; index < selectedData.length; index++) {
      if (!selectedData[index]?.isBuy) {
        let selected_strike = 0;
        let lots = selectedData[index]?.lotSize;
        if (selectedData[index]?.isCALL) {
          selected_strike = selectedData[index]["allstrks"]?.length - 1;
          call_put_array.push("Call");
          select_index.push(selected_strike);
          lots_array.push(lots);
          hedge_buy.push("Buy");
        } else {
          call_put_array.push("Put");
          select_index.push(selected_strike);
          lots_array.push(lots);
          hedge_buy.push("Buy");
        }
      }
    }
    let childData = constructInstruments(
      call_put_array,
      hedge_buy,
      select_index,
      lots_array
    );
    handleCallback([childData], true);
  }

  function applyPreBuiltStrategy(strategyName) {
    const atmIndex = returnAtmIndex();
    let childData = [];
    setSelectedStrategy(strategyName);
    if (!(optionData || optionData.options)) {
      // setInterval(() => {}, 100);
    }

    if (strategyName === "buy_call") {
      childData = constructInstruments(["Call"], ["Buy"], [atmIndex], [1]);
    } else if (strategyName === "sell_put") {
      childData = constructInstruments(["Put"], ["Sell"], [atmIndex], [1]);
    } else if (strategyName === "bull_call_spread") {
      childData = constructInstruments(
        ["Call", "Call"],
        ["Buy", "Sell"],
        [atmIndex + 2, atmIndex + 5],
        [1, 1]
      );
    } else if (strategyName === "bull_put_spread") {
      childData = constructInstruments(
        ["Put", "Put"],
        ["Buy", "Sell"],
        [atmIndex - 6, atmIndex - 1],
        [1, 1]
      );
    } else if (strategyName === "call_ratio_back_spread") {
      childData = constructInstruments(
        ["Call", "Call"],
        ["Buy", "Sell"],
        [atmIndex + 3, atmIndex - 2],
        [2, 1]
      );
    } else if (strategyName === "buy_put") {
      childData = constructInstruments(["Put"], ["Buy"], [atmIndex], [1]);
    } else if (strategyName === "sell_call") {
      childData = constructInstruments(["Call"], ["Sell"], [atmIndex], [1]);
    } else if (strategyName === "bear_put_spread") {
      childData = constructInstruments(
        ["Put", "Put"],
        ["Sell", "Buy"],
        [atmIndex + 2, atmIndex + 5],
        [1, 1]
      );
    } else if (strategyName === "bear_call_spread") {
      childData = constructInstruments(
        ["Call", "Call"],
        ["Sell", "Buy"],
        [atmIndex - 6, atmIndex - 1],
        [1, 1]
      );
    } else if (strategyName === "put_ratio_back_spread") {
      childData = constructInstruments(
        ["Put", "Put"],
        ["Buy", "Sell"],
        [atmIndex - 2, atmIndex + 3],
        [2, 1]
      );
    } else if (strategyName === "short_straddle") {
      childData = constructInstruments(
        ["Call", "Put"],
        ["Sell", "Sell"],
        [atmIndex, atmIndex],
        [1, 1]
      );
    } else if (strategyName === "short_strangle") {
      childData = constructInstruments(
        ["Call", "Put"],
        ["Sell", "Sell"],
        [atmIndex + 8, atmIndex - 8],
        [1, 1]
      );
    } else if (strategyName === "iron_butterfly") {
      childData = constructInstruments(
        ["Call", "Put", "Call", "Put"],
        ["Buy", "Buy", "Sell", "Sell"],
        [atmIndex + 5, atmIndex - 5, atmIndex, atmIndex],
        [1, 1, 1, 1]
      );
    } else if (strategyName === "iron_condor") {
      childData = constructInstruments(
        ["Call", "Put", "Call", "Put"],
        ["Buy", "Buy", "Sell", "Sell"],
        [atmIndex + 9, atmIndex - 9, atmIndex + 5, atmIndex - 5],
        [1, 1, 1, 1]
      );
    }

    handleCallback([childData]);
  }

  function constructInstruments(
    call_put_array,
    buy_sell_array,
    index_array,
    quantity_array
  ) {
    let row_list = [];
    for (let index = 0; index < call_put_array.length; index++) {
      let atmIndex = index_array[index];
      let row_code =
        call_put_array[index] === "Call"
          ? optionData.options[atmIndex].call.code
          : optionData.options[atmIndex].put.code;
      let strike =
        call_put_array[index] === "Call"
          ? optionData.options[atmIndex].call.strike
          : optionData.options[atmIndex].put.strike;
      let row_expiryDate =
        call_put_array[index] === "Call"
          ? optionData.options[atmIndex].call.expiry_date
          : optionData.options[atmIndex].put.expiry_date;

      let row_instrumentName =
        call_put_array[index] === "Call"
          ? optionData.options[atmIndex].call.instrument_name
          : optionData.options[atmIndex].put.instrument_name;
      let row_lotSize =
        call_put_array[index] === "Call"
          ? optionData.options[atmIndex].call.lot_size
          : optionData.options[atmIndex].put.lot_size;
      let row_tradingSymbol =
        call_put_array[index] === "Call"
          ? optionData.options[atmIndex].call.trading_symbol
          : optionData.options[atmIndex].put.trading_symbol;
      let freeze_qty =
        call_put_array[index] === "Call"
          ? optionData.options[atmIndex].call.freeze_qty
          : optionData.options[atmIndex].put.freeze_qty;
      let exchange =
        call_put_array[index] === "Call"
          ? optionData.options[atmIndex].call.exchange
          : optionData.options[atmIndex].put.exchange;
      let lot_multiplier = parseFloat(optionData?.lot_size);
      row_list.push({
        buy_sell: buy_sell_array[index],
        code: row_code,
        expiryDate: row_expiryDate,
        instrumentName: row_instrumentName,
        lotSize: row_lotSize,
        side: call_put_array[index],
        tradingSymbol: row_tradingSymbol,
        quantity: quantity_array[index],
        strike: strike,
        freeze_qty: freeze_qty,
        exchange: exchange,
        lot_multiplier: lot_multiplier,
      });
    }
    return row_list;
  }

  const returnAtmIndex = () => {
    let index = 0;
    const ltp = symbolData[selectedSymbol?.code]?.ltp || 0;
    if (optionData && optionData.options) {
      let curr = optionData.options[0].strike,
        diff = Math.abs(ltp - curr);
      for (let val = 0; val < optionData.options.length; val++) {
        let newdiff = Math.abs(ltp - optionData.options[val].strike);
        if (newdiff < diff) {
          diff = newdiff;
          curr = optionData.options[val];
          index = val;
        }
      }
    }
    return index;
  };

  const handleStrategyOnSymbolChange = () => {
    if (selectedStrategy && optionData) {
      applyPreBuiltStrategy(selectedStrategy);
    }
  };

  // Function to calculate the absolute difference between two values
  function getDifference(value1, value2) {
    return Math.abs(value1 - value2);
  }

  const handleScreenerback = async (rowData) => {
    let filtered_rows = [];
    rowData.map((item, index) => {
      if (item.hasTick) {
        let row = item.data;
        let targetValue = row.inputValue;
        if (
          row.selectedCondition == "delta" ||
          row.selectedCondition == "theta" ||
          row.selectedCondition == "gamma" ||
          row.selectedCondition == "vega"
        ) {
          let closestJson = optionData.options.reduce((prev, curr) => {
            let prev_data = row.isCALL ? prev.call : prev.put;
            let curdd_data = row.isCALL ? curr.call : curr.put;
            const prevDiff = getDifference(
              Math.abs(prev_data.delta),
              targetValue
            );
            const currDiff = getDifference(
              Math.abs(curdd_data.delta),
              targetValue
            );
            return currDiff < prevDiff ? curr : prev;
          });
          let selected_row = row.isCALL ? closestJson.call : closestJson.put;
          let addingRow = {
            buy_sell: row.isBuy ? "Buy" : "Sell",
            code: selected_row.code,
            expiryDate: selected_row.expiry_date,
            instrumentName: selected_row.instrument_name,
            lotSize: selected_row.lot_size,
            side: row.isCALL ? "Call" : "Put",
            tradingSymbol: selected_row.trading_symbol,
            quantity: row.lotsValue,
            freeze_qty: selected_row.freeze_qty,
            exchange: selected_row.exchange,
            strike: parseInt(selected_row.strike),
            lot_multiplier: selected_row.lot_size,
          };
          filtered_rows.push(addingRow);
        }
      }
    });
    if (filtered_rows.length > 0) {
      handleCallback([filtered_rows]);
    }
  };

  const callBackToUpdateAlloption = (newOptions) => {
    let date = newOptions?.options[0]?.call?.expiry_date;
    let optdt = newOptions;
    setAllOptionData((prevStatus) => ({
      ...prevStatus,
      [date]: optdt,
    }));
  };

  const handleCallback = async ([rowData], should_add) => {
    let rows = [];
    for (let index = 0; index < rowData.length; index++) {
      let childData = rowData[index];
      let dataToAdd = childData;
      let dt = dataToAdd.instrumentName.split(" ");
      let date = dataToAdd.expiryDate;
      const dDate = date.replaceAll("%20", " ");
      const newObject = {
        isBuy: childData.buy_sell == "Buy" ? true : false,
        isCALL: childData.side == "Call" ? true : false,
        strike: parseInt(childData.strike),
        expiry: dDate,
        scrip: dt[0],
        lotSize: childData.quantity,
        code: childData.code,
        tradingSymbol: childData.tradingSymbol,
        allstrks: [],
        allData: [],
        freeze_qty: childData.freeze_qty,
        exchange: childData.exchange,
        lot_multiplier: childData.lot_multiplier,
      };
      const newobj = [newObject.code];
      setSymbols(newobj);
      subscribeToSymbols(newobj, "NFO");
      const formattedDate = newObject.expiry.replaceAll(" ", "%20");
      let alldt;
      if (optionData?.id !== selectedSymbol.scrip + newObject.expiry) {
        alldt = await getOptionData(selectedSymbol.scrip, formattedDate);
      } else {
        alldt = optionData;
      }
      setTimeout(() => {
        let allstrk = extractStrikeValues(alldt);
        newObject.allstrks = allstrk;
        newObject.allData = alldt?.options;
      }, 200);
      rows.push(newObject);
    }
    // TODO: Add condition to remove incase of pre built strategy
    if (should_add) {
      setData([...selectedData, ...rows]);
    } else {
      setData(rows);
    }
  };

  useLayoutEffect(() => {
    let change = false;
    selectedData.map((row) => {
      if (symbolData[row.code] && !row.price) {
        row.price = symbolData[row.code]?.ltp;
        change = true;
      }
    });
    if (change) {
      setData(selectedData);
    }
  }, [selectedData, selectedSymbol, symbolData]);

  useEffect(() => {
    if (
      selectedSymbol?.option_expiries &&
      Array.isArray(selectedSymbol.option_expiries) &&
      selectedSymbol.option_expiries.length > 0
    ) {
      setSelectedExpiry(selectedSymbol.option_expiries[0]); // Set the selected expiry to the first value
      setLatestExpiry(selectedSymbol.option_expiries[0]); // Set the initial value of latestExpiry to the first value
    }
  }, [selectedSymbol]);

  const updateCodeSymbol = (optdt, ind) => {
    const selectedRow = selectedData[ind];
    let tSymbol = selectedData[ind].trading_symbol;
    let tCode = selectedData[ind].code;
    let newRow = optdt.options.find((o, i) => {
      if (o.strike == selectedData[ind].strike) {
        return o;
      }
    });
    if (newRow != undefined && newRow != null) {
      if (selectedData[ind].isCALL) {
        tSymbol = newRow.call.trading_symbol;
        tCode = newRow.call.code;
      } else {
        tSymbol = newRow.put.trading_symbol;
        tCode = newRow.put.code;
      }
    }
    return [tSymbol, tCode];
  };

  const [upd, setUpd] = useState(selectedSymbol);

  const handleSymbolSelection = async (symbol, date, ind) => {
    // sessionStorage.setItem("selectedSymbol", JSON.stringify(symbol));
    // setSelectedSymbol(symbol);
    const formattedDate = date.replaceAll(" ", "%20");
    setSelectedDate(formattedDate);
    setUpd(symbol);
    setActiveDate(formattedDate); // Setting the active date
    // console.log(date)

    // await fetchDataForSymbol(symbol.scrip, formattedDate); // Await the completion of the function
    const optdt = await getOptionData(symbol.scrip, formattedDate); // Await the completion of the function
    // setTimeout(() => {
    setSelectedExpiry(date);
    setAllOptionData((prevStatus) => ({
      ...prevStatus,
      [date]: optdt,
    }));

    const allstr = extractStrikeValues(optdt);

    const [tS, tC] = updateCodeSymbol(optdt, ind);
    const newobj = [tC];
    subscribeToSymbols(newobj, "NFO");
    const newste = selectedData.map((obj, index) => {
      if (index == ind) {
        return {
          ...obj,
          expiry: date,
          allstrks: allstr,
          tradingSymbol: tS,
          code: tC,
          allData: optdt.options,
          price: null,
          iv: null,
        };
      }
      return obj;
    });
    // updateSymbol()
    // setSymbols(newobj);
    // console.log(newste);
    setData(newste);
    // updateSymbol()
    // handleStrategyOnSymbolChange();
    // console.log(newste, ind);
  };

  const getCodeTradingSymbolMappings = (data, key) => {
    const codeTradingSymbol = [];
    for (const item of data) {
      const { code } = item[key];
      codeTradingSymbol.push(code);
    }
    return codeTradingSymbol;
  };

  useLayoutEffect(() => {
    const fetchDefaultData = () => {
      if (
        selectedSymbol &&
        selectedSymbol.option_expiries &&
        selectedSymbol.option_expiries.length > 0
      ) {
        const defaultDate = selectedSymbol.option_expiries[0]; // Selecting the first date
        const formattedDate = defaultDate.replaceAll(" ", "%20");
        if (optionData) {
          if (selectedSymbol.scrip + defaultDate !== optionData["id"]) {
            fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
          }
        } else {
          fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
        }
      }
    };
    fetchDefaultData();
  }, [selectedSymbol]);

  const getOptionData = async (symbol, date) => {
    let brokerName = localStorage.getItem("broker");

    if (!brokerName) {
      console.error("Broker name not found in local storage.");
      return;
    }

    brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
    const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols`;
    try {
      const response = await axios.post(apiEndpoint, {
        id: symbol + date,
        broker: brokerName,
      });
      const data = response.data;
      // Extracting trading symbols from the received data and updating allTradingSymbols
      // TODO: Remove getting only 20 and add logic to get OTM 30 and ITM 30
      let underlying_ltp = symbolData[selectedSymbol?.code]?.ltp;
      const formattedData = data["options"].map((item) => ({
        ...item,
        strike: parseInt(item.strike),
      }));

      let minDiff = Math.abs(
        formattedData[0].strike - parseFloat(underlying_ltp)
      );
      let closestIdx = 0;

      // Iterate through the array to find the closest index
      formattedData.forEach((item, index) => {
        const diff = Math.abs(item.strike - parseFloat(underlying_ltp));
        if (diff < minDiff) {
          minDiff = diff;
          closestIdx = index;
        }
      });
      const selected = formattedData.slice(
        Math.max(0, closestIdx - 10),
        Math.min(formattedData.length, closestIdx + 10)
      );
      data["options"] = selected;
      return data;
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useLayoutEffect(() => {
    if (
      Object.keys(tempOptionData).length > 0 &&
      symbolData[selectedSymbol?.code]
    ) {
      let underlying_ltp = symbolData[selectedSymbol?.code]?.ltp;
      let data = tempOptionData;
      const formattedData = data["options"].map((item) => ({
        ...item,
        strike: parseInt(item.strike),
      }));

      let minDiff = Math.abs(
        formattedData[0].strike - parseFloat(underlying_ltp)
      );
      let closestIdx = 0;

      // Iterate through the array to find the closest index
      formattedData.forEach((item, index) => {
        const diff = Math.abs(item.strike - parseFloat(underlying_ltp));
        if (diff < minDiff) {
          minDiff = diff;
          closestIdx = index;
        }
      });
      const selected = formattedData.slice(
        Math.max(0, closestIdx - 10),
        Math.min(formattedData.length, closestIdx + 10)
      );
      data["options"] = selected;
      setOptionData(data);

      const call = getCodeTradingSymbolMappings(selected, "call");
      const put = getCodeTradingSymbolMappings(selected, "put");
      const tradingSymbols = call.concat(put);

      let date = data.options[0].call?.expiry_date;
      setAllOptionData({ [date]: data });

      setAllTradingSymbols(tradingSymbols);
      setTempOptionData({});
      subscribeToSymbols(tradingSymbols, "NFO");
    }
  }, [tempOptionData, symbolData]);

  const fetchDataForSymbol = async (symbol, date) => {
    let brokerName = localStorage.getItem("broker");

    if (!brokerName) {
      console.error("Broker name not found in local storage.");
      return;
    }

    brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
    const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols`;
    try {
      if (Object.keys(tempOptionData).length === 0) {
        const response = await axios.post(apiEndpoint, {
          id: symbol + date,
          broker: brokerName,
        });
        const data = response.data;
        setTempOptionData(data);

        if (Object.keys(allTradingSymbols).length > 0) {
          unsubscribeToSymbols(allTradingSymbols, "NFO");
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  // Define the updateSelectedSymbol function
  const updateSelectedSymbol = (symbol) => {
    setSelectedSymbol(symbol);
    // setTimeout(() => {
    //   console.log(optionData.options,selectedSymbol)
    // handleStrategyOnSymbolChange();
    // }, 500);
  };

  const computeGreeks = () => {
    //using parsefloat only once so we dont need to use multiple times
    const ltp = parseFloat(symbolData[selectedSymbol?.code]?.ltp);
    const riskFreeRate = 0.1 / 365; //moved this out of the loop

    // using forrach instead of map
    Object.keys(allOptionData).forEach((key) => {
      const options = allOptionData[key]?.options;
      options.forEach((data) => {
        const call_option = data.call;
        const put_option = data.put;
        const strike = data.strike;

        const isCallOption = strike > ltp;
        const optionType = isCallOption ? "call" : "put";
        const relevantOption = isCallOption ? call_option : put_option;

        const targetDateTimeString = put_option.expiry_date + " 15:29:59"; // Target date and time
        const targetDateTime = new Date(targetDateTimeString);
        const currentDate = new Date();
        let timeToExpiration = Math.max(
          0,
          (targetDateTime - currentDate) / (24 * 60 * 60 * 365 * 1000) ||
            0.0002 / 365
        );
        const foundItem = selectedData?.find(
          (item) => item.code === relevantOption?.code
        );
        let currentStockPrice;
        if (foundItem?.price) {
          currentStockPrice = foundItem.price;
        } else {
          currentStockPrice = parseFloat(symbolData[relevantOption?.code]?.ltp);
        }
        const initialGuessIv = iv.getImpliedVolatility(
          currentStockPrice,
          ltp,
          strike,
          timeToExpiration,
          riskFreeRate,
          optionType
        );
        if (!call_option.iv) {
          //instead of repeting same calculations for calls and puts, combining them here
          if (initialGuessIv <= 1) {
            const ivValue = (initialGuessIv * 100).toFixed(2);
            const deltaValue = (
              greeks.getDelta(
                ltp,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            const thetaValue = (
              greeks.getTheta(
                ltp,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            const gammaValue = (
              greeks.getGamma(
                ltp,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            const vegaValue = (
              greeks.getVega(
                ltp,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "call"
              ) * 100
            ).toFixed(2);
            data.call.iv = ivValue;
            data.call.delta = deltaValue;
            data.call.theta = thetaValue;
            data.call.gamma = gammaValue;
            data.call.vega = vegaValue;
          }
        }
        if (!put_option.iv) {
          if (initialGuessIv <= 1) {
            const ivValue = (initialGuessIv * 100).toFixed(2);
            const deltaValue = (
              greeks.getDelta(
                ltp,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            const thetaValue = (
              greeks.getTheta(
                ltp,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            const gammaValue = (
              greeks.getGamma(
                ltp,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            const vegaValue = (
              greeks.getVega(
                ltp,
                strike,
                timeToExpiration,
                initialGuessIv,
                riskFreeRate,
                "put"
              ) * 100
            ).toFixed(2);
            data.put.iv = ivValue;
            data.put.delta = deltaValue;
            data.put.theta = thetaValue;
            data.put.gamma = gammaValue;
            data.put.vega = vegaValue;
          }
        }
      });
    });
  };

  useEffect(() => {
    handleStrategyOnSymbolChange();
  }, [optionData]);

  useLayoutEffect(() => {
    computeGreeks();
  }, [selectedData, symbolData, allOptionData]);

  useLayoutEffect(() => {
    updateSelectedDataGreeks();
  }, [selectedData, optionData]);

  useLayoutEffect(() => {
    setLotx(1);
  }, [selectedSymbol]);

  const updateSelectedDataGreeks = () => {
    selectedData.map((row, index) => {
      const option_index = optionData.options.findIndex(
        (jsonObj) => jsonObj.strike === row.strike
      );
      if (option_index != -1) {
        let optiondata = row.isCALL
          ? optionData.options[option_index].call
          : optionData.options[option_index].put;

        let delta = optiondata?.delta;
        let theta = optiondata?.theta;
        let gamma = optiondata?.gamma;
        let vega = optiondata?.vega;
        if (!row.isBuy) {
          delta = -delta;
          theta = -theta;
          vega = -vega;
          gamma = -gamma;
        }
        optiondata.delta = delta;
        optiondata.theta = theta;
        optiondata.gamma = gamma;
        optiondata.vega = vega;
      }
    });
  };

  const [activeOption, setActiveOption] = useState("intraday");

  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  // For Bullish, Bearish... buttons active class
  const [activeButton, setActiveButton] = useState("bullish");
  // This useEffect will be executed once when the component mounts

  const handleButtonClick = (button) => {
    setActiveButton(button);
    const mainGraph = document.querySelector(".main-graph");
    const tableContainer = document.querySelector(".table-container");

    // if (button === "p&ltable") {
    //   mainGraph.style.display = "none";
    //   tableContainer.style.display = "block";
    // } else {
    //   mainGraph.style.display = "block";
    //   tableContainer.style.display = "none";
    // }
  };
  // setLotsValue((prevLotsValue) => prevLotsValue + 1);
  const handleIncrement = (lot, index) => {
    const dt = lot;
    // const index = props.index;
    const newstate = selectedData.map((obj, ind) => {
      if (index == ind) {
        let f = parseInt(obj.lotSize);
        return { ...obj, lotSize: f + 1 };
      }
      return obj;
    });
    setData(newstate);
  };

  const handleDecrement = (lot, index) => {
    if (lot > 1) {
      // if(dt)/
      const newstate = selectedData.map((obj, ind) => {
        if (index == ind) {
          let f = parseInt(obj.lotSize);
          return { ...obj, lotSize: f - 1 };
        }
        return obj;
        // setLotsValue((prevLotsValue) => prevLotsValue - 1);
      });
      setData(newstate);
    }
  };

  const [lotx, setLotx] = useState(1);
  const handleLotxIncrement = () => {
    let updatedLotx = lotx + 1;
    const newstate = selectedData.map((obj, ind) => {
      let initial_lot = obj.lotSize / lotx;
      let updated_lot = initial_lot * updatedLotx;
      return { ...obj, lotSize: updated_lot };
    });
    setData(newstate);
    setLotx((prevLotxValue) => prevLotxValue + 1);
  };

  const handleLotxDecrement = () => {
    if (lotx > 1) {
      let updatedLotx = lotx - 1;
      const newstate = selectedData.map((obj, ind) => {
        let initial_lot = obj.lotSize / lotx;
        let updated_lot = initial_lot * updatedLotx;
        return { ...obj, lotSize: updated_lot };
      });
      setData(newstate);
      setLotx((prevLotxValue) => prevLotxValue - 1);
    }
  };

  // for graph table:
  //for range slider
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value);
    setSliderValue(value);
  };

  // Extracting strike
  const extractStrikeValues = (optionData) => {
    if (!optionData || !Array.isArray(optionData.options)) {
      return [];
      // return optionData.options.map((options) => options.strike);
    }
    return optionData.options.map((options) => options.strike);
  };

  // const extractedStrikeValues = extractStrikeValues(optionData);
  // console.log("EXTRACTED STRIKE VALUES:", extractedStrikeValues);

  // FOR STRIKE VALUES:
  // State to keep track of the current index of the strike value
  const [currentStrikeIndex, setCurrentStrikeIndex] = useState(0);

  // Function to handle the increment (next) behavior
  const handleNextStrike = async (ind) => {
    let dat = selectedData[ind];
    let prevstr = dat.strike;
    let allstrks = dat.allstrks;
    if (allstrks.length == 0) {
      const formattedDate = dat.expiry.replaceAll(" ", "%20");
      await fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
      allstrks = extractStrikeValues(optionData);
    }
    let prev = allstrks.find((ob) => ob == prevstr);
    let prevind = allstrks.indexOf(prev);
    let newind;
    if (prevind == undefined) {
      newind = 0;
    }
    if (prevind == allstrks.length - 1) {
      newind = 0;
    } else {
      newind = parseInt(prevind) + 1;
    }
    const newstr = allstrks[newind];
    // const [tS,tC]=updateCodeSymbol(selectedData[ind].allData,newind)
    let tSymbol = selectedData[ind].trading_symbol;
    let tCode = selectedData[ind].code;
    let newRow = selectedData[ind].allData.find((o, i) => {
      if (o.strike == newstr) {
        return o;
      }
    });
    if (newRow != undefined && newRow != null) {
      if (selectedData[ind].isCALL) {
        tSymbol = newRow.call.trading_symbol;
        tCode = newRow.call.code;
      } else {
        tSymbol = newRow.put.trading_symbol;
        tCode = newRow.put.code;
      }
    }
    const newstate = selectedData.map((obj, index) => {
      if (index == ind) {
        const newobj = [tCode];
        subscribeToSymbols(newobj, "NFO");
        return {
          ...obj,
          strike: parseInt(newstr),
          tradingSymbol: tSymbol,
          code: tCode,
          code: tCode,
          price: null,
          iv: null,
        };
      }
      return obj;
    });
    setData(newstate);
  };

  // Function to handle the decrement (previous) behavior
  const setCALL = (ind) => {
    let tSymbol = selectedData[ind].trading_symbol;
    let tCode = selectedData[ind].code;
    let newRow = selectedData[ind].allData.find((o, i) => {
      if (o.strike == selectedData[ind].strike) {
        return o;
      }
    });
    if (newRow != undefined && newRow != null) {
      if (selectedData[ind].isCALL) {
        tSymbol = newRow.put.trading_symbol;
        tCode = newRow.put.code;
      } else {
        tSymbol = newRow.call.trading_symbol;
        tCode = newRow.call.code;
      }
    }
    const newstate = selectedData.map((obj, index) => {
      if (index == ind) {
        let f = obj.isCALL;
        const newobj = [tCode];
        subscribeToSymbols(newobj, "NFO");
        return {
          ...obj,
          isCALL: !f,
          tradingSymbol: tSymbol,
          code: tCode,
          code: tCode,
          price: null,
          iv: null,
        };
      }
      return obj;
    });
    setData(newstate);
  };

  const setBuy = (ind) => {
    // IsBuy((prevValue) => !prevValue)
    const newstate = selectedData.map((obj, index) => {
      if (index == ind) {
        let f = obj.isBuy;
        return { ...obj, isBuy: !f };
      }
      return obj;
    });
    setData(newstate);
  };
  const handlePrevStrike = async (ind) => {
    let dat = selectedData[ind];
    let prevstr = dat.strike;
    // const formattedDate = dat.expiry.replaceAll(" ", "%20");
    // await fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
    let allstrks = dat.allstrks;
    if (allstrks.length == 0) {
      const formattedDate = dat.expiry.replaceAll(" ", "%20");
      await fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
      allstrks = extractStrikeValues(optionData);
    }
    let prev = allstrks.find((ob) => ob == prevstr);
    let prevind = allstrks.indexOf(prev);
    // console.log(prev, prevind, allstrks)
    let newind;
    if (prevind <= 0 || prevind == undefined) {
      newind = allstrks.length - 1;
    } else {
      newind = parseInt(prevind) - 1;
    }
    const newstr = allstrks[newind];
    // console.log(newstr)
    let tSymbol = selectedData[ind].trading_symbol;
    let tCode = selectedData[ind].code;
    let newRow = selectedData[ind].allData.find((o, i) => {
      if (o.strike == newstr) {
        return o;
      }
    });
    if (newRow != undefined && newRow != null) {
      if (selectedData[ind].isCALL) {
        tSymbol = newRow.call.trading_symbol;
        tCode = newRow.call.code;
      } else {
        tSymbol = newRow.put.trading_symbol;
        tCode = newRow.put.code;
      }
    }
    // console.log(newRow);
    const newstate = selectedData.map((obj, index) => {
      if (index == ind) {
        // const newobj = {
        //   ...symbols,
        //   [tCode]: tSymbol,
        // };
        // setSymbols(newobj);
        const newobj = [tCode];
        subscribeToSymbols(newobj, "NFO");
        // updateSymbol()
        return {
          ...obj,
          strike: parseInt(newstr),
          tradingSymbol: tSymbol,
          code: tCode,
          code: tCode,
          price: null,
          iv: null,
        };
      }
      return obj;
    });
    setData(newstate);
    // setCurrentStrikeIndex((prevIndex) =>
    //   prevIndex === 0 ? extractedStrikeValues.length - 1 : prevIndex - 1
    // );
  };

  // Array containing the extracted strike values
  const extractedStrikeValues = extractStrikeValues(optionData);

  // Display the first strike value initially
  // const initialStrikeValue =
  //   extractedStrikeValues.length > 0 ? extractedStrikeValues[0] : null;

  //FOR BUY/SELL VALUE TOGGLE AND STYLE
  const [isB, setIsB] = useState(true); // Assuming B is the initial value
  const [isCE, setIsCE] = useState(true); // Assuming CE is the initial value

  const toggleBsValue = () => {
    setIsB((prevValue) => !prevValue);
  };
  const toggleCEPE = () => {
    setIsCE((prevValue) => !prevValue);
  };

  ///////////////////////////////////////////////////
  const createDataArray = () => {
    if (!optionData || !Array.isArray(optionData.options)) {
      return [];
    }
    const currentOption = optionData.options[currentStrikeIndex];

    const dataArray = [
      {
        buy_sell: isBuy ? "BUY" : "SELL",
        expiry: selectedExpiry,
        strike: currentOption.strike,
        option_type: isCALL ? "CE" : "PE",
        quantity: lotsValue,
        price: priceValue,
        instrument_name: isCALL
          ? currentOption.call.instrument_name
          : currentOption.put.instrument_name,
        lot_size: currentOption.call.lot_size,
        trading_symbol: isCALL
          ? currentOption.call.trading_symbol
          : currentOption.put.trading_symbol,
      },
    ];

    return dataArray;
  };

  const [latestExpiry, setLatestExpiry] = useState("");
  const [latestStrikeIndex, setLatestStrikeIndex] = useState(0);
  const [isBuy, setIsBuy] = useState(true);
  const [isCALL, setIsCALL] = useState(true);

  const [lotsValue, setLotsValue] = useState(1);
  const [priceValue, setPriceValue] = useState("");

  // Calling the function to create the data array
  // const dataArray = createDataArray(
  //   optionData,
  //   latestExpiry,
  //   latestStrikeIndex,
  //   isBuy,
  //   isCALL,
  //   lotsValue,
  //   priceValue
  // );

  // console.log(dataArray);
  // useEffect(() => {
  // const dataArray = createDataArray();
  // console.log(dataArray);
  // }, [latestExpiry, latestStrikeIndex, isBuy, isCALL, lotsValue, priceValue]);

  // const showOrder = () => {
  //   console.log(selectedData, symbols);
  // };
  const showMostTradedSymbols = true;

  const handleDeleteRow = (index) => {
    if (selectedData.length === 1) {
      // dont do anything if only 1 row is present
      return;
    }
    // delete the row of specifc index
    const updatedData = [...selectedData];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  const handleOKClick = async () => {
    const payload = generateOrderPayload();
    try {
      const response = await sendOrder(payload);
      const overlay = document.querySelector(".overlay");
      overlay.classList.remove("active");
      if (response.status) {
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error handling order:", error);
      toast.error(
        <CustomToastContent
          heading="Error handling order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  const handleCloseClick = () => {
    console.log("Clicked closed!");
    const overlay = document.querySelector(".overlay");
    overlay.classList.remove("active"); // Remove the "active" class to hide the overlay
    toast.dismiss();
  };

  const formatSymbol = (symbol) => {
    return symbol?.replace(selectedSymbol.scrip, "");
  };

  const showToast = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.add("active"); // Add the "active" class to show the overlay

    toast(
      <div className="d-flex align-items-center justify-content-between">
        <div>Sure, you want to execute this strategy?</div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              onClick={handleCloseClick}>
              <path
                d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                stroke="#FF3908"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="confirm-popup-ok d-flex justify-content-center align-items-center"
            onClick={handleOKClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none">
              <path
                d="M1 6.65685L6.65685 12.3137L17.9706 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>,
      {
        position: "bottom-center",
        closeButton: false, // Hide the built-in close button
        icon: false,
        className: "confirm-popup-toast", // Apply the custom CSS class
        hideProgressBar: true,
        draggable: true,
        autoClose: false,
      }
    );
  };

  const [checkboxStates, setCheckboxStates] = useState([]);
  useEffect(() => {
    setCheckboxStates(Array(selectedData.length).fill(true));
  }, [selectedData]);

  const handleCheckboxChange = (index) => {
    setCheckboxStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  // Function to handle price input change for a specific row
  const handlePriceChange = (e, ind) => {
    const newstate = selectedData.map((obj, index) => {
      if (index == ind) {
        return { ...obj, price: e.target.value };
      }
      return obj;
    });
    setData(newstate);
  };

  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const [isStrategiesOpen, setIsStrategiesOpen] = useState(false);

  const handleOpenBasket = () => {
    setIsBasketOpen(true);
  };

  const handleCloseBasket = () => {
    setIsBasketOpen(false);
  };

  const handleOpenStrategies = () => {
    setIsStrategiesOpen(true);
  };

  const handleCloseStrategies = () => {
    setIsStrategiesOpen(false);
  };

  const [expandedRows, setExpandedRows] = useState(
    new Array(selectedData.length).fill(false)
  );
  const toggleRowExpansion = (index) => {
    const newExpandedRows = [...expandedRows];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
  };

  const [orderPayload, setOrderPayload] = useState(null);
  const [marginRequired, setMarginRequired] = useState(null);
  const generateOrderPayload = () => {
    const orderList = selectedData.map((currData, ind) => ({
      isBuy: currData.isBuy,
      isCall: currData.isCALL,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeOption === "normal" ? "NRML" : "MIS",
      orderType: checkboxStates[ind] ? "MARKET" : "LIMIT",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiry,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
    }));
    const payload = {
      order_list: orderList,
      broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
    console.log("send order payload", payload);
    return payload;
  };

  const generateVirtualOrderPayload = () => {
    const orderList = selectedData.map((currData, ind) => ({
      isBuy: currData.isBuy,
      isCall: currData.isCALL,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeOption === "normal" ? "NRML" : "MIS",
      orderType: checkboxStates[ind] ? "MARKET" : "LIMIT",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiry,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
    }));
    const payload = {
      order_list: orderList,
      main_broker: broker,
      broker: "virtual",
      access_token: access_token,
      client_id: client_id,
    };
    console.log("send order payload", payload);
    return payload;
  };

  const handleVirtaulOKClick = async () => {
    const payload = generateVirtualOrderPayload();
    try {
      const response = await sendVirtualOrder(payload);
      const overlay = document.querySelector(".overlay");
      overlay.classList.remove("active");
      if (response.status) {
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error handling order:", error);
      toast.error(
        <CustomToastContent
          heading="Error handling order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  const showVirtualToast = () => {
    const overlay = document.querySelector(".overlay");
    overlay.classList.add("active");

    toast(
      <div className="d-flex align-items-center justify-content-between">
        <div>Sure, you want to add these virtual positions?</div>
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              onClick={handleCloseClick}>
              <path
                d="M1 1.00002L13.7742 13.7742M1 13.7742L13.7742 1"
                stroke="#FF3908"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="confirm-popup-ok d-flex justify-content-center align-items-center"
            onClick={handleVirtaulOKClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              viewBox="0 0 19 14"
              fill="none">
              <path
                d="M1 6.65685L6.65685 12.3137L17.9706 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>,
      {
        position: "bottom-center",
        closeButton: false,
        icon: false,
        className: "confirm-popup-toast",
        hideProgressBar: true,
        draggable: true,
        autoClose: false,
      }
    );
  };

  const sendVirtualOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://hgb1gw5n6g.execute-api.ap-south-1.amazonaws.com/default/og_virtual_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };
  const sendOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://0eo1dv23p5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };
  // marginRequired api call
  useLayoutEffect(() => {
    const generateMarginPayload = () => {
      const orderList = selectedData.map((currData, ind) => ({
        isBuy: currData.isBuy,
        isCall: currData.isCALL,
        isFut: false,
        code: currData.code,
        exchange: currData.exchange,
        productType: activeOption === "normal" ? "NRML" : "MIS",
        orderType: checkboxStates[ind] ? "MARKET" : "LIMIT",
        quantity: currData.lotSize * currData.lot_multiplier,
        price: currData?.price,
        strike: currData.strike,
        expiry: currData.expiry,
        tradingSymbol: currData.tradingSymbol,
        lot_multiplier: currData.lot_multiplier,
        scrip: currData.scrip,
      }));
      return {
        order_list: orderList,
        broker: broker,
        access_token: access_token,
        client_id: client_id,
      };
    };

    const fetchMarginRequired = async () => {
      try {
        const payload = generateMarginPayload();
        const response = await axios.post(
          "https://7mwh6csmrh.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_margin",
          payload
        );
        setMarginRequired(response.data.marginRequired);
      } catch (error) {
        console.error("Error getting margin required:", error);
      }
    };
    if (selectedData.length > 0) {
      fetchMarginRequired();
    }
  }, [selectedData]);

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.toLocaleDateString(undefined, { day: "2-digit" });
    const month = date.toLocaleDateString(undefined, { month: "short" });
    const year = date.toLocaleDateString(undefined, { year: "2-digit" });

    return `${day} ${month} ${year}`;
  };
  return (
    <div className="d-grid mb-5 mt-3" style={{ gridColumn: "span 4" }}>
      <div className="overlay"></div>
      <SymbolSelection
        selectedSymbol={selectedSymbol}
        fetchDataForSymbol={fetchDataForSymbol}
        updateSelectedSymbol={updateSelectedSymbol}
        handleSymbolSelection={handleSymbolSelection}
        showMostTradedSymbols={showMostTradedSymbols}
      />
      {computedData?.maxLoss === "Unlimited" ? (
        <div className="limit-banner d-flex justify-content-between align-items-center px-3 mt-3">
          <span className="limit-risk-text">
            Your strategy has unlimited risk!
          </span>
          <button className="limit-risk-btn" onClick={addHedge}>
            Limit my risk
          </button>
        </div>
      ) : (
        ""
      )}
      <div
        className="create-strategy d-flex flex-column justify-content-start mt-3"
        style={{ height: "auto" }}>
        <div className="header-box d-flex justify-content-between align-items-center py-2 px-2">
          <div className="d-flex gap-2 align-items-center justify-content-center">
            <div className="lotx ">Lots X</div>
            <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
              <span
                role="button"
                onClick={handleLotxDecrement}
                style={{
                  color: "#C1C1C1",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "0px",
                }}>
                -
              </span>
              <span>{lotx}</span>
              <span
                role="button"
                className="plus"
                onClick={handleLotxIncrement}
                style={{
                  color: "#C1C1C1",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "0px",
                }}>
                +
              </span>
            </div>
          </div>
          <div className="d-flex gap-2">
            <div className="toggle-container">
              <button
                className={`toggle-button ${
                  activeOption === "intraday" ? "active" : ""
                }`}
                onClick={() => handleOptionChange("intraday")}>
                Intraday
              </button>
              <button
                className={`toggle-button ${
                  activeOption === "normal" ? "active" : ""
                }`}
                onClick={() => handleOptionChange("normal")}>
                Normal
              </button>
            </div>
            <div className="d-flex flex-column pos-table-more dropdown">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                className="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <path
                  d="M1 1H15M1 5.5H15M1 10H15"
                  stroke="#14142B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="strategy-table-dropdown dropdown-menu  py-0">
                <li className="dropdown-item px-2">
                  <button
                    className="create-strategy-btn"
                    onClick={handleOpenStrategies}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      viewBox="0 0 14 18"
                      fill="#000000"
                      className="me-1 mt-1">
                      <g clip-path="url(#clip0_454_199)">
                        <path
                          d="M8.78566 6.78402C8.78566 6.45052 9.05776 6.17977 9.39293 6.17977C9.7279 6.17977 10 6.45052 10 6.78402V8.5008C10 8.91289 9.83045 9.28842 9.55776 9.55996C9.28507 9.83129 8.90766 10 8.49332 10H1.50668C1.09234 10 0.714931 9.83129 0.44224 9.55996C0.169548 9.28842 0 8.91289 0 8.5008V1.4992C0 1.08691 0.169548 0.711381 0.44224 0.440044C0.714931 0.168706 1.09234 0 1.50668 0H3.21552C3.55069 0 3.82259 0.270751 3.82259 0.604058C3.82259 0.937561 3.55069 1.20831 3.21552 1.20831H1.50668C1.42672 1.20831 1.35305 1.24135 1.3002 1.29374C1.24754 1.34633 1.21434 1.41963 1.21434 1.4992V8.5008C1.21434 8.58017 1.24754 8.65367 1.3002 8.70606C1.35305 8.75845 1.42672 8.79169 1.50668 8.79169H8.49332C8.57328 8.79169 8.64695 8.75845 8.6998 8.70606C8.75246 8.65367 8.78566 8.58017 8.78566 8.5008V6.78402ZM8.78566 2.02272L6.05403 4.77538C5.82004 5.01232 5.43615 5.01544 5.19823 4.78262C4.96031 4.54979 4.95697 4.16781 5.19096 3.93107L7.89332 1.20831H6.08448C5.74931 1.20831 5.47741 0.937561 5.47741 0.604058C5.47741 0.270751 5.74931 0 6.08448 0H9.39293C9.7279 0 10 0.270751 10 0.604058V3.83039C10 4.1639 9.7279 4.43445 9.39293 4.43445C9.05776 4.43445 8.78566 4.1639 8.78566 3.83039V2.02272Z"
                          fill="#000000"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_454_199">
                          <rect width="10" height="10" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                    Strategies
                  </button>
                </li>
                <li className="dropdown-item px-2">
                  <button
                    className="create-strategy-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdropOptionChainMobile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      viewBox="0 0 14 18"
                      fill="#000000"
                      className="me-1 mt-1">
                      <g clip-path="url(#clip0_454_199)">
                        <path
                          d="M8.78566 6.78402C8.78566 6.45052 9.05776 6.17977 9.39293 6.17977C9.7279 6.17977 10 6.45052 10 6.78402V8.5008C10 8.91289 9.83045 9.28842 9.55776 9.55996C9.28507 9.83129 8.90766 10 8.49332 10H1.50668C1.09234 10 0.714931 9.83129 0.44224 9.55996C0.169548 9.28842 0 8.91289 0 8.5008V1.4992C0 1.08691 0.169548 0.711381 0.44224 0.440044C0.714931 0.168706 1.09234 0 1.50668 0H3.21552C3.55069 0 3.82259 0.270751 3.82259 0.604058C3.82259 0.937561 3.55069 1.20831 3.21552 1.20831H1.50668C1.42672 1.20831 1.35305 1.24135 1.3002 1.29374C1.24754 1.34633 1.21434 1.41963 1.21434 1.4992V8.5008C1.21434 8.58017 1.24754 8.65367 1.3002 8.70606C1.35305 8.75845 1.42672 8.79169 1.50668 8.79169H8.49332C8.57328 8.79169 8.64695 8.75845 8.6998 8.70606C8.75246 8.65367 8.78566 8.58017 8.78566 8.5008V6.78402ZM8.78566 2.02272L6.05403 4.77538C5.82004 5.01232 5.43615 5.01544 5.19823 4.78262C4.96031 4.54979 4.95697 4.16781 5.19096 3.93107L7.89332 1.20831H6.08448C5.74931 1.20831 5.47741 0.937561 5.47741 0.604058C5.47741 0.270751 5.74931 0 6.08448 0H9.39293C9.7279 0 10 0.270751 10 0.604058V3.83039C10 4.1639 9.7279 4.43445 9.39293 4.43445C9.05776 4.43445 8.78566 4.1639 8.78566 3.83039V2.02272Z"
                          fill="#000000"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_454_199">
                          <rect width="10" height="10" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                    Option Chain
                  </button>
                </li>
                <li className="dropdown-item px-2">
                  <button
                    className="create-strategy-btn"
                    onClick={handleOpenBasket}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.54546 0L0 11.4546H6.54546V18L13.0909 6.54546H6.54546V0Z"
                        fill="url(#paint0_linear_724_574)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_724_574"
                          x1="6.54546"
                          y1="0"
                          x2="6.54546"
                          y2="18"
                          gradientUnits="userSpaceOnUse">
                          <stop stopColor="#FF3908" />
                          <stop offset="1" stopColor="#FF7834" />
                        </linearGradient>
                      </defs>
                    </svg>{" "}
                    Auto find
                  </button>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="table-first-container">
          <table className="strategy-table w-100">
            <thead>
              <tr
                className="tr-strategy-headerr  d-flex align-items-center gap-2 ps-2"
                style={{ borderBottom: "2px solid #EFEFEF" }}>
                <th scope="col">B/S</th>
                <th scope="col" className="ps-4 ms-1">
                  Instrument
                </th>
                {expandedRows && <th scope="col"></th>}
                <th scope="col" className="ms-3 ps-5">
                  <span className="ms-5">Price</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedData.map((currData, ind) => (
                <Fragment key={ind}>
                  <tr
                    className={`strategy-table-row text-center d-flex align-items-center justify-content-between gap-1 ps-2 ${
                      expandedRows[ind] ? "expanded-row" : ""
                    }`}>
                    <td>
                      <div
                        className="bs-value d-flex align-items-center justify-content-center"
                        style={{
                          height: "20px",
                          width: "20px",
                          background: currData.isBuy ? "#70D11F" : "#FF3908",
                          color: "white",
                        }}
                        onClick={() => setBuy(ind)}
                        role="button">
                        {currData.isBuy ? "B" : "S"}
                      </div>
                    </td>
                    {!expandedRows[ind] ? (
                      <td className="table-trading-symbol">
                        {currData.lotSize} <span className="px-1"> X</span>{" "}
                        {formatSymbol(currData?.tradingSymbol)}
                      </td>
                    ) : (
                      <td>
                        <div className="dropdown">
                          <button
                            className="expiry-dates-dropdown btn-date dropdown-toggle px-0"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {currData.expiry
                              ? formatDate(currData.expiry)
                              : "Select Expiry"}{" "}
                          </button>

                          <ul className="dropdown-menu text-center rounded-0 px-1">
                            {selectedSymbol?.option_expiries &&
                              Array.isArray(selectedSymbol.option_expiries) &&
                              selectedSymbol.option_expiries.map(
                                (expiryDate, index) => (
                                  <li
                                    className="dropdown-item-hover"
                                    role="button"
                                    key={index}
                                    onClick={() => {
                                      handleSymbolSelection(
                                        selectedSymbol,
                                        expiryDate,
                                        ind
                                      );
                                    }}>
                                    {formatDate(expiryDate)}
                                  </li>
                                )
                              )}
                          </ul>
                        </div>
                      </td>
                    )}
                    {expandedRows[ind] && (
                      <td>
                        <div
                          className="ce d-flex align-items-center justify-content-center mx-auto px-0"
                          style={{
                            color: currData.isCALL ? "#E2A838" : "#757AFA",
                            width: "30px",
                            height: "30px",
                            borderRadius: "4px",
                            background: "#fff",
                            border: `1px solid ${
                              currData.isCALL ? "#E2A838" : "#757AFA"
                            }`,
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "0px",
                          }}
                          role="button"
                          onClick={() => setCALL(ind)}>
                          {currData.isCALL ? "CE" : "PE"}
                        </div>
                      </td>
                    )}
                    <td>
                      <div className="price d-flex align-items-center justify-content-between px-2">
                        <input
                          type="checkbox"
                          checked={checkboxStates[ind]}
                          onChange={() => handleCheckboxChange(ind)}
                        />
                        {checkboxStates[ind] ? (
                          <div style={{ color: "#383FEC" }}>MARKET</div>
                        ) : (
                          <input
                            type="text"
                            value={currData.price}
                            className="price-input pe-1"
                            onChange={(e) => handlePriceChange(e, ind)} // Add onChange handler
                          />
                        )}
                      </div>
                    </td>
                    <td className="edit-strategy pe-2">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        onClick={() => toggleRowExpansion(ind)}
                        role="button">
                        {!expandedRows[ind] && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M9.24474 3.00569L12.8157 6.57663M11.0555 1.19505C11.0555 1.19505 12.6948 0.448399 14.1232 1.87678C15.5516 3.30516 14.8049 4.94454 14.8049 4.94454L5.26079 14.4887L1 15L1.51129 10.7392L11.0555 1.19505Z"
                              stroke="#14142B"
                            />
                          </svg>
                        )}
                      </div>
                    </td>
                  </tr>
                  {expandedRows[ind] && (
                    <tr
                      className={`strategy-table-row text-center d-flex align-items-center justify-content-between gap-1 ps-2 ${
                        expandedRows[ind] ? "expanded-row" : ""
                      }`}>
                      <td>
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <div className="expanded-row-name">Lots</div>
                          <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
                            <span
                              role="button"
                              onClick={() =>
                                handleDecrement(currData.lotSize, ind)
                              }
                              style={{
                                color: "#C1C1C1",
                                fontSize: "20px",
                                fontWeight: "500",
                                lineHeight: "0px",
                              }}>
                              -
                            </span>
                            <span>{currData.lotSize}</span>
                            <span
                              role="button"
                              className="plus"
                              onClick={() =>
                                handleIncrement(currData.lotSize, ind)
                              }
                              style={{
                                color: "#C1C1C1",
                                fontSize: "20px",
                                fontWeight: "500",
                                lineHeight: "0px",
                              }}>
                              +
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          <div className="expanded-row-name">Strike</div>
                          <div className="strategy-strike d-flex align-items-center justify-content-between px-2">
                            <span
                              style={{
                                color: "#C1C1C1",
                                fontSize: "20px",
                                fontWeight: "500",
                                lineHeight: "0px",
                              }}
                              onClick={() => handlePrevStrike(ind)}
                              role="button">
                              -
                            </span>
                            <span>{currData.strike}</span>
                            <span
                              style={{
                                color: "#C1C1C1",
                                fontSize: "20px",
                                fontWeight: "500",
                                lineHeight: "0px",
                              }}
                              onClick={() => handleNextStrike(ind)}
                              role="button">
                              +
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className=" d-flex align-items-center justify-content-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            className={`${
                              selectedData.length === 1 ? "disabled-delete" : ""
                            }`}
                            onClick={() => handleDeleteRow(ind)}>
                            <path
                              d="M11.1818 3.54545V2.90909C11.1818 1.85473 10.3271 1 9.27273 1H6.72727C5.67291 1 4.81818 1.85473 4.81818 2.90909V3.54545M1 3.54545H15M9.90909 7.36364V10.5455M6.09091 7.36364V10.5455M2.27273 3.54545H13.7273L13.3057 11.9771C13.221 13.6705 11.8234 15 10.1278 15H5.87216C4.17665 15 2.77898 13.6705 2.69431 11.9771L2.27273 3.54545Z"
                              stroke={
                                selectedData.length === 1
                                  ? "#A6A6A6"
                                  : "#14142B"
                              }
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      </td>
                      <td colSpan="5" className="pe-2">
                        <button
                          className="strategy-done"
                          onClick={() => toggleRowExpansion(ind)}>
                          Done
                        </button>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <div className="create-strategy-actions d-flex justify-content-end align-items-center px-2 mb-2 gap-3">
            <button
              className="virtual-button bg-transparent"
              onClick={showVirtualToast}>
              Add virtual position
            </button>
            <button className="execute-order-btn" onClick={showToast}>
              Execute Order
            </button>
          </div>
          <div className="creator-bottom d-flex justify-content-between align-items-center px-2 mb-2">
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">Funds</span>
              <span className="creator-bottom-value">
                ₹{" "}
                {formatNumberWithCommasAndFontWeight(fundsData.marginAvailable)}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Estmtd margin{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                    stroke="#555555"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
              </span>
              <span className="creator-bottom-value">
                {formatNumberWithCommasAndFontWeight(marginRequired)}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Charges{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                    stroke="#555555"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
              </span>
              <span className="creator-bottom-value">-</span>
            </div>
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Premium{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                    stroke="#555555"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
              </span>
              <span className="creator-bottom-value">
                ₹ {formatNumberWithCommasAndFontWeight(premium)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <StrategyMenu data={computedData} />
      </div>
      <div className="mt-2">
        <StrategyGraph
          data={computedData}
          selectedData={selectedData}
          ltp={symbolData[selectedSymbol?.code]?.ltp}
          leastExpiry={selectedData[0]?.["expiry"]}
          allOptionData={allOptionData}
        />
      </div>
      <div className="mt-3 d-grid">
        <div className="strategy-instruments">
          <div className="d-flex justify-content-between justify-content-md-start align-items-center p-3 gap-4">
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Delta{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79166 6.12492 3.79166M6.99992 9.91666V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583328 6.99992 0.583328C10.5437 0.583328 13.4166 3.45617 13.4166 7Z"
                    stroke="#555555"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="strategy-menu-value">
                {selectedData.reduce(
                  (total, obj) =>
                    (
                      parseFloat(
                        obj.isCALL
                          ? allOptionData[obj.expiry]?.options.find(
                              (callOption) => callOption.call.code === obj.code
                            )?.call.delta
                          : allOptionData[obj.expiry]?.options.find(
                              (putOption) => putOption.put.code === obj.code
                            )?.put.delta
                      ) + parseFloat(total)
                    ).toFixed(2),
                  0
                )}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Theta{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79166 6.12492 3.79166M6.99992 9.91666V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583328 6.99992 0.583328C10.5437 0.583328 13.4166 3.45617 13.4166 7Z"
                    stroke="#555555"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
              </span>
              <span className="strategy-menu-value">
                {selectedData.reduce(
                  (total, obj) =>
                    (
                      parseFloat(
                        obj.isCALL
                          ? allOptionData[obj.expiry]?.options.find(
                              (callOption) => callOption.call.code === obj.code
                            )?.call.theta
                          : allOptionData[obj.expiry]?.options.find(
                              (putOption) => putOption.put.code === obj.code
                            )?.put.theta
                      ) + parseFloat(total)
                    ).toFixed(2),
                  0
                )}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Gamma{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79166 6.12492 3.79166M6.99992 9.91666V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583328 6.99992 0.583328C10.5437 0.583328 13.4166 3.45617 13.4166 7Z"
                    stroke="#555555"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
              </span>
              <span className="strategy-menu-value">
                {selectedData.reduce(
                  (total, obj) =>
                    (
                      parseFloat(
                        obj.isCALL
                          ? allOptionData[obj.expiry]?.options.find(
                              (callOption) => callOption.call.code === obj.code
                            )?.call.gamma
                          : allOptionData[obj.expiry]?.options.find(
                              (putOption) => putOption.put.code === obj.code
                            )?.put.gamma
                      ) + parseFloat(total)
                    ).toFixed(2),
                  0
                )}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="creator-bottom-title">
                Vega{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79166 6.12492 3.79166M6.99992 9.91666V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583328 6.99992 0.583328C10.5437 0.583328 13.4166 3.45617 13.4166 7Z"
                    stroke="#555555"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="strategy-menu-value">
                {selectedData.reduce(
                  (total, obj) =>
                    (
                      parseFloat(
                        obj.isCALL
                          ? allOptionData[obj.expiry]?.options.find(
                              (callOption) => callOption.call.code === obj.code
                            )?.call.vega
                          : allOptionData[obj.expiry]?.options.find(
                              (putOption) => putOption.put.code === obj.code
                            )?.put.vega
                      ) + parseFloat(total)
                    ).toFixed(2),
                  0
                )}
              </span>
            </div>
          </div>
          <div className="table-container">
            <table className="strategy-tablee">
              <thead>
                <tr
                  className="text-center tr-strategy-headerr"
                  style={{ border: "1px solid #EFEFEF" }}>
                  <tr
                    className="d-flex justify-content-between align-items-center px-2 "
                    style={{ width: "auto" }}>
                    <th scope="col" className="py-2">
                      Instrument
                    </th>
                    <th scope="col">
                      IV{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        onClick={(event) => computeGreeks()}>
                        <path
                          d="M2.1578 6.34618C2.50705 5.12819 3.23293 4.05203 4.2314 3.27194C5.22987 2.49185 6.44967 2.04788 7.71597 2.00366C8.98227 1.95944 10.2301 2.31724 11.2805 3.02578C12.331 3.73432 13.1302 4.75722 13.5635 5.94788M13.4534 10.3325C13.0021 11.4022 12.25 12.318 11.2885 12.9688C10.327 13.6196 9.19738 13.9775 8.03653 13.999C6.87569 14.0205 5.73354 13.7047 4.74859 13.09C3.76364 12.4753 2.9782 11.588 2.48752 10.5357M3.80923 9.9566L2.09793 10.2104L1.27417 11.7317M12.0889 6.48659L13.8002 6.23282L14.6239 4.71152"
                          stroke="#555555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </th>
                  </tr>
                  <tr
                    className="d-flex justify-content-between align-items-center px-2"
                    style={{ width: "auto" }}>
                    <th scope="col" className="pb-2">
                      Delta
                    </th>
                    <th scope="col">Theta</th>
                    <th scope="col">Gamma</th>
                    <th scope="col">Vega</th>
                  </tr>
                </tr>
              </thead>
              <tbody className="tbody">
                {selectedData &&
                  selectedData.map((obj, index) => (
                    <tr
                      key={index}
                      className="strategy-table-roww"
                      style={{ height: "66px" }}>
                      <tr
                        className="d-flex  align-items-center justify-content-between px-2 pb-2"
                        style={{ width: "auto" }}>
                        <td className="table-cell">
                          <div className="d-flex align-items-center">
                            {obj?.tradingSymbol}
                          </div>
                        </td>
                        <td className="table-cell iv mt-1 d-flex align-items-center justify-content-center">
                          {/* Display calculated IV */}
                          {obj?.isCALL
                            ? allOptionData[obj.expiry]?.options.find(
                                (callOption) =>
                                  callOption.call.code === obj.code
                              )?.call.iv
                            : allOptionData[obj.expiry]?.options.find(
                                (putOption) => putOption.put.code === obj.code
                              )?.put.iv}
                        </td>
                      </tr>
                      <tr
                        className="d-flex justify-content-between align-items-center px-2"
                        style={{ width: "auto" }}>
                        <td className="table-cell">
                          {obj?.isCALL
                            ? allOptionData[obj.expiry]?.options.find(
                                (callOption) =>
                                  callOption.call.code === obj.code
                              )?.call.delta
                            : allOptionData[obj.expiry]?.options.find(
                                (putOption) => putOption.put.code === obj.code
                              )?.put.delta}
                        </td>
                        <td className="table-cell">
                          {obj.isCALL
                            ? allOptionData[obj.expiry]?.options.find(
                                (callOption) =>
                                  callOption.call.code === obj.code
                              )?.call.theta
                            : allOptionData[obj.expiry]?.options.find(
                                (putOption) => putOption.put.code === obj.code
                              )?.put.theta}
                        </td>
                        <td className="table-cell">
                          {obj?.isCALL
                            ? allOptionData[obj.expiry]?.options.find(
                                (callOption) =>
                                  callOption.call.code === obj.code
                              )?.call.gamma
                            : allOptionData[obj.expiry]?.options.find(
                                (putOption) => putOption.put.code === obj.code
                              )?.put.gamma}
                        </td>
                        <td className="table-cell ">
                          {obj?.isCALL
                            ? allOptionData[obj.expiry]?.options.find(
                                (callOption) =>
                                  callOption.call.code === obj.code
                              )?.call.vega
                            : allOptionData[obj.expiry]?.options.find(
                                (putOption) => putOption.put.code === obj.code
                              )?.put.vega}
                        </td>
                      </tr>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isBasketOpen && (
        <ScreenerMobile
          isOpen={isBasketOpen}
          onClose={handleCloseBasket}
          parentCallback={handleScreenerback}
        />
      )}
      {isStrategiesOpen && (
        <StrategiesMobile
          isOpen={isStrategiesOpen}
          onClose={handleCloseStrategies}
          parentCallback={applyPreBuiltStrategy}
        />
      )}

      <OptionChainMobile
        parentCallback={handleCallback}
        parentCallback2={callBackToUpdateAlloption}
        optionData={optionData}
        selectedSymbol={selectedSymbol}
      />
    </div>
  );
};

export default StrategyCreatorMobile;
