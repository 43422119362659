import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import PositionsTable from "../components/PositionsTable";
import StrategyMenu from "../components/StrategyMenu";
import ABWebSocketContext from "../context/ABWebsocketContext";
import AnalyseGraph from "../components/AnalyseGraph";
import { useGraphContext } from "../context/GraphContext";
import StrategyGraph from "../components/StrategyGraph";

const AnalyseModal = ({
  data,
  selectedData,
  selectedSymbol,
  type,
  index,
}) => {
  const { symbolData } = useContext(ABWebSocketContext);
  
  return (
    <div
      className="modal fade analyse-chain-modal"
      id="staticBackdropAnalyseModal"
      data-bs-backdrop="static"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      data-bs-keyboard="false"
      aria-hidden="true">
      <div className="modal-dialog analyse-chain-modal-dialog">
        <div className="modal-content analyse-chain-modal-content">
          <div className="modal-header">
            <h1
              className="modal-title analyse-modal-title"
              id="exampleModalLabel">
              Analyse positions
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div
            className="modal-body analyse-chain-modal-body"
            style={{ padding: "12px" }}>
            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
              <StrategyMenu data={data} />
              {selectedData && <StrategyGraph
                data={data}
                selectedData={selectedData}
                ltp={symbolData[selectedSymbol?.code]?.ltp}
                leastExpiry={selectedData?.[0]?.["expiry"]}
                // allOptionData={allOptionData}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyseModal;
