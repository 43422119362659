import axios from "axios";

let symbolsData = null;

export const getSymbols = async () => {
  let brokerName = localStorage.getItem("broker");
  if (!brokerName) {
    console.error("Broker name not found in local storage.");
    return;
  }
  brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
  if (symbolsData) {
    return symbolsData;
  } else {
    try {
      const response = await axios.post(
        'https://qf1csya8oe.execute-api.ap-south-1.amazonaws.com/default/og_allsymbols',
        {
          "broker": brokerName
        }
      );
      const data = response.data;
      symbolsData = data;
      return symbolsData;
    } catch (error) {
      console.error("Error fetching symbols:", error);
      return null;
    }
  }
};

export const getOrders = async (access_token, client_id, broker) => {
  try {
    const body = {
      access_token: access_token,
      client_id: client_id,
      broker: broker,
    };
    const endpoint_url =
      "https://uoeebamrzf.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_orders";
    const response = await axios.post(endpoint_url, body);
    let ordersData = response.data;
    return ordersData;
  } catch (error) {
    console.error("Error fetching orders:", error);
    return null;
  }
};
