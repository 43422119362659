import React, { useState } from "react";
import { useBasketContext } from "../context/BasketContext";
import { useGraphContext } from "../context/GraphContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContent from "../components/CustomToastContent";
import SuccessIcon from "../images/success_icon.svg";
import IronConder from "../images/Screenshot 2023-05-15 at 1.34.png";

const StrategiesMobile = ({ isOpen, onClose, parentCallback }) => {
  const [isBuy, setIsBuy] = useState(true);
  const [isCALL, setIsCALL] = useState(true);
  const {
    basketData,
    removeFromBasket,
    buySellStatus,
    toggleBuySellStatus,
    handleIncrement,
    handleDecrement,
    clearBasket,
  } = useBasketContext();
  const { maxProfit, maxLoss, riskReward, breakevenRange, premium } =
    useGraphContext();
  const [activeOption, setActiveOption] = useState("intraday");
  const [activeButton, setActiveButton] = useState("bullish");

  const handleButtonClick = (button) => {
    setActiveButton(button);
    const mainGraph = document.querySelector(".main-graph");
    const tableContainer = document.querySelector(".table-container");

    // if (button === "p&ltable") {
    //   mainGraph.style.display = "none";
    //   tableContainer.style.display = "block";
    // } else {
    //   mainGraph.style.display = "block";
    //   tableContainer.style.display = "none";
    // }
  };

  function applyPreBuiltStrategy(strategyName) {
    parentCallback(strategyName);
    onClose();
  }

  return (
    <>
      <div className="basket-mobile-overlay"></div>
      <div className={`screener-mobile-modal ${isOpen ? "show" : ""}`} style={{"height": "auto"}}>
          {/* <div className="basket-mobile-overlay"></div> */}
          <div className="risk-factor p-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2">
              <button
                className={`filter-btns ${
                  activeButton === "bullish" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("bullish")}>
                Bullish
              </button>
              <button
                className={`filter-btns ${
                  activeButton === "bearish" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("bearish")}>
                Bearish
              </button>
              <button
                className={`filter-btns ${
                  activeButton === "neutral" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("neutral")}>
                Neutral
              </button>
              {/* <button
                className={`filter-btns ${
                  activeButton === "screeners" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("screeners")}>
                Screeners
              </button> */}
            </div>
            <button
            type="button"
            className="btn-close mb-2"
            onClick={onClose}></button>
            {/* <div className="ms-5 ps-3">
              <input type="checkbox" name="" id="" /> Within funds{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none">
                <path
                  d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79166 6.12492 3.79166M6.99992 9.91666V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583328 6.99992 0.583328C10.5437 0.583328 13.4166 3.45617 13.4166 7Z"
                  stroke="#555555"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}
          </div>
          {/* Box Containers */}
          {activeButton === "bullish" && (
            <div className="box-container bullish-box-container">
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("buy_call")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Buy Call</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Low risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>Buy 1 lot of ATM CE</span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("sell_put")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Sell Put</div>
                    <div className="d-flex gap-1">
                      <div className="high-risk text-center">High risk</div>
                      <div className="text-center unhedged">Unhedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>Sell 1 lot of ATM PE</span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("bull_call_spread")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Bull Call Spread</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Medium risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 lot of OTM 5 CE and Buy 1 lot of OTM 2 CE to hedge
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("bull_put_spread")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Bull Put Spread</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Medium risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 lot of ATM PE and Buy 1 lot of OTM 5 PE to hedge
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("call_ratio_back_spread")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Call Ratio Back Spread</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Medium risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 lot of ITM 2 CE and Buy 2 lots of OTM 3 CE
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeButton === "bearish" && (
            <div className="box-container bearish-box-container">
              {/* Content for Bearish */}
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("buy_put")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Buy Put</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Low risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>Buy 1 lot of ATM PE</span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("sell_call")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Sell Call</div>
                    <div className="d-flex gap-1">
                      <div className="high-risk text-center">High risk</div>
                      <div className="text-center unhedged">Unhedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>Sell 1 lot of ATM CE</span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("bear_put_spread")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Bear Put Spread</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Medium risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 lot of OTM 5 PE and Buy 1 lot of OTM 2 PE to hedge
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("bear_call_spread")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Bear Call Spread</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Medium risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 lot of ATM CE and Buy 1 lot of OTM 5 CE to hedge
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("put_ratio_back_spread")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Put Ratio Back Spread</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Medium risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 lot of ITM 2 PE and Buy 2 lots of OTM 2 PE
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeButton === "neutral" && (
            <div className="box-container neutral-box-container ">
              {/* Content for Neutral */}
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("short_straddle")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Short Straddle</div>
                    <div className="d-flex gap-1">
                      <div className="high-risk text-center">High risk</div>
                      <div className="text-center unhedged">Unhedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>Sell 1 lot of ATM CE and Sell 1 lot of ATM PE</span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("short_strangle")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Short Strangle</div>
                    <div className="d-flex gap-1">
                      <div className="high-risk text-center">High risk</div>
                      <div className="text-center unhedged">Unhedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 Lot of OTM 8 PE and Sell 1 lot of OTM 8 CE
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("iron_butterfly")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Iron Butterfly</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Medium risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 lot of ATM CE and ATM PE Buy 1 lot of OTM CE and
                      OTM PE to hedge
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="box d-flex gap-3 mt-3 p-2 cursor-pointer"
                onClick={() => applyPreBuiltStrategy("iron_condor")}>
                {/* <img src={IronConder} alt="" srcSet="" /> */}
                <div className="d-flex flex-column justify-content-between w-100">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Iron Condor</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Medium risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex justify-content-between">
                    <span>
                      Sell 1 lot of OTM CE and OTM PE Buy 1 lot of farther OTM
                      CE and OTM PE to hedge
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeButton === "screeners" && (
            <div className="box-container screeners-box-container">
              {/* Content for Screeners */}
              <div className="box d-flex justify-content-between gap-3 mt-3 p-2 bg-dark-subtle">
                <img src={IronConder} alt="" srcSet="" />
                <div className="d-flex flex-column justify-content-between">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Iron condor</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Low risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex">
                    <span>
                      Sell 1 lot of OTM CE and OTM PE Buy 1 lot of farther OTM
                      CE and OTM PE to hedge
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="box d-flex justify-content-between gap-3 mt-3 p-2 bg-dark-subtle">
                <img src={IronConder} alt="" srcSet="" />
                <div className="d-flex flex-column justify-content-between">
                  <div className="d-flex justify-content-between">
                    <div className="iron-title">Iron butterfly</div>
                    <div className="d-flex gap-1">
                      <div className="low-risk text-center">Low risk</div>
                      <div className="text-center hedged">Hedged</div>
                    </div>
                  </div>
                  <div className="iron-text d-flex">
                    <span>
                      Sell 1 lot of ATM CE and ATM PE Buy 1 lot of OTM CE and
                      OTM PE to hedge
                    </span>
                    <span>
                      <svg
                        className="mt-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M10 5L16.9632 11.9632L10 18.9263"
                          stroke="#14142B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
    </>
  );
};

export default StrategiesMobile;
