import React, { useState } from "react";

const SettingsModal = ({
  isOpen,
  onClose,
  showCalls,
  setShowCalls,
  showPuts,
  setShowPuts,
  showDelta,
  setShowDelta,
  showTheta,
  setShowTheta,
  showGamma,
  setShowGamma,
  showVega,
  setShowVega,
  showOIChng,
  setShowOIChng,
  showOIBuilddup,
  setShowOIBuilddup,
}) => {
  const [showCallsTemp, setShowCallsTemp] = useState(showCalls);
  const [showPutsTemp, setShowPutsTemp] = useState(showPuts);
  const [showDeltaTemp, setShowDeltaTemp] = useState(showDelta);
  const [showThetaTemp, setShowThetaTemp] = useState(showTheta);
  const [showGammaTemp, setShowGammaTemp] = useState(showGamma);
  const [showVegaTemp, setShowVegaTemp] = useState(showVega);
  const [showOIChngTemp, setShowOIChngTemp] = useState(showOIChng);
  const [showOIBuilddupTemp, setShowOIBuilddupTemp] = useState(showOIBuilddup);

  const handleApplyClick = () => {
    setShowCalls(showCallsTemp);
    setShowPuts(showPutsTemp);
    setShowDelta(showDeltaTemp);
    setShowTheta(showThetaTemp);
    setShowGamma(showGammaTemp);
    setShowVega(showVegaTemp);
    setShowOIChng(showOIChngTemp);
    setShowOIBuilddup(showOIBuilddupTemp);
    //  to close the modal
    // const modalElement = document.getElementById("staticBackdropsettings");
    // const bootstrapModal = new window.bootstrap.Modal(modalElement);
    // bootstrapModal.hide();
    // const modal = document.getElementById("staticBackdropsettings");
    // modal.classList.remove("show");
    onClose();
  };
  const handleResetClick = () => {
    setShowCallsTemp(true);
    setShowPutsTemp(true);
    setShowDeltaTemp(false);
    setShowThetaTemp(false);
    setShowGammaTemp(false);
    setShowVegaTemp(false);
    setShowOIChngTemp(false);
    setShowOIBuilddupTemp(false);
  };

  return (
    <>
      {isOpen && (
        <>
          <div className="basket-mobile-overlay"></div>
          <div
            className={`basket-mobile ${isOpen ? "show" : ""}`}
            style={{ height: "auto" }}>
            <div className="settings-modal d-flex flex-column gap-3 px-3">
              <div className="d-flex flex-column gap-2 mt-3">
                <div className="modal-header">
                  <p className="modal-title" id="staticBackdropLabel">
                    Settings
                  </p>
                  <button
                    type="button"
                    className="btn-close ms-auto"
                    onClick={() => {
                      onClose();
                    }}></button>
                </div>

                <div className="modal-body py-0">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-3">
                      <div className="modal-tag-titles">Show/hide Columns</div>
                      <div className="d-flex">
                        <ul className="list-group list-unstyled me-3">
                          <li className="mb-2">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={showDeltaTemp}
                              onChange={() =>
                                setShowDeltaTemp((prevValue) => !prevValue)
                              }
                            />
                            Delta
                          </li>
                          <li className="mb-2">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={showGammaTemp}
                              onChange={() =>
                                setShowGammaTemp((prevValue) => !prevValue)
                              }
                            />
                            Gamma
                          </li>
                        </ul>
                        <ul className="list-group list-unstyled">
                          <li className="mb-2">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={showThetaTemp}
                              onChange={() =>
                                setShowThetaTemp((prevValue) => !prevValue)
                              }
                            />
                            Theta
                          </li>
                          <li className="mb-2">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              checked={showVegaTemp}
                              onChange={() =>
                                setShowVegaTemp((prevValue) => !prevValue)
                              }
                            />
                            Vega
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex align-items-center justify-content-between gap-4">
                        <div className="modal-tag-titles">Display</div>
                        <div className="d-flex gap-3 align-items-center">
                          <div className="">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={showCallsTemp}
                              onChange={() =>
                                setShowCallsTemp((prevValue) => !prevValue)
                              }
                            />
                            Call
                          </div>
                          <div className="">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              value=""
                              id="defaultCheck2"
                              checked={showPutsTemp}
                              onChange={() =>
                                setShowPutsTemp((prevValue) => !prevValue)
                              }
                            />
                            Put
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer" style={{ marginBottom: "12px" }}>
                  <button
                    type="button"
                    className="reset-btn"
                    onClick={handleResetClick}>
                    Reset
                  </button>

                  <button
                    type="button"
                    className="apply-btn"
                    style={{ marginLeft: "12px" }}
                    onClick={handleApplyClick}>
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SettingsModal;
