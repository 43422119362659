import React, {
    useEffect,
    useLayoutEffect,
    useState,
    useRef,
    useContext,
  } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";


const MetricsComputations = () => {

  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);

  const computeXandYAxis = (data) => {

    let x_axis = null;
    let metrics = null;
    let index;
    
    if (data?.["ltp"]){
      let resp = formXaxis(data?.["symbol"], data?.["ltp"]);
      index = resp?.low;
      x_axis = resp?.newArray;
    }

    function processData() {
      return formYaxis(x_axis, data?.["selectedData"], data["lotsVal"], data?.["ltp"]).then((response) => {
        metrics = response;
        metrics["x_axis"] = x_axis;
        metrics["ltpIndex"] = index;
        return metrics;
      })
      .catch((error) => {
        return processData();
      });
    }

    if (x_axis && data?.["selectedData"].length > 0){
      return processData(x_axis, data)
      .then((metrics) => {
        return metrics;
      })
      .catch((error) => {
      });
    }
    
  }

  const formXaxis = (symbol, ltp) => {
    let step = parseFloat(symbol?.step);
    let valuesArray = [];

    if (!step){
      let x_axis = [];
      let start = 85; 
      let stop = 116; 
      let step = 0.01; 
      for (var i = start; i < stop; i += step) {
        let n = Math.round((ltp * i) / 100, 2);
        x_axis.push(Number(n));
      }
      valuesArray = x_axis;
    } else {
      const roundedValue = Math.round(ltp / step) * step;
      const lowerBound = roundedValue - roundedValue * 0.08;
      const upperBound = roundedValue + roundedValue * 0.08;
      for (let value = lowerBound; value <= upperBound; value += step) {
        let step_rounded = Math.floor(value / step) * step;
        valuesArray.push(step_rounded);
      }
    }    
    let newArray = [...valuesArray];
    let low = 0;
    let high = valuesArray.length - 1;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      if (valuesArray[mid] === ltp) {
        // If the element already exists, you can handle it as needed
        return;
      } else if (valuesArray[mid] < ltp) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    newArray.splice(low, 0, parseFloat(ltp));

    return {newArray, low};

  };

  const formYaxis = (x_axis, selectedData, lotsVal, ltp) => {
    let checkedarrays = [];
    let g = [];
    let calculatedMaxProfit;
    let calculatedMaxLoss;
    let calculatedRiskReward;

    selectedData.map((obj, i) => {
      checkedarrays.push(obj);
    });
    let h = [];

    let premium = 0;
    lotsVal = parseInt(lotsVal);
    
    checkedarrays.map((obj) => {
      let strike = Number(obj.strike);
      let option_price = obj.price
        ? obj.price
        : Number(symbolData[obj.code]?.ltp);
      
      if (!option_price){
        option_price = obj?.average_prc;
      }
      let option_type = obj.isCALL ? "CE" : "PE";
      let y = [];
      if (!lotsVal){
        lotsVal = parseInt(obj?.lotSize) / parseInt(obj?.net_quantity);
      }
      let option_lot = lotsVal * Number(obj.lotSize);

      let price = obj.isBuy
        ? -(option_price * option_lot)
        : option_price * option_lot;
      premium = premium + price;

      if (option_type == "CE") {
        for (let i = 0; i < x_axis.length; i++) {
          let num1 = x_axis[i] - strike - option_price;
          let num2 = 0 - option_price;
          let m = Math.max(Number(num1), Number(num2));
          y.push(m * option_lot);
        }
      } else {
        for (let i = 0; i < x_axis.length; i++) {
          let num1 = strike - x_axis[i] - option_price;
          let num2 = 0 - option_price;
          let m = Math.max(Number(num1), Number(num2));
          y.push(m * option_lot);
        }
      }
      if (obj.isBuy == 0) {
        // if (obj.buy_sell == 'SELL') {
        for (let i = 0; i < y.length; i++) {
          y[i] = -y[i];
        }
      }
      if (h.length == 0) {
        for (let i = 0; i < y.length; i++) {
          // g.push(Math.round(Number(y[i]), 2));
          g[i] = Math.round(y[i] * 100) / 100;
        }
      } else {
        for (let i = 0; i < y.length; i++) {
          let num = g[i] + Number(y[i]);
          g[i] = Math.round(num * 100) / 100;
        }
      }
      h = g;
      // Calculate maxProfit and maxLoss
      const maxProfit = Math.max(...h);
      const maxLoss = Math.min(...h);

      const maxProfitIndex = h.indexOf(maxProfit);
      if (
        maxProfitIndex === h.length - 1 &&
        h[maxProfitIndex] > h[maxProfitIndex - 1]
      ) {
        calculatedMaxProfit = "Unlimited";
      } else if (
        maxProfitIndex === 0 &&
        h[maxProfitIndex] > h[maxProfitIndex + 1]
      ) {
        calculatedMaxProfit = "Unlimited";
      } else {
        calculatedMaxProfit = maxProfit;
      }
      const maxLossIndex = h.indexOf(maxLoss);
      if (
        maxLossIndex === h.length - 1 &&
        h[maxLossIndex] < h[maxLossIndex - 1]
      ) {
        calculatedMaxLoss = "Unlimited";
      } else if (maxLossIndex === 0 && h[maxLossIndex] < h[maxLossIndex + 1]) {
        calculatedMaxLoss = "Unlimited";
      } else {
        calculatedMaxLoss = maxLoss;
      }

      if (
        calculatedMaxProfit === "Unlimited" ||
        calculatedMaxLoss === "Unlimited"
      ) {
        calculatedRiskReward = "N/A";
      } else {
        calculatedRiskReward = Math.abs(
          parseFloat(calculatedMaxProfit / calculatedMaxLoss).toFixed(2)
        );
        if (Math.abs(calculatedMaxProfit) > Math.abs(calculatedMaxLoss)) {
          calculatedRiskReward = calculatedRiskReward + ":1";
        } else {
          calculatedRiskReward = "1:" + calculatedRiskReward;
        }
      }
    });

    let breakevenRange = "";
    let strategyDirection = "";
    const indices = [];
    for (let i = 1; i < h.length; i++) {
      if ((h[i - 1] < 0 && h[i] >= 0) || (h[i - 1] >= 0 && h[i] < 0)) {
        indices.push(x_axis[i]);
      }
    }
    if (indices.length == 1) {
      if (h[h.length - 1] >= 0) {
        let percent_diff = parseFloat(((indices[0] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange = indices[0] + "(" + percent_diff + "%) >";
        strategyDirection = "Bullish strategy";
      } else {
        let percent_diff = parseFloat(((indices[0] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange = "< " + indices[0] + "(" + percent_diff + "%)";
        strategyDirection = "Bearish strategy";
      }
    }
    if (indices.length == 2) {
      if (h[h.length - 1] >= 0) {
        let percent_diff = parseFloat(((indices[1] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange = indices[1] + "(" + percent_diff + "%) >";
        strategyDirection = "Neutral strategy";
      }
      if (h[0] > 0) {
        let percent_diff = parseFloat(((indices[0] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange =
          "< " +
          indices[0] +
          "(" +
          percent_diff +
          "%)" +
          " & " +
          breakevenRange;
        strategyDirection = "Neutral strategy";
      }
      if (
        h[x_axis.indexOf(indices[0])] > 0 &&
        h[x_axis.indexOf(indices[0]) - 1] <= 0 &&
        h[x_axis.indexOf(indices[1]) - 1] >= 0 &&
        h[x_axis.indexOf(indices[1]) + 1] < 0
      ) {
        let percent_diff = parseFloat(((indices[0] - ltp) / ltp) * 100).toFixed(
          2
        );
        breakevenRange = indices[0] + "(" + percent_diff + "%)";
        percent_diff = parseFloat(((indices[1] - ltp) / ltp) * 100).toFixed(2);
        breakevenRange =
          breakevenRange +
          " < Spot < " +
          indices[1] +
          "(" +
          percent_diff +
          "%)";
        strategyDirection = "Neutral strategy";
      }
    }


    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (isNaN(h[0])) {
          reject(new Error('Data is null'));
        }
        resolve ({
          maxProfit: calculatedMaxProfit,
          maxLoss: calculatedMaxLoss,
          riskReward: calculatedRiskReward,
          breakevenRange: breakevenRange,
          strategyDirection: strategyDirection,
          premium: premium?.toFixed(2),
          y_axis: h
        });
      }, 200);
    });    
  };
  
  return {
    computeXandYAxis
  }

}

export default MetricsComputations;