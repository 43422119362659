import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useOptionChainContext } from "../context/OptionChainContext";
import { getSymbols } from "../utils/api";
import ExpiryDates from "../components/ExpiryDates";
import { ExpiryDatesProvider } from "../context/ExpiryDatesContext";
import axios from "axios";
import OptionChainTable from "../components/OptionChainTable";
import { useLtpContext } from "../context/LtpContext";
import ABWebSocketContext from "../context/ABWebsocketContext";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";

const OptionChainModal = ({
  parentCallback,
  parentCallback2,
  initialOptionData,
  selectedSymbol,
}) => {
  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);

  // const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeDate, setActiveDate] = useState("");
  const { optionData, setOptionData } = useOptionChainContext();
  const [tempOptionData, setTempOptionData] = useState({});
  const { reducedData, setReducedData } = useState();
  const [allTradingSymbols, setAllTradingSymbols] = useState({});
  const [calls, setCalls] = useState();
  const [puts, setPuts] = useState();
  const [expiryDays, setExpiryDays] = useState(0);

  var iv = require("implied-volatility");
  var greeks = require("greeks");

  useLayoutEffect(() => {
    const storedSymbol = sessionStorage.getItem("selectedSymbol");
    if (storedSymbol) {
      // setSelectedSymbol(JSON.parse(storedSymbol));
    } else {
      const fetchSymbols = async () => {
        const symbols = await getSymbols();
        if (symbols) {
          const niftySymbol = symbols.find(
            (symbol) => symbol.scrip === "NIFTY"
          );
          // setSelectedSymbol(niftySymbol);
          sessionStorage.setItem("selectedSymbol", JSON.stringify(niftySymbol));
        }
      };

      fetchSymbols();
    }
  }, []);

  const handleSymbolSelection = async (symbol, date) => {
    const formattedDate = date.replaceAll(" ", "%20");
    setSelectedDate(formattedDate);
    setActiveDate(date);
    await fetchDataForSymbol(symbol.scrip, formattedDate);
    const targetDateTime = new Date(date);
    const currentDate = new Date();
    let timeToExpiration = (
      (targetDateTime - currentDate) /
      (24 * 60 * 60 * 1000)
    ).toFixed(0);
    setExpiryDays(parseInt(timeToExpiration) + 1);
  };

  useLayoutEffect(() => {
    if (
      Object.keys(tempOptionData).length > 0 &&
      symbolData[selectedSymbol?.code]
    ) {
      let underlying_ltp = symbolData[selectedSymbol?.code]?.ltp;
      let data = tempOptionData;
      const formattedData = data["options"].map((item) => ({
        ...item,
        strike: parseInt(item.strike),
      }));

      let minDiff = Math.abs(
        formattedData[0].strike - parseFloat(underlying_ltp)
      );
      let closestIdx = 0;

      // Iterate through the array to find the closest index
      formattedData.forEach((item, index) => {
        const diff = Math.abs(item.strike - parseFloat(underlying_ltp));
        if (diff < minDiff) {
          minDiff = diff;
          closestIdx = index;
        }
      });
      const selected = formattedData.slice(
        Math.max(0, closestIdx - 10),
        Math.min(formattedData.length, closestIdx + 10)
      );
      data["options"] = selected;
      setOptionData(data);

      parentCallback2(data);

      const call = getCodeTradingSymbolMappings(selected, "call");
      const put = getCodeTradingSymbolMappings(selected, "put");

      const tradingSymbols = call.concat(put);
      setAllTradingSymbols(tradingSymbols);
      setTempOptionData({});
      subscribeToSymbols(tradingSymbols, "NFO");
    }
  }, [tempOptionData, symbolData]);

  const getCodeTradingSymbolMappings = (data, key) => {
    const codeTradingSymbol = [];
    for (const item of data) {
      const { code } = item[key];
      codeTradingSymbol.push(code);
    }
    return codeTradingSymbol;
  };

  const fetchDataForSymbol = async (symbol, date) => {
    let brokerName = localStorage.getItem("broker");

    if (!brokerName) {
      console.error("Broker name not found in local storage.");
      return;
    }

    brokerName = brokerName.toLowerCase().replace(/\s+/g, "");
    const apiEndpoint = `https://y18wfq6w5b.execute-api.ap-south-1.amazonaws.com/default/og_expirysymbols`;
    try {
      if (Object.keys(tempOptionData).length === 0) {
        const response = await axios.post(apiEndpoint, {
          id: symbol + date,
          broker: brokerName,
        });
        const data = response.data;
        setTempOptionData(data);

        if (Object.keys(allTradingSymbols).length > 0) {
          unsubscribeToSymbols(allTradingSymbols, "NFO");
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useLayoutEffect(() => {
    const fetchDefaultData = () => {
      if (
        selectedSymbol &&
        selectedSymbol.option_expiries &&
        selectedSymbol.option_expiries.length > 0
      ) {
        const defaultDate = selectedSymbol.option_expiries[0]; // Selecting the first date
        const formattedDate = defaultDate.replaceAll(" ", "%20");
        setActiveDate(defaultDate);
        const targetDateTime = new Date(defaultDate);
        const currentDate = new Date();
        let timeToExpiration = (
          (targetDateTime - currentDate) /
          (24 * 60 * 60 * 1000)
        ).toFixed(0);
        setExpiryDays(parseInt(timeToExpiration) + 1);
        if (optionData) {
          if (selectedSymbol.scrip + defaultDate !== optionData["id"]) {
            fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
          }
        } else {
          fetchDataForSymbol(selectedSymbol.scrip, formattedDate);
        }
      }
    };
    fetchDefaultData();
  }, [selectedSymbol]);

  useLayoutEffect(() => {
    computeGreeks();
  }, [symbolData, optionData]);

  const computeGreeks = () => {
    //using parsefloat only once so we dont need to use multiple times
    const ltp = parseFloat(symbolData[selectedSymbol?.code]?.ltp);
    const riskFreeRate = 0.1 / 365; //moved this out of the loop

    // using forrach instead of map
    optionData?.options.forEach((data) => {
      const call_option = data.call;
      const put_option = data.put;
      const strike = data.strike;

      const isCallOption = strike > ltp;
      const optionType = isCallOption ? "call" : "put";
      const relevantOption = isCallOption ? call_option : put_option;

      const targetDateTimeString = put_option.expiry_date + " 15:29:59"; // Target date and time
      const targetDateTime = new Date(targetDateTimeString);
      const currentDate = new Date();
      let timeToExpiration = Math.max(
        0,
        (targetDateTime - currentDate) / (24 * 60 * 60 * 365 * 1000) ||
          0.0002 / 365
      );

      const currentStockPrice = parseFloat(
        symbolData[relevantOption?.code]?.ltp
      );

      if (!call_option.iv) {
        //instead of repeting same calculations for calls and puts, combining them here
        const initialGuessIv = iv.getImpliedVolatility(
          currentStockPrice,
          ltp,
          strike,
          timeToExpiration,
          riskFreeRate,
          optionType
        );
        if (initialGuessIv <= 1) {
          const ivValue = (initialGuessIv * 100).toFixed(2);
          const deltaValue = (
            greeks.getDelta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const thetaValue = (
            greeks.getTheta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const gammaValue = (
            greeks.getGamma(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          const vegaValue = (
            greeks.getVega(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "call"
            ) * 100
          ).toFixed(2);
          data.call.iv = ivValue;
          data.call.delta = deltaValue;
          data.call.theta = thetaValue;
          data.call.gamma = gammaValue;
          data.call.vega = vegaValue;
        }
      }
      if (!put_option.iv) {
        const initialGuessIv = iv.getImpliedVolatility(
          currentStockPrice,
          ltp,
          strike,
          timeToExpiration,
          riskFreeRate,
          optionType
        );
        if (initialGuessIv <= 1) {
          const ivValue = (initialGuessIv * 100).toFixed(2);
          const deltaValue = (
            greeks.getDelta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const thetaValue = (
            greeks.getTheta(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const gammaValue = (
            greeks.getGamma(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          const vegaValue = (
            greeks.getVega(
              ltp,
              strike,
              timeToExpiration,
              initialGuessIv,
              riskFreeRate,
              "put"
            ) * 100
          ).toFixed(2);
          data.put.iv = ivValue;
          data.put.delta = deltaValue;
          data.put.theta = thetaValue;
          data.put.gamma = gammaValue;
          data.put.vega = vegaValue;
        }
      }
    });
  };

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedSide, setSelectedSide] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState({ top: 0, left: 0 });
  const getReducedData = () => {
    const sample = optionData && optionData.options && optionData.options;
    if (sample) {
      let f = sample.reduce((groupedOptions, option) => {
        const strike = option.call.strike;
        if (!groupedOptions[strike]) {
          groupedOptions[strike] = {
            call: option.call,
            put: option.put,
          };
        } else {
          groupedOptions[strike].call = {
            ...groupedOptions[strike].call,
            ...option.call,
          };
          groupedOptions[strike].put = {
            ...groupedOptions[strike].put,
            ...option.put,
          };
        }
        return groupedOptions;
      }, []);
      return f;
    }
  };
  const handleRowClick = (event, rowIndex, side) => {
    const rowRect = event.currentTarget.getBoundingClientRect();
    const top = rowRect.top + window.scrollY;
    const left = rowRect.left + window.scrollX;
    setSelectedSide(side);
    setSelectedRowIndex(rowIndex);

    // Setting the selected row index and side in state variables
    setSelectedRowIndex(rowIndex);
    setSelectedSide(side);

    // Setting the position of the clicked row
    setSelectedPosition({ top, left });
  };

  const containerRef = useRef(null);

  // Refs to store the latest
  const selectedRowIndexRef = useRef(null);
  const selectedSideRef = useRef(null);
  const optionDataRef = useRef(null);

  const handleButtonClick = (event) => {
    const target = event.target;
    const isBuyButton = target.classList.contains("buy-btn");
    const isSellButton = target.classList.contains("sell-btn");
    if (isBuyButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleClick("Buy");
    } else if (isSellButton) {
      setSelectedRowIndex(selectedRowIndexRef.current);
      setSelectedSide(selectedSideRef.current);
      handleClick("Sell");
    }
  };

  //This block of container is used because in a way we have two onclick elements at a single place
  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("click", handleButtonClick);
    return () => {
      container.removeEventListener("click", handleButtonClick);
    };
  }, []);

  useEffect(() => {
    selectedRowIndexRef.current = selectedRowIndex;
    selectedSideRef.current = selectedSide;
    optionDataRef.current = optionData;
  }, [selectedRowIndex, selectedSide, optionData]);

  const handleClick = (buy_sell_type) => {
    const f = getReducedData();
    if (
      selectedSymbol &&
      optionData &&
      optionData.options &&
      selectedRowIndex !== null &&
      selectedSide !== null
    ) {
      const selectedOption = f[selectedRowIndex];
      const side = selectedSide === "calls" ? "Call" : "Put";
      debugger
      const instrumentName =
        side === "Call"
          ? selectedOption.call.instrument_name
          : selectedOption.put.instrument_name;
      const lotSize =
        side === "Call"
          ? selectedOption.call.lot_size
          : selectedOption.put.lot_size;
      const quantity = lots;
      const optionType = side === "Call" ? "CE" : "PE";
      const tradingSymbol =
        side === "Call"
          ? selectedOption.call.trading_symbol
          : selectedOption.put.trading_symbol;
      const expiryDate =
        side === "Call"
          ? selectedOption.call.expiry_date
          : selectedOption.put.expiry_date;
      const code =
        side === "Call" ? selectedOption.call.code : selectedOption.put.code;
      const strike =
        side === "Call"
          ? selectedOption.call.strike
          : selectedOption.put.strike;
      const buy_sell = buy_sell_type;
      let freeze_qty =
        side === "Call"
          ? selectedOption.call.freeze_qty
          : selectedOption.put.freeze_qty;
      let exchange =
        side === "Call"
          ? selectedOption.call.exchange
          : selectedOption.put.exchange;
      let lot_multiplier = parseFloat(optionData?.lot_size);
      const dataObject = {
        side,
        instrumentName,
        lotSize,
        quantity,
        optionType,
        tradingSymbol,
        expiryDate,
        code,
        buy_sell,
        strike,
        freeze_qty: freeze_qty,
        exchange: exchange,
        lot_multiplier: lot_multiplier,
        scrip: selectedSymbol.scrip,
      };
      parentCallback([[dataObject]], "add");
    }
  };

  const getRowColor = (strike, isCall) => {
    const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
    if ((isCall && strike < ltpValue) || (!isCall && strike > ltpValue)) {
      return isCall ? "#FFF9E5" : "#F4F5FF"; // Light yellow for ITM call options, Light blue for ITM put options
    } else {
      return "white"; // White for OTM and ATM options
    }
  };

  const getPutRowColor = (strike) => {
    const ltpValue = symbolData[selectedSymbol?.code]?.ltp;
    if (strike > ltpValue) {
      return "#F4F5FF";
    } else {
      return "white";
    }
  };

  // Formatting volume
  function formatVolume(volume) {
    if (volume >= 10000000) {
      return `${(volume / 10000000)?.toFixed(2)}Cr`;
    } else if (volume >= 100000) {
      return `${(volume / 100000)?.toFixed(2)}L`;
    } else if (volume >= 1000) {
      return `${(volume / 1000)?.toFixed(2)}K`;
    } else if (volume) {
      return volume % 1 === 0 ? volume?.toFixed(0) : volume?.toFixed(2);
    }
  }

  const [lots, setLots] = useState(1);

  return (
    <ExpiryDatesProvider>
      <div
        ref={containerRef}
        className="modal fade option-chain-modal"
        id="staticBackdropOptionChainModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-xl option-chain-modal-dialog">
          <div className="modal-content option-chain-modal-content">
            <div className="modal-body d-flex flex-column">
              <div className="">
                <div className="symbol-info d-flex flex-column">
                  <div className="symbol-name d-flex justify-content-between">
                    <div>{selectedSymbol?.scrip}</div>
                    <button
                      type="button"
                      className="btn-close mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"></button>
                  </div>
                  <div>
                    <span className="lp">
                      {symbolData[selectedSymbol?.code]?.ltp}
                    </span>
                    <span
                      className="ch-chp me-2"
                      style={{
                        color:
                          symbolData[selectedSymbol?.code]?.change > 0
                            ? "#5CA81D" // Green color for positive change
                            : "#DD6565", // Red color for negative change
                      }}>
                      {symbolData[selectedSymbol?.code]?.change > 0 ? "+" : ""}
                      {symbolData[selectedSymbol?.code]?.change}(
                      {symbolData[selectedSymbol?.code]?.change_per}%)
                    </span>
                  </div>
                </div>
                <ExpiryDates
                  selectedSymbol={selectedSymbol}
                  handleSymbolSelection={handleSymbolSelection}
                  expiryDays={expiryDays}
                  activeDate={activeDate}
                />
              </div>
                <div
                  className="option-chain-table mt-3"
                  style={{ fontSize: "14px" }}>
                      <div className="calls-puts-header d-flex text-center border-bottom py-1 text-center">
                          <div
                            className="calls text-center fw-bold"
                            style={{ width: "50%" }}>
                            {"Calls"}
                          </div>
                          <div
                            className="puts text-center fw-bold"
                            style={{ width: "50%" }}>
                            { "Puts"}
                          </div>
                      </div>

                      <div className="table-wrapper table-wrapper-sc-modal-desktop">
                    <table className="table">
                      <thead>
                       
                        <tr className="sticky-header">
                          <>
                            <th
                              scope="col"
                              className="table-header-columns text-center">
                              OI
                            </th>
                            <th
                              scope="col"
                              className="table-header-columns text-center">
                              Volume
                            </th>
                            <th
                              scope="col"
                              className="table-header-columns text-center">
                              LTP Chng
                            </th>
                            <th
                              scope="col"
                              className="table-header-columns text-center">
                              LTP
                            </th>
                          </>
                          <th
                            scope="col"
                            className="table-header-columns text-center">
                            Strike
                          </th>
                          <th
                            scope="col"
                            className="table-header-columns text-center">
                            IV
                          </th>
                          <>
                            {/* Put header columns */}
                            <th
                              scope="col"
                              className="table-header-columns text-center">
                              LTP
                            </th>
                            <th
                              scope="col"
                              className="table-header-columns text-center">
                              LTP Chng
                            </th>
                            <th
                              scope="col"
                              className="table-header-columns text-center">
                              Volume
                            </th>
                            <th
                              scope="col"
                              className="table-header-columns text-center">
                              OI
                            </th>
                          </>
                        </tr>
                      </thead>
                      <tbody className="" style={{ overflowY: "hidden" }}>
                        {optionData &&
                          optionData.options &&
                          optionData.options
                            .reduce((groupedOptions, option) => {
                              const strike = option.call.strike;
                              if (!groupedOptions[strike]) {
                                groupedOptions[strike] = {
                                  call: option.call,
                                  put: option.put,
                                };
                              } else {
                                groupedOptions[strike].call = {
                                  ...groupedOptions[strike].call,
                                  ...option.call,
                                  selectedSide: false,
                                  selectedRowIndex: false,
                                };
                                groupedOptions[strike].put = {
                                  ...groupedOptions[strike].put,
                                  ...option.put,
                                  selectedSide: false,
                                  selectedRowIndex: false,
                                };
                              }
                              return groupedOptions;
                            }, [])
                            .map((optionGroup, index) => (
                              <tr
                                className="text-center"
                                key={optionGroup.call.instrument_name}>
                                <td
                                  style={{
                                    backgroundColor: getRowColor(
                                      optionGroup.call.strike,
                                      true
                                    ),
                                  }}
                                  data-side="calls"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "calls")
                                  }>
                                  {formatVolume(
                                    symbolData[optionGroup.call.code]?.oi /
                                      symbolData[optionGroup.call.code]
                                        ?.lot_size
                                  )}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getRowColor(
                                      optionGroup.call.strike,
                                      true
                                    ), position:"relative"
                                  }}
                                  data-side="calls"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "calls")
                                  }>
                                  {formatVolume(
                                    symbolData[optionGroup.call.code]?.volume /
                                      symbolData[optionGroup.call.code]
                                        ?.lot_size
                                  )}
                                  {selectedSide !== null &&
                                    selectedRowIndex !== null &&
                                    selectedSide == "calls" &&
                                    selectedRowIndex == index && (
                                      <div
                                        className="option-chain-row-buttons"
                                        style={{
                                          position: "absolute",
                                          // top: selectedPosition.top + "px",
                                          // left: selectedPosition.left + "px",
                                          zIndex: 9999, // To make sure it appears on top of the table
                                          display: "inline-table",
                                        }}>
                                        <span className="d-flex justify-content-center align-items-center gap-2">
                                          <input
                                            className="hover-lots-value px-1 text-end"
                                            value={lots}
                                            onChange={(event) =>
                                              setLots(
                                                parseInt(event.target.value)
                                              )
                                            }
                                          />

                                          <button
                                            className="buy-btn"
                                            onClick={() => handleClick("Buy")}>
                                            Buy
                                          </button>
                                          <button
                                            className="sell-btn"
                                            onClick={() => handleClick("Sell")}>
                                            Sell
                                          </button>
                                        </span>
                                      </div>
                                    )}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getRowColor(
                                      optionGroup.call.strike,
                                      true
                                    ),
                                  }}
                                  data-side="calls"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "calls")
                                  }>
                                  {/* {optionGroup.call.ltp_change} */}
                                  {symbolData[optionGroup.call.code] && (
                                    <span>
                                      {symbolData[optionGroup.call.code].change}
                                      <span
                                        style={{
                                          color:
                                            symbolData[optionGroup.call.code]
                                              .change > 0
                                              ? "#5CA81D"
                                              : "#DD6565",
                                        }}>
                                        (
                                        {
                                          symbolData[optionGroup.call.code]
                                            .change_per
                                        }
                                        %)
                                      </span>
                                    </span>
                                  )}
                                </td>

                                <td
                                  style={{
                                    backgroundColor: getRowColor(
                                      optionGroup.call.strike,
                                      true
                                    ),
                                  }}
                                  data-side="calls"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "calls")
                                  }>
                                  {symbolData[optionGroup.call.code]?.ltp}
                                </td>
                                {/* //striek and iv */}
                                <td className="strike">
                                  {optionGroup.call.strike}
                                </td>
                                <td className="strike">
                                  {" "}
                                  {optionGroup.call.iv}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      optionGroup.put.strike
                                    ),position:"relative"
                                  }}
                                  data-side="puts"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "puts")
                                  }>
                                  {symbolData[optionGroup.put.code]?.ltp}
                                  {selectedSide !== null &&
                                    selectedRowIndex !== null &&
                                    selectedSide == "puts" &&
                                    selectedRowIndex == index && (
                                      <div
                                        // className="option-chain-row-buttons"
                                        style={{
                                          position: "absolute",
                                          // top: selectedPosition.top + "px",
                                          // left: selectedPosition.left + "px",
                                          zIndex: 9999, // To make sure it appears on top of the table
                                          display: "inline-table",
                                        }}>
                                        <span className="d-flex justify-content-center align-items-center gap-2">
                                          <input
                                            className="hover-lots-value px-1 text-end"
                                            value={lots}
                                            onChange={(event) =>
                                              setLots(
                                                parseInt(event.target.value)
                                              )
                                            }
                                          />

                                          <button
                                            className="buy-btn"
                                            onClick={() => handleClick("Buy")}>
                                            Buy
                                          </button>
                                          <button
                                            className="sell-btn"
                                            onClick={() => handleClick("Sell")}>
                                            Sell
                                          </button>
                                        </span>
                                      </div>
                                    )}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      optionGroup.put.strike
                                    ),
                                  }}
                                  data-side="puts"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "puts")
                                  }>
                                  {/* {optionGroup.put.ltp_change} */}
                                  {symbolData[optionGroup.put.code] && (
                                    <span>
                                      {symbolData[optionGroup.put.code].change}
                                      <span
                                        style={{
                                          color:
                                            symbolData[optionGroup.put.code]
                                              .change > 0
                                              ? "#5CA81D"
                                              : "#DD6565",
                                        }}>
                                        (
                                        {
                                          symbolData[optionGroup.put.code]
                                            .change_per
                                        }
                                        %)
                                      </span>
                                    </span>
                                  )}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      optionGroup.put.strike
                                    ),
                                  }}
                                  data-side="puts"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "puts")
                                  }>
                                  {/* {optionGroup.put.volume} */}
                                  {formatVolume(
                                    symbolData[optionGroup.put.code]?.volume /
                                      symbolData[optionGroup.put.code]?.lot_size
                                  )}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getPutRowColor(
                                      optionGroup.put.strike
                                    ),
                                  }}
                                  data-side="puts"
                                  onClick={(event) =>
                                    handleRowClick(event, index, "puts")
                                  }>
                                  {/* {optionGroup.put.oi} */}
                                  {formatVolume(
                                    symbolData[optionGroup.put.code]?.oi /
                                      symbolData[optionGroup.put.code]?.lot_size
                                  )}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                      </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </ExpiryDatesProvider>
  );
};

export default OptionChainModal;
