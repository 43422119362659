import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ABWebSocketProvider } from "./context/ABWebsocketContext";

import { OptionChainProvider } from "./context/OptionChainContext";
import { LtpProvider } from "./context/LtpContext";
import { BasketProvider } from "./context/BasketContext";
import { GraphProvider } from "./context/GraphContext";
import { VisibilityProvider } from "./context/VisibilityContext";
import { ACAWebSocketProvider } from "./context/ACAWebsocketContext";
import { WebsocketWrapperProvider } from "./context/WebsocketWrapperContext";
import { FundsDataProvider } from "./context/FundsDataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
let broker = localStorage.getItem("broker");

if (broker == null) {
  const urlParams = new URLSearchParams(window.location.search);
  broker = urlParams.get("broker");
  localStorage.setItem("broker", broker);
}

root.render(
  <BrowserRouter>
    <HelmetProvider>
      {broker == "aliceblue" ? (
        <ABWebSocketProvider>
          <WebsocketWrapperProvider>
            <FundsDataProvider>
              <GraphProvider>
                <BasketProvider>
                  <LtpProvider>
                    <OptionChainProvider>
                      <VisibilityProvider>
                        <App />
                      </VisibilityProvider>
                    </OptionChainProvider>
                  </LtpProvider>
                </BasketProvider>
              </GraphProvider>
            </FundsDataProvider>
          </WebsocketWrapperProvider>
        </ABWebSocketProvider>
      ) : (
        ""
      )}
      {broker == "acagarwal" ? (
        <ABWebSocketProvider>
          <WebsocketWrapperProvider>
            <FundsDataProvider>
              <GraphProvider>
                <BasketProvider>
                  <LtpProvider>
                    <OptionChainProvider>
                      <VisibilityProvider>
                        <App />
                      </VisibilityProvider>
                    </OptionChainProvider>
                  </LtpProvider>
                </BasketProvider>
              </GraphProvider>
            </FundsDataProvider>
          </WebsocketWrapperProvider>
        </ABWebSocketProvider>
      ) : (
        ""
      )}
    </HelmetProvider>
  </BrowserRouter>
);

reportWebVitals();
