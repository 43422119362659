import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useContext,
} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import WebsocketWrapperContext from "../context/WebsocketWrapperContext";
import { blackScholes } from "black-scholes";

const StrategyGraph = ({
  data,
  selectedData,
  ltp,
  leastExpiry,
  allOptionData,
}) => {
  const {
    updateSymbolList,
    websocketConnection,
    checkWebsocketConnection,
    subscribeToSymbols,
    unsubscribeToSymbols,
    symbolList,
    symbolData,
  } = useContext(WebsocketWrapperContext);
  var iv = require("implied-volatility");

  const [calculatedIV, setCalculatedIV] = useState(0);
  const x_axis = data?.["x_axis"];
  const y_axis = data?.["y_axis"];

  let targetPnLLine = y_axis;

  function setAtmIndex() {
    let index = 0;
    try {
      if (allOptionData) {
        let optionData = allOptionData[Object.keys(allOptionData)[0]];
        if (optionData && optionData.options) {
          let curr = optionData.options[0].strike,
            diff = Math.abs(ltp - curr);
          for (let val = 0; val < optionData.options.length; val++) {
            let newdiff = Math.abs(ltp - optionData.options[val].strike);
            if (newdiff < diff) {
              diff = newdiff;
              curr = optionData.options[val];
              index = val;
            }
          }
          let atmIndex = optionData?.options[index]?.call;
          const targetDateTimeString = atmIndex.expiry_date + " 15:29:59"; // Target date and time
          const targetDateTime = new Date(targetDateTimeString);
          const currentDate = new Date();
          let timeToExpiration = Math.max(
            0,
            (targetDateTime - currentDate) / (24 * 60 * 60 * 365 * 1000) ||
              0.0002 / 365
          );
          let computedIv = (
            computeIV(
              symbolData?.[atmIndex.code]?.ltp,
              ltp,
              atmIndex?.strike,
              timeToExpiration,
              "call"
            ) * 100
          ).toFixed(2);
          setCalculatedIV(computedIv);
        }
      }
    } catch (error) {
      console.log(error);
      console.log("Waiting for IV");
    }
  }

  function computeIV(optionPrice, spot, strike, timeToExpiration, optionType) {
    const riskFreeRate = 0.1 / 365; //moved this out of the loop
    const initialGuessIv = iv.getImpliedVolatility(
      optionPrice,
      spot,
      strike,
      timeToExpiration,
      riskFreeRate,
      optionType
    );
    return initialGuessIv;
  }

  function computeTargetLine(
    spot,
    row,
    timeToExpiration,
    annualizedDaysCount,
    optionType
  ) {
    const riskFreeRate = 0.1 / 365;
    let targetPrices = [];
    if (symbolData?.[row.code]?.ltp) {
      let rowIv = computeIV(
        parseFloat(symbolData?.[row.code]?.ltp),
        parseFloat(spot),
        row?.strike,
        annualizedDaysCount + timeToExpiration,
        optionType
      ).toFixed(2);
      if (parseInt(rowIv) > 1) {
        rowIv = rowIv / 100;
      }
      for (let val = 0; val < x_axis?.length; val++) {
        const optionPrice = blackScholes(
          x_axis[val],
          row?.strike,
          annualizedDaysCount - timeToExpiration,
          rowIv,
          riskFreeRate,
          optionType
        );
        targetPrices.push(optionPrice);
      }
    }
    return targetPrices;
  }

  function updateTargetLine(g, daysCount) {
    let xaxis_length = x_axis?.length;
    let targetLine = Array.from({ length: xaxis_length }, () => 0);
    for (let val = 0; val < selectedData.length; val++) {
      let optionType = selectedData[val]?.isCALL ? "call" : "put";
      let timeToExpiration = Math.max(0, g / 365 || 0.0002 / 365);
      let annualizedDaysCount = Math.max(0, daysCount / 365 || 0.0002 / 365);
      let line = computeTargetLine(
        ltp,
        selectedData[val],
        annualizedDaysCount - timeToExpiration,
        annualizedDaysCount,
        optionType
      );
      let updatedLine;
      let optionPrice = parseFloat(symbolData?.[selectedData[val]?.code]?.ltp);
      let total_quantity =
        selectedData[val]?.lotSize * selectedData[val]?.lot_multiplier;
      if (selectedData[val]?.isBuy) {
        updatedLine = line.map((item) => (item - optionPrice) * total_quantity);
      } else {
        updatedLine = line.map((item) => (optionPrice - item) * total_quantity);
      }
      targetLine.map(
        (value, index) => (targetLine[index] += updatedLine[index])
      );
      let currentPriceIndex = x_axis?.indexOf(parseFloat(ltp));
      if (currentPriceIndex == -1) {
        currentPriceIndex = x_axis.length / 2;
      }
      selectedData[val]["targetPrice"] = parseFloat(
        line[currentPriceIndex]
      ).toFixed(2);
    }
    targetPnLLine = targetLine;
  }

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }

  const [activeButton, setActiveButton] = useState("payoffchart");
  const [lotsValue, setLotsValue] = useState(1);

  const chartComponent = useRef(null);

  const [linechart, setlineChart] = useState({
    xAxis: {
      tickInterval: 1000,
      categories: x_axis,
      title: false,
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          textOverflow: 'none'
        },
        formatter: function () {
          let chartWidth = this.chart.plotWidth;
          let labelCount = this.axis.tickPositions.length;
          let labelWidth = chartWidth / labelCount;
          let showLabels = labelCount;

          // Adjust the number of labels to show based on label width
          if (labelWidth < 150) {
            // Adjust the threshold as needed
            showLabels = Math.ceil(chartWidth / 150); // Show 1 label per 50 pixels
          }

          // Show only every nth label to fit the available space
          let index = this.axis.tickPositions.indexOf(this.pos);
          if (index % Math.ceil(labelCount / showLabels) !== 0) {
            return ""; // Return an empty string to skip label
          }

          return this.value;
        },
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        fillOpacity: 0.4,
      },
    },
    series: [
      {
        name: "",
        color: "#D8FDE2",
        negativeColor: "#FFD9D7",
        showInLegend: false,
      },
    ],
    chart: {
      type: "area",
      height: 346,
      style: {
        fontFamily: "Satoshi-Variable",
      },
    },
    title: {
      text: "",
    },
  });

  const calculatePlotBandWidth = (
    days_to_expire,
    ltp,
    calculatedIV,
    x_axis
  ) => {
    if (calculatedIV === undefined || calculatedIV === null) {
      return {
        lowerBand1: null,
      };
    }
    const width = parseFloat(
      ltp * (calculatedIV / 100) * Math.sqrt(days_to_expire / 365)
    );

    const lowerBand1 = parseFloat(ltp) - width;
    const upperBand1 = parseFloat(ltp) + width;
    const lowerBand2 = parseFloat(ltp) - width * 2;
    const upperBand2 = parseFloat(ltp) + width * 2;

    const lowerBand1Index = closestIndex(lowerBand1, x_axis);
    const upperBand1Index = closestIndex(upperBand1, x_axis);
    const lowerBand2Index = closestIndex(lowerBand2, x_axis);
    const upperBand2Index = closestIndex(upperBand2, x_axis);

    return {
      lowerBand1,
      upperBand1,
      lowerBand2,
      upperBand2,
      lowerBand1Index,
      upperBand1Index,
      lowerBand2Index,
      upperBand2Index,
    };
  };

  const closestIndex = (band, x_axis) => {
    const index = x_axis?.reduce(
      (prevIndex, currentValue, currentIndex, arr) => {
        return Math.abs(currentValue - band) < Math.abs(arr[prevIndex] - band)
          ? currentIndex
          : prevIndex;
      },
      0
    );
    return index;
  };

  const [chartOptions, setChartOptions] = useState(linechart);

  const updateChart = () => {
    const {
      lowerBand1,
      upperBand1,
      lowerBand2,
      upperBand2,
      lowerBand1Index,
      upperBand1Index,
      lowerBand2Index,
      upperBand2Index,
    } = calculatePlotBandWidth(lotsValue, ltp, calculatedIV, x_axis);

    if (!x_axis?.length || !y_axis?.length) {
      return; // Don't update chart with invalid data
    }

    if (((upperBand1 == upperBand2) == lowerBand1) == lowerBand2) {
      return;
    }

    setChartOptions((prevOptions) => ({
      ...prevOptions,

      series: [
        {
          type: "area",
          data: y_axis,
          name: "P&L",
          color: "#ACF1BF",
          opacity: 0.7,
          negativeColor: "#FFC2BE",
          lineWidth: 1,
          showInLegend: false,
          marker: {
            enabled: false,
          },
        },
        {
          type: "line",
          data: targetPnLLine,
          name: "Target P&L",
          color: "#383FEC",
          opacity: 0.7,
          negativeColor: "#383FEC",
          lineWidth: 1,
          showInLegend: false,
          marker: {
            enabled: false,
          },
        },
      ],
      yAxis: {
        gridLineColor: "#EFEFEF",
        tickInterval: 100,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          formatter: function () {
            let chartWidth = this.chart.plotHeight;
            let labelCount = this.axis.tickPositions.length;
            let labelWidth = chartWidth / labelCount;
            let showLabels = labelCount;
  
            // Adjust the number of labels to show based on label width
            if (labelWidth < 50) {
              // Adjust the threshold as needed
              showLabels = Math.ceil(chartWidth / 50); // Show 1 label per 50 pixels
            }
  
            // Show only every nth label to fit the available space
            let index = this.axis.tickPositions.indexOf(this.pos);
            if (index % Math.ceil(labelCount / showLabels) !== 0) {
              return ""; // Return an empty string to skip label
            }
  
            return this.value;
          },
          style: {
            color: "#000",
            fontSize: "12px",
            fontWeight: "700",
          },
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: "#efefef",
          },
        ],
        title: {
          enabled: false,
        },
      },
      xAxis: {
        alternateGridColor: "#fff",
        tickInterval: 2,
        allowDecimals: false,
        categories: x_axis.map((value) => value.toLocaleString("en-IN")),
        labels: {
          style: {
            color: "#000",
            fontSize: "12px",
            fontWeight: "700",
          },
        },

        plotLines: [
          {
            color: "#B7BAFA",
            width: 2,
            value: data?.["ltpIndex"],
            zIndex: 2,
            allowDecimals: false,
            label: {
              style: {
                fontWeight: "bold",
              },
              text: "",
              textAlign: "center",
              rotation: 0,
              zIndex: 2,
              y: 0,
            },
          },
        ],
        plotBands: [
          {
            color: "rgba(241, 242, 255, 0.38)",
            from: lowerBand2Index,
            to: upperBand2Index,
            zIndex: 1,
            label: {
              text: "-2σ",
              align: "left",
              x: 0,
              style: {
                color: "#000",
                fontSize: "14px",
                fontWeight: "400",
              },
            },
          },
          {
            color: "rgba(221, 222, 255, 0.38)",
            from: lowerBand1Index,
            to: upperBand1Index,
            zIndex: 1,
            label: {
              text: "-1σ",
              align: "left",
              x: 0,
              style: {
                color: "#000",
                fontSize: "14px",
                fontWeight: "400",
              },
            },
          },
          {
            color: "rgba(241, 242, 255, 0.38)",
            from: lowerBand2Index,
            to: upperBand2Index,
            zIndex: 1,
            label: {
              text: "+2σ",
              align: "right",
              x: 0,
              style: {
                color: "#000",
                fontSize: "14px",
                fontWeight: "400",
              },
            },
          },
          {
            color: "rgba(221, 222, 255, 0.38)",
            from: lowerBand1Index,
            to: upperBand1Index,
            zIndex: 1,
            label: {
              text: "+1σ",
              align: "right",
              x: 0,
              style: {
                color: "#000",
                fontSize: "14px",
                fontWeight: "400",
              },
            },
          },
        ],
      },
    }));
  };
  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  // for graph table:
  //for range slider
  const [sliderValue, setSliderValue] = useState(0);
  const [lowerLimit, setlower] = useState(0);
  const [duplicateVal, setdup] = useState(0);
  const [upperLimit, setupper] = useState(100);
  const [left, setleft] = useState(0);
  const [diff, setdiff] = useState(0);
  const [symb, setsymbol] = useState(null);
  const [expiries, setExpiries] = useState([]);
  const [selectedDate, setDate] = useState(new Date());
  const [chartError, setChartError] = useState(null);
  const [ltpIndex, setLtpIndex] = useState(0);

  const [daysleft, setdaysleft] = useState(0);
  const [daysCount, setdaysCount] = useState(0);
  const [daysToExpiry, setDaysToExpiry] = useState(0);

  useLayoutEffect(() => {
    let dt = new Date(leastExpiry);
    let varb = 0;
    let leastdate = new Date(selectedData[0]?.expiry + " 15:29:59");
    let g;
    selectedData.map((o) => {
      if (o == leastExpiry) {
        varb = true;
      }
      let ddate = new Date(o.expiry);
      leastdate = new Date(Math.max(ddate, leastdate));
    });

    if (!varb) {
      dt = new Date(leastdate);
      setDate(dt);
      let exd = new Date(leastdate);
      g = Math.round((dt - exd) / (1000 * 60 * 60 * 24));
      setdaysleft(g);
    }

    let td = new Date();
    let dates = [];
    let date = new Date();
    while (date.getDate() <= dt.getDate() - 1) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    dates.push(new Date(date));
    setExpiries(dates);

    let exd = new Date();
    let daysCount = Math.round((date - exd) / (1000 * 60 * 60 * 24) + 1);
    setdaysCount(daysCount);

    let finalExpiry = new Date(leastExpiry);
    let daysExpiry = Math.round(
      (finalExpiry - exd) / (1000 * 60 * 60 * 24) + 1
    );
    setDaysToExpiry(daysExpiry);

    updateTargetLine(g, daysExpiry);
  }, [leastExpiry, selectedData, data]);

  useEffect(() => {
    let j = ((sliderValue - lowerLimit) / (upperLimit - lowerLimit)) * 100;
    setleft(j);
  }, [sliderValue]);

  const handleDateClick = (event) => {
    let date = event.target.innerHTML;
    date = new Date(date);
    setDate(date);

    let exd = new Date(leastExpiry);
    let g = Math.round((exd - date) / (1000 * 60 * 60 * 24));
    setdaysleft(g);

    exd = new Date();
    let daysCount = Math.round((date - exd) / (1000 * 60 * 60 * 24) + 1);
    setdaysCount(daysCount);

    let finalExpiry = new Date(leastExpiry);
    let daysExpiry = Math.round(
      (finalExpiry - exd) / (1000 * 60 * 60 * 24) + 1
    );
    setDaysToExpiry(daysExpiry);
    // Computing target line

    updateTargetLine(g, daysExpiry);
    updateChart();
  };

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value);
    setSliderValue(value);
    if (duplicateVal) {
      let diff = value - Math.round(duplicateVal);
      diff /= duplicateVal;
      diff *= 100;
      diff = Math.round(diff * 100) / 100;
      setdiff(diff);
    }
  };

  const decrementLots = () => {
    if (lotsValue > 1) {
      setLotsValue(lotsValue - 1);
    }
  };

  const incrementLots = () => {
    setLotsValue(lotsValue + 1);
  };

  useEffect(() => {
    if (y_axis?.length > 0) {
      setAtmIndex(symbolData);
      updateChart();
    }
  }, [y_axis, calculatedIV, lotsValue]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = date.toLocaleDateString(undefined, { day: "2-digit" });
    const month = date.toLocaleDateString(undefined, { month: "short" });
    const year = date.toLocaleDateString(undefined, { year: "2-digit" });

    return `${dayOfWeek}, ${day} ${month} ${year}`;
  };

  return (
    <div className="strategy-graph d-flex flex-column justify-content-between py-3 w-100 mt-2 mt-md-0">
      <div className="graph-btns d-flex gap-2 px-3 w-100">
        <button
          className={`filter-btns payoffmobile ${
            activeButton === "payoffchart" ? "active" : ""
          }`}
          style={{ width: "100px" }}
          onClick={() => handleButtonClick("payoffchart")}>
          Payoff chart
        </button>
        {allOptionData != null ? (
          <button
            className={`filter-btns ${activeButton === "p&ltable" ? "active" : ""}`}
            onClick={() => handleButtonClick("p&ltable")}>
            P&L table
          </button>
        ) : (
          <div className="d-flex">
            <button
              className="filter-btns pandlinvisible"
              onClick={() => handleButtonClick("p&ltable")}
              style={{ visibility: "hidden" }}>
              P&L table
            </button>
            <button
              className="filter-btns pandlinvisible"
              onClick={() => handleButtonClick("p&ltable")}
              style={{ visibility: "hidden" }}>
              P&L table
            </button>
            <button
              className="filter-btns pandlinvisible"
              onClick={() => handleButtonClick("p&ltable")}
              style={{ visibility: "hidden" }}>
              P&L table
            </button>
            {/* <button
              className="filter-btns pandlinvisible adjustment-btn"
              onClick={() => handleButtonClick("p&ltable")}
              style={{ visibility: "hidden" }}>
              P&L table
            </button> */}
          </div>
        )}
      </div>

      {activeButton === "payoffchart" && (
        // Show the graph when activeButton is "payoffchart"
        <div style={{ height: "90%" }}>
          {chartError ? (
            <div>Error rendering chart: {chartError.message}</div>
          ) : (
            <div>
              <div
                style={{
                  textAlign: "center",
                  // fontSize: "14px",
                  marginBottom: "8px",
                  marginLeft: "45px",
                }}>
                <span
                  style={{
                    // border: "1px solid #989898",
                    // padding: "2px 8px",
                    // borderRadius: "4px",
                    color: "#555",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}>
                  {ltp}
                </span>
              </div>
              <div id="container">
                <HighchartsReact
                  highcharts={Highcharts}
                  allowChartUpdate={true}
                  options={chartOptions}
                  ref={chartComponent}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {activeButton === "p&ltable" && (
        <div
          className="main-graph-table table-container my-3"
          style={{ display: activeButton === "p&ltable" ? "block" : "none" }}>
          <div className="table-container">
            {window.innerWidth > 600 && (
              <table className="strategy-tablee">
                <thead>
                  <tr
                    className="text-center tr-strategy-headerr"
                    style={{ border: "1px solid #EFEFEF", width: "auto" }}>
                    <th scope="col" className="text-start ps-3 pe-5 py-2 ">
                      Instrument
                    </th>
                    <th scope="col">Lots</th>
                    <th scope="col">Entry price</th>
                    <th scope="col">Target price</th>
                    <th scope="col" className="pe-3">
                      Estimated P&L
                    </th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {selectedData &&
                    selectedData.map((obj, index) => (
                      <tr
                        className="strategy-table-roww text-center"
                        key={index}>
                        <td
                          className="table-cell text-start ps-3"
                          style={{
                            maxWidth: "100px",
                            wordWrap: "break-all",
                            overflow: "hidden",
                          }}>
                          <div className="d-flex align-items-center">
                            {/* BANKNIFTY 15 JUN 23 42100 CE */}
                            {obj?.tradingSymbol}
                          </div>
                        </td>
                        <td className="table-cell">{obj?.lotSize}</td>

                        <td className="table-cell">{obj?.price}</td>
                        <td className="table-cell">{obj?.targetPrice}</td>
                        <td
                          className={`table-cell pe-3 ${
                            (obj?.isBuy ? -1 : 1) *
                              (obj?.price - obj?.targetPrice) >=
                            0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          ₹{" "}
                          {(
                            (obj?.isBuy ? -1 : 1) *
                            ((obj?.price - obj?.targetPrice) *
                              obj?.lotSize *
                              obj?.lot_multiplier)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {window.innerWidth < 420 && (
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <table className="strategy-tablee">
                  <thead>
                    <tr
                      className="text-center tr-strategy-headerr"
                      style={{ border: "1px solid #EFEFEF" }}>
                      <tr
                        className="d-flex justify-content-between align-items-center px-2 "
                        style={{ width: "auto" }}>
                        <th scope="col" className="py-2">
                          Instrument
                        </th>
                        <th scope="col">Lots</th>
                      </tr>
                      <tr
                        className="d-flex justify-content-between align-items-center px-2"
                        style={{ width: "auto" }}>
                        <th scope="col" className="pb-2">
                          Entry price
                        </th>
                        <th scope="col">Target price</th>
                        <th scope="col">Estimated P&L</th>
                      </tr>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {selectedData &&
                      selectedData.map((obj, index) => (
                        <tr
                          className="strategy-table-roww text-center"
                          key={index}>
                          <tr
                            className="d-flex  align-items-center justify-content-between px-2 pb-2"
                            style={{ width: "auto" }}>
                            <td className="table-cell">
                              <div className="d-flex align-items-center">
                                {obj?.tradingSymbol}
                              </div>
                            </td>
                            <td className="table-cell mt-1 d-flex align-items-center justify-content-center">
                              {obj?.lotSize}
                            </td>
                          </tr>
                          <tr
                            className="d-flex justify-content-between align-items-center px-2"
                            style={{ width: "auto" }}>
                            <td className="table-cell">
                              ₹{formatNumberWithCommasAndFontWeight(obj?.price)}
                            </td>
                            <td className="table-cell ">
                              ₹
                              {formatNumberWithCommasAndFontWeight(
                                obj?.targetPrice
                              )}
                            </td>
                            <td
                              className={`table-cell ${
                                (obj?.isBuy ? -1 : 1) *
                                  (obj?.price - obj?.targetPrice) >=
                                0
                                  ? "text-success"
                                  : "text-danger"
                              }`}>
                              ₹{" "}
                              {formatNumberWithCommasAndFontWeight(
                                (
                                  (obj?.isBuy ? -1 : 1) *
                                  ((obj?.price - obj?.targetPrice) *
                                    obj?.lotSize *
                                    obj?.lot_multiplier)
                                ).toFixed(2)
                              )}
                            </td>
                          </tr>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center pe-3 mt-5">
            <span className="graph-text">Total estimated P&L </span>
            <svg
              className="ms-2 me-3"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none">
              <path
                d="M7.00004 8.1667V7.71721C7.00004 7.13791 7.44495 6.65974 7.94111 6.36071C8.42012 6.07203 8.8775 5.55888 8.75004 4.66667C8.50004 2.91666 6.12504 3.79168 6.12504 3.79168M7.00004 9.91668V10.2083M13.4167 7.00001C13.4167 10.5438 10.5439 13.4167 7.00004 13.4167C3.45621 13.4167 0.583374 10.5438 0.583374 7.00001C0.583374 3.45618 3.45621 0.583344 7.00004 0.583344C10.5439 0.583344 13.4167 3.45618 13.4167 7.00001Z"
                stroke="#555555"
                strokeLinecap="round"
                strokeLinejoin="round"
              />{" "}
            </svg>
            <span
              className={`estimated-total
            ${
              selectedData
                .map(
                  (obj) =>
                    (obj?.isBuy ? -1 : 1) *
                    ((obj?.price - obj?.targetPrice) *
                      obj?.lotSize *
                      obj?.lot_multiplier)
                )
                .reduce((acc, value) => acc + value)
                .toFixed(2) >= 0
                ? "text-success"
                : "text-danger"
            }`}>
              ₹{" "}
              {formatNumberWithCommasAndFontWeight(
                selectedData
                  .map(
                    (obj) =>
                      (obj?.isBuy ? -1 : 1) *
                      ((obj?.price - obj?.targetPrice) *
                        obj?.lotSize *
                        obj?.lot_multiplier)
                  )
                  .reduce((acc, value) => acc + value)
                  .toFixed(2)
              )}
            </span>
          </div>
        </div>
      )}
      {allOptionData != null && (
        <div className="d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-center gap-3 gap-md-0 px-3">
          <div className="d-flex justify-content-end align-items-center">
            <span className="graph-text">Standard deviation </span>
            <svg
              className="ms-2 me-3"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none">
              <path
                d="M7.00004 8.1667V7.71721C7.00004 7.13791 7.44495 6.65974 7.94111 6.36071C8.42012 6.07203 8.8775 5.55888 8.75004 4.66667C8.50004 2.91666 6.12504 3.79168 6.12504 3.79168M7.00004 9.91668V10.2083M13.4167 7.00001C13.4167 10.5438 10.5439 13.4167 7.00004 13.4167C3.45621 13.4167 0.583374 10.5438 0.583374 7.00001C0.583374 3.45618 3.45621 0.583344 7.00004 0.583344C10.5439 0.583344 13.4167 3.45618 13.4167 7.00001Z"
                stroke="#555555"
                strokeLinecap="round"
                strokeLinejoin="round"
              />{" "}
            </svg>
            <span>
              <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
                <span
                  role="button"
                  onClick={decrementLots}
                  style={{
                    color: "#C1C1C1",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "0px",
                    cursor: "pointer",
                  }}>
                  -
                </span>
                <span>{lotsValue}</span>
                <span
                  role="button"
                  onClick={incrementLots}
                  className="plus"
                  style={{
                    color: "#C1C1C1",
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "0px",
                    cursor: "pointer",
                  }}>
                  +
                </span>
              </div>
            </span>
          </div>
          <div className="graph-target-day-container d-flex justify-content-between align-items-center gap-md-4">
            <div className="graph-text">Target day</div>
            <div className="dropdown">
              <button
                className="btn-date dropdown-toggle p-0"
                type="button"
                style={{ width: "120px", color: "#383FEC" }}
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {formatDate(selectedDate)}
              </button>
              <ul className="dropdown-menu target-date-dropdown-menu">
                {expiries?.map((o, ind) => (
                  <li
                    key={ind}
                    className="dropdown-item target-date-dropdown-item"
                    onClick={handleDateClick}>
                    {formatDate(o)}
                  </li>
                ))}
              </ul>
            </div>
            <div className="graph-text">
              {daysleft} day{daysleft > 1 ? "s" : ""} to expire
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StrategyGraph;
