import React, { useState } from "react";

const BuySellModal = ({ activeBuySellOption, setActiveBuySellOption }) => {
  const [activeOption, setActiveOption] = useState("intraday");
  // const [activeBuySellOption, setActiveBuySellOption] = useState("sell");
  const [qtyButtonText, setQtyButtonText] = useState("Sell 50 Qty");

  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  const handleBuySellToggle = (option) => {
    setActiveBuySellOption(option);
    setQtyButtonText(
      `${option.charAt(0).toUpperCase() + option.slice(1)} 50 Qty`
    );
  };

  const isActiveButton = (buttonName) => {
    return orderType === buttonName ? "active" : "";
  };

  const [orderType, setOrderType] = useState("marketorder");
  const [isPriceEnabled, setIsPriceEnabled] = useState(false);

  const handleOrderTypeChange = (type) => {
    setOrderType(type);
    setIsPriceEnabled(type !== "marketorder"); // Enable/Disable "Price" input based on the selected order type
  };

  return (
    <div
      className="buy-sell-modal modal fade"
      id="staticBackdropBuySell"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header d-flex flex-column gap-2 border-bottom">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ gap: "300px" }}>
              <p className="modal-title " id="staticBackdropLabel">
                BANKNIFTY 15 Jun 23 39300 CE
              </p>
              <div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: "360px" }}>
              <div className="toggle-containerr" style={{ height: "35px" }}>
                <button
                  className={`toggle-buttonn buy ${
                    activeBuySellOption === "buy" ? "active" : ""
                  }`}
                  onClick={() => handleBuySellToggle("buy")}>
                  Buy
                </button>
                <button
                  className={`toggle-buttonn sell ${
                    activeBuySellOption === "sell" ? "active" : ""
                  }`}
                  onClick={() => handleBuySellToggle("sell")}>
                  Sell
                </button>
              </div>
              <div className="toggle-container" style={{ height: "35px" }}>
                <button
                  className={`toggle-button ${
                    activeOption === "intraday" ? "active" : ""
                  }`}
                  onClick={() => handleOptionChange("intraday")}>
                  Intraday
                </button>
                <button
                  className={`toggle-button ${
                    activeOption === "normal" ? "active" : ""
                  }`}
                  onClick={() => handleOptionChange("normal")}>
                  Normal
                </button>
              </div>
            </div>
          </div>

          <div className="modal-body px-4">
            <div className="btn-group-1 d-flex gap-2">
              <button
                className={`btn-1 marketorder ${isActiveButton("marketorder")}`}
                onClick={() => handleOrderTypeChange("marketorder")}>
                Market order
              </button>
              <button
                className={`btn-1 limitorder ${isActiveButton("limitorder")}`}
                onClick={() => handleOrderTypeChange("limitorder")}>
                Limit order
              </button>
              <button
                className={`btn-1 triggerorder ${isActiveButton(
                  "triggerorder"
                )}`}
                onClick={() => handleOrderTypeChange("triggerorder")}>
                Trigger order
              </button>
              <button
                className={`btn-1 coverorder ${isActiveButton("coverorder")}`}
                onClick={() => handleOrderTypeChange("coverorder")}>
                Cover order
              </button>
              <button
                className={`btn-1 bracketorder ${isActiveButton(
                  "bracketorder"
                )}`}
                onClick={() => handleOrderTypeChange("bracketorder")}>
                Bracket order
              </button>
            </div>
            <div className="d-flex gap-3 mt-4">
              <div className="input-container">
                <label for="lots" className="input-label">
                  <span className="bg-white">Lots</span>
                </label>
                <input
                  type="number"
                  id="lots"
                  className="number-input text-end pe-2"
                  value={1}
                />
              </div>
              {orderType === "marketorder" && (
                <div className="input-container">
                  <label htmlFor="price" className="input-label">
                    <span className="bg-white">Price</span>
                  </label>
                  <input
                    type="number"
                    id="price"
                    className="number-input disabled number-input-disabled text-end pe-2"
                    value={120.0}
                  />
                </div>
              )}
              {(orderType === "limitorder" || orderType === "triggerorder") && (
                <div className="input-container">
                  <label htmlFor="price" className="input-label">
                    <span className="bg-white">Price</span>
                  </label>
                  <input
                    type="number"
                    id="price"
                    className="number-input text-end pe-2"
                    value={120.0}
                  />
                </div>
              )}
              {orderType === "triggerorder" && (
                <div className="input-container">
                  <label htmlFor="triggerPrice" className="input-label">
                    <span className="bg-white">Trigger Price</span>
                  </label>
                  <input
                    type="number"
                    id="triggerPrice"
                    className="number-input text-end pe-2"
                    value={120.0}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer justify-content-between ">
            <div className="creator-bottom d-flex justify-content-start align-items-center px-2 mb-2 gap-4">
              <div className="d-flex flex-column">
                <span className="creator-bottom-title ">
                  Estmtd margin{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none">
                    <path
                      d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                      stroke="#555555"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                </span>
                <span className="creator-bottom-value text-success">
                  ₹ 1,39,241.20
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">
                  Charges{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none">
                    <path
                      d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                      stroke="#555555"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                </span>
                <span className="creator-bottom-value">₹ 24.2</span>
              </div>
              <div className="d-flex flex-column">
                <span className="creator-bottom-title">Margin available</span>
                <span className="creator-bottom-value">₹ 12,00,00.00</span>
              </div>
            </div>
            <div className="d-flex gap-3">
              <button className={`buy-sell-impact-btn `}>
                <svg
                  className="pe-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none">
                  <path
                    d="M17.8934 13.1798C17.3841 14.4402 16.5743 15.5569 15.5345 16.4324C14.4947 17.308 13.2565 17.916 11.9279 18.2032C10.5992 18.4905 9.22046 18.4484 7.91183 18.0805C6.60319 17.7127 5.4044 17.0303 4.42 16.0929C3.4356 15.1554 2.69546 13.9914 2.26413 12.7023C1.8328 11.4132 1.72338 10.0381 1.9454 8.69705C2.16741 7.35595 2.71413 6.08952 3.53789 5.00819C4.36164 3.92687 5.43741 3.06347 6.6714 2.49329"
                    stroke="#14142B"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11 2.16856C11 1.4782 11.5627 0.908966 12.2453 1.01213C12.9112 1.11277 13.564 1.294 14.189 1.5529C15.2001 1.97168 16.1187 2.58551 16.8926 3.35934C17.6664 4.13316 18.2802 5.05182 18.699 6.06286C18.9579 6.6879 19.1391 7.34067 19.2398 8.00657C19.3429 8.68917 18.7737 9.25189 18.0833 9.25189L12.25 9.25189C11.5596 9.25189 11 8.69225 11 8.00189L11 2.16856Z"
                    fill="#70D11F"
                  />
                </svg>
                Impact
              </button>
              <button
                className={`buy-sell-qty-btn ${
                  activeBuySellOption === "sell" ? "sell-bg" : "buy-bg"
                }`}>
                {qtyButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySellModal;
