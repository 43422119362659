import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../images/Logo.svg";
import UserIcon from "../../images/user-icon.svg";
import { CLIENT_ID, BROKER_NAME } from "../../utils/constants";
import ToolsModal from "../../modals/ToolsModal";

const HeaderDesktop = ({ symbolData }) => {
  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "https://optiongeeks.in/";
  };

  //for setting the date
  useEffect(() => {
    const currentDate = new Date().toLocaleDateString();
    localStorage.setItem("login_date", currentDate);
  }, []);

  //for automatically logging out if date doesn't match
  useEffect(() => {
    const storedDate = localStorage.getItem("login_date");
    const currentDate = new Date().toLocaleDateString();
    if (storedDate !== currentDate) {
      localStorage.clear();
      window.location.href = "https://optiongeeks.in/";
    }
  }, []);

  return (
    <div className="shadow-sm">
      <div className="grid-container">
        <nav
          className="header fixed-top navbar navbar-expand-lg shadow-mb-sm bg-white"
          style={{ zIndex: "999" }}>
          <div
            className="container-fluid  bg-white"
            style={{ maxWidth: "1392px" }}>
            <Link
              className="navbar-brand d-flex align-items-center justify-content-center  "
              to="/">
              <img src={Logo} alt="logo" className="app-logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-md-3 ps-md-5 gap-md-3 text-decoration-none">
                {/* <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    // className="nav-link navigation"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/backtest">
                    Backtest
                  </NavLink>
                </li>{" "} */}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    // className="nav-link navigation"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/">
                    Strategy creator
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/optionchain">
                    Option chain
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/positions">
                    Positions
                  </NavLink>
                </li>{" "}
                <li className="nav-item mb-2 mb-md-0">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to="/orders">
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item mb-2 mb-md-0 " type="button">
                  <div class="dropdown">
                    <button
                      style={{ textDecoration: "none", color: "black" }}
                      class="dropdown-toggle tools-dropdown-btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      Tools{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none">
                        <path
                          d="M12 1L6.5 7L1 1"
                          stroke="#000000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <div
                      class="dropdown-menu tools-dropdown-content p-3 mt-3"
                      aria-labelledby="dropdownMenuButton">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column gap-3">
                          <div className="d-flex flex-column">
                            <div className="tools-feature-title">
                              Virtual portfolio
                            </div>
                            <li className="nav-item">
                              <NavLink
                                to="/virtualpositions"
                                className="tools-feature-name text-decoration-none">
                                Virtual positions
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink
                                to="/virtualorders"
                                className="tools-feature-name text-decoration-none">
                                Virtual orders
                              </NavLink>
                            </li>
                          </div>
                          <div className="d-flex flex-column">
                            <div className="tools-feature-title">
                              Verified P&L
                              <span className="tools-label ms-2">
                                Coming soon
                              </span>{" "}
                            </div>
                            <div className="tools-feature-name">
                              My Verified P&L’s
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div className="tools-feature-title">
                              Market
                              <span className="tools-label ms-2">
                                Coming soon
                              </span>{" "}
                            </div>
                            <div className="tools-feature-name">
                              Strategy finder
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="tools-feature-title">
                            OI ANALYSIS
                            <span className="tools-label ms-2">
                              Coming soon
                            </span>{" "}
                          </div>
                          <div className="tools-feature-name">
                            Options premium
                          </div>
                          <div className="tools-feature-name">
                            Multi-strike chart
                          </div>
                          <div className="tools-feature-name">PCR chart</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div
                className="d-flex flex-column flex-md-row list-unstyled justify-content-start justify-content-md-between"
                style={{ lineHeight: "normal" }}>
                <li className=" nav-item">
                  <Link className="nav-link " aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">NIFTY</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26000"]?.ltp.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26000"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26000"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26000"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26000"]?.change > 0 ? "+" : ""}
                          {symbolData["26000"]?.change}
                          {symbolData["26000"]?.change_per ? "(" : ""}
                          {symbolData["26000"]?.change_per}
                          {symbolData["26000"]?.change_per ? "%)" : "-"}
                          {/* {symbolData["101000000026000"]?.change > 0 ? "+" : ""}
                          {symbolData["101000000026000"]?.change}
                          {symbolData["101000000026000"]?.change_per ? "(" : ""}
                          {symbolData["101000000026000"]?.change_per}
                          {symbolData["101000000026000"]?.change_per  */}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className=" nav-item">
                  <Link className="nav-link" aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">BANKNIFTY</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26009"]?.ltp.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26009"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26009"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26009"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26009"]?.change > 0 ? "+" : ""}
                          {symbolData["26009"]?.change}
                          {symbolData["26009"]?.change_per ? "(" : ""}
                          {symbolData["26009"]?.change_per}
                          {symbolData["26009"]?.change_per ? "%)" : "-"}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
                <li className=" nav-item">
                  <Link className="nav-link " aria-current="page">
                    <div className="symbol-containers d-flex flex-column align-items-md-start">
                      <div className="symbol-name">INDIA VIX</div>
                      <div>
                        <span className="nifty">
                          {symbolData["26017"]?.ltp.includes(".")
                            ? formatNumberWithCommasAndFontWeight(
                                symbolData["26017"]?.ltp
                              )
                            : formatNumberWithCommasAndFontWeight(
                                symbolData["26017"]?.ltp
                              )}
                        </span>

                        <span
                          className={`ch-chp me-2 ${
                            symbolData["26017"]?.change > 0
                              ? "text-success"
                              : "text-danger"
                          }`}>
                          {symbolData["26017"]?.change > 0 ? "+" : ""}
                          {symbolData["26017"]?.change}
                          {symbolData["26017"]?.change_per ? "(" : ""}
                          {symbolData["26017"]?.change_per}
                          {symbolData["26017"]?.change_per ? "%)" : "-"}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </div>

              <div className="d-flex justify-content-center align-items-center gap-2">
                <img
                  src={UserIcon}
                  alt="User Profile Pic"
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                  }}
                  type="button"
                />
                <div className="d-flex flex-column gap-2">
                  <span className="client-id">
                    {CLIENT_ID}
                    <span className="header-dropdown dropdown">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        className="ms-1 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <path
                          d="M12 1L6.5 7L1 1"
                          stroke="#1E2028"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <ul className="dropdown-menu header-dropdown-menu">
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={handleLogout}>
                            Logout
                          </button>
                        </li>
                      </ul>
                    </span>
                  </span>
                  <span className="broker-name">{BROKER_NAME?.toUpperCase()}</span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* <ToolsModal /> */}
    </div>
  );
};

export default HeaderDesktop;
