import React, { useEffect, useState } from "react";
import axios from "axios";
import { getSymbols } from "../utils/api";

const SearchModal = ({ onSymbolSelect }) => {
  const [searchText, setSearchText] = useState("");
  const [symbols, setSymbols] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  // Fetch symbols from the API
  const fetchSymbols = async () => {
    try {
      const symbolsData = await getSymbols();
      if (symbolsData) {
        setSymbols(symbolsData);
      }
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
    searchSymbols(event.target.value);
  };

  // Search symbols based on the search text
  const searchSymbols = (searchText) => {
    const filteredSymbols = symbols.filter((symbol) =>
      symbol.scrip.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchResults(filteredSymbols);
  };

  // Handle symbol selection
  const handleSymbolSelection = (symbol) => {
    console.log("Selected Symbol:", symbol.scrip);
    console.log("Futures Expiries:", symbol.fut_expiries);
    // Pass the selected symbol's data to the parent component
    onSymbolSelect(symbol);
  };

  // Fetch symbols on component mount
  useEffect(() => {
    fetchSymbols();
  }, []);

  return (
    <div
      className="search-modal modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog  modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <input
              className="ps-2"
              type="text"
              placeholder="Search underlying instruments"
              value={searchText}
              onChange={handleSearchInputChange}
            />
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <ul className="list-unstyled list-group">
              <li className="pop-searches-title">Popular searches</li>
              {searchResults.map((symbol) => (
                <li
                  key={symbol.scrip}
                  className="d-flex justify-content-between align-items-center list-group-item-action"
                  data-bs-dismiss="modal"
                  role="button"
                  onClick={() => handleSymbolSelection(symbol)}>
                  <span className="pop-searches-symbol">{symbol.scrip}</span>
                  <span className="index d-flex justify-content-center align-items-center">
                    Index
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
