import React from "react";
import { Helmet } from "react-helmet-async";
import StrategyCreatorDesktop from "./StrategyCreatorDesktop";
import StrategyCreatorMobile from "./StrategyCreatorMobile";

const StrategyCreator = () => {
  function isMobile() {
    return (window.screen.width > 430 ) ? false : true;
  }

  return isMobile() ? (
    <>
      <Helmet>
        <title>Strategy Creator</title>
      </Helmet>
      <div className="mobile-screen d-grid" style={{ gridColumn: "span 4" }}>
        <StrategyCreatorMobile />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Strategy Creator</title>
      </Helmet>

      <div className="desktop-screenn">
        <StrategyCreatorDesktop />
      </div>
    </>
  );
};

export default StrategyCreator;
