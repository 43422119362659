import React, { useEffect, useState } from "react";
import { CLIENT_ID } from "../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
const MenuModal = () => {
  const location = useLocation();

  useEffect(() => {
    const modalCloseButton = document.querySelector(
      '[data-bs-dismiss="modal"]'
    );
    if (modalCloseButton) {
      modalCloseButton.click();
    }
  }, [location]);

  return (
    <>
      <div
        className="modal option-chain-modal menu-modal"
        id="menuModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div
          className="modal-dialog modal-xl option-chain-modal-dialog"
          style={{ margin: "0" }}>
          <div
            className="modal-content option-chain-modal-content"
            style={{ height: "100vh" }}>
            <div className="modal-body d-flex flex-column">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <div className="menu-title">Menu</div>
                  <div className="menu-description">
                    Find more tools & actions here
                  </div>
                </div>
                <button
                  type="button"
                  className="btn-close mb-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="virtual-title">Virtual portfolio</div>
                <Link to="/virtualpositions" className="virtual-feature">
                  Virtual positions
                </Link>
                <Link to="/virtualorders" className="virtual-feature">
                  Virtual orders
                </Link>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="d-flex justify-content-between">
                  <div className="virtual-title">Verified P&L</div>
                  <span className="coming-soon">Coming soon</span>
                </div>
                <Link className="virtual-feature">My Verified P&L’s</Link>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="d-flex justify-content-between">
                  <div className="virtual-title">Market</div>
                  <span className="coming-soon">Coming soon</span>
                </div>
                <Link className="virtual-feature">Strategy finder</Link>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="d-flex justify-content-between">
                  <div className="virtual-title">OI Analysis</div>
                  <span className="coming-soon">Coming soon</span>
                </div>
                <Link className="virtual-feature">Options premium</Link>
                <Link className="virtual-feature">Multistrike charts</Link>
                <Link className="virtual-feature">PCR charts</Link>
              </div>

              <div className="d-flex flex-column mt-3">
                <div className="virtual-title">{CLIENT_ID}'s account</div>

                <Link className="virtual-feature">Logout</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuModal;
