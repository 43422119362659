import React, { useEffect, useState } from "react";
import { useBasketContext } from "../context/BasketContext";
import { useGraphContext } from "../context/GraphContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContent from "../components/CustomToastContent";
import ErrorIcon from "../images/error_icon.svg";
import SuccessIcon from "../images/success_icon.svg";
import axios from "axios";
import { useFundsData } from "../context/FundsDataContext";
const BasketMobile = ({ isOpen, onClose }) => {
  const { fundsData } = useFundsData();
  const broker = localStorage.getItem("broker");
  const access_token = localStorage.getItem("access_token");
  const client_id = localStorage.getItem("client_id");
  const [isBuy, setIsBuy] = useState(true);
  const [isCALL, setIsCALL] = useState(true);
  const {
    basketData,
    removeFromBasket,
    buySellStatus,
    toggleBuySellStatus,
    handleIncrement,
    handleDecrement,
    clearBasket,
    handlePriceChange,
  } = useBasketContext();
  const { maxProfit, maxLoss, riskReward, breakevenRange, premium } =
    useGraphContext();
  const [activeOptions, setActiveOptions] = useState([]);

  useEffect(() => {
    setActiveOptions(Array(basketData.length).fill("intraday"));
  }, [basketData]);

  const handleOptionChange = (index, option) => {
    setActiveOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = option;
      return updatedOptions;
    });
  };

  // MARKET Checkbox logic
  const [checkboxStates, setCheckboxStates] = useState([]);

  useEffect(() => {
    setCheckboxStates(Array(basketData.length).fill(true));
  }, [basketData]);

  const handleCheckboxChange = (index) => {
    setCheckboxStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };
  const StrategyLots = ({ row, index, handleIncrement, handleDecrement }) => {
    return (
      <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
        <span
          role="button"
          onClick={() => handleDecrement(index)}
          style={{
            color: "#C1C1C1",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "0px",
          }}>
          -
        </span>
        <span>{row?.lotSize}</span>
        <span
          role="button"
          className="plus"
          onClick={() => handleIncrement(index)}
          style={{
            color: "#C1C1C1",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "0px",
          }}>
          +
        </span>
      </div>
    );
  };
  const sendVirtualOrder = async (payload) => {
    try {
      const response = await axios.post(
        "https://hgb1gw5n6g.execute-api.ap-south-1.amazonaws.com/default/og_virtual_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };
  const handleExecuteVirtualOrder = async () => {
    try {
      const payload = generateOrderPayload();
      console.log("payload from basket", payload);
      const response = await sendVirtualOrder(payload);

      if (response.status) {
        clearBasket();
        onClose();
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing virtual order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing virtual order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  const handleExecuteOrder = async () => {
    try {
      const payload = generateVirtualOrderPayload();
      console.log("payload from basket", payload);
      const response = await sendOrder(payload);

      if (response.status) {
        clearBasket();
        onClose();
        toast.success(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-success-toast",
            icon: <img src={SuccessIcon} alt="Success Icon" />,
          }
        );
      } else {
        toast.error(
          <CustomToastContent
            heading={response.message_header}
            message={response.message_body}
          />,
          {
            className: "custom-error-toast",
            icon: <img src={ErrorIcon} alt="Error Icon" />,
          }
        );
      }
    } catch (error) {
      console.error("Error executing virtual order:", error);
      toast.error(
        <CustomToastContent
          heading="Error executing virtual order"
          message={error.message}
        />,
        {
          className: "custom-error-toast",
          icon: <img src={ErrorIcon} alt="Error Icon" />,
        }
      );
    }
  };

  // api calls
  const [orderPayload, setOrderPayload] = useState(null);
  const [marginRequired, setMarginRequired] = useState(null);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false); // State to track if order is successfully placed

  // Function to generate order payload
  const generateOrderPayload = () => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      orderType: checkboxStates[ind] ? "MARKET" : "LIMIT",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
    }));
    return {
      order_list: orderList,
      broker: broker,
      access_token: access_token,
      client_id: client_id,
    };
  };

  const generateVirtualOrderPayload = () => {
    const orderList = basketData.map((currData, ind) => ({
      isBuy: currData.buy_sell === "B" ? true : false,
      isCall: currData.side === "Call" ? true : false,
      isFut: false,
      code: currData.code,
      exchange: currData.exchange,
      productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
      orderType: checkboxStates[ind] ? "MARKET" : "LIMIT",
      quantity: currData.lotSize * currData.lot_multiplier,
      price: currData.price,
      freeze_qty: currData.freeze_qty,
      strike: currData.strike,
      expiry: currData.expiryDate,
      tradingSymbol: currData.tradingSymbol,
      lot_multiplier: currData.lot_multiplier,
      scrip: currData.scrip,
    }));
    return {
      order_list: orderList,
      main_broker: broker,
      broker: "virtual",
      access_token: access_token,
      client_id: client_id,
    };
  };

  // Function to send order
  const sendOrder = async (payload) => {
    console.log("payload in basket", payload);
    try {
      const response = await axios.post(
        "https://0eo1dv23p5.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_placeorder",
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error placing order:", error);
      return {
        status: false,
        message_header: "Error placing order",
        message_body: error.message,
      };
    }
  };

  // Effect to fetch margin required
  useEffect(() => {
    const generateMarginPayload = () => {
      const orderList = basketData.map((currData, ind) => ({
        isBuy: currData.buy_sell === "B" ? true : false,
        isCall: currData.side === "Call" ? true : false,
        isFut: false,
        code: currData.code,
        exchange: currData.exchange,
        productType: activeOptions[ind] === "normal" ? "NRML" : "MIS",
        orderType: checkboxStates[ind] ? "MARKET" : "LIMIT",
        quantity: currData.lotSize * currData.lot_multiplier,
        price: currData.price,
        strike: currData.strike,
        expiry: currData.expiryDate,
        tradingSymbol: currData.tradingSymbol,
        lot_multiplier: currData.lot_multiplier,
        scrip: currData.scrip,
      }));
      return {
        order_list: orderList,
        broker: broker,
        access_token: access_token,
        client_id: client_id,
      };
    };

    const fetchMarginRequired = async () => {
      try {
        const payload = generateMarginPayload();

        const response = await axios.post(
          "https://7mwh6csmrh.execute-api.ap-south-1.amazonaws.com/default/og_allbrokers_margin",
          payload
        );

        setMarginRequired(response.data.marginRequired);
      } catch (error) {
        console.error("Error getting margin required:", error);
      }
    };
    if (basketData) {
      fetchMarginRequired();
    }
  }, [basketData]);

  function formatNumberWithCommasAndFontWeight(value) {
    const formattedValue = parseFloat(value).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const parts = formattedValue.split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1];

    return (
      <span>
        {integerPart}.<span style={{ fontWeight: 400 }}>{decimalPart}</span>
      </span>
    );
  }
  return (
    <>
      {isOpen && (
        <>
          <div className="basket-mobile-overlay"></div>
          <div className={`basket-mobile ${isOpen ? "show" : ""}`}>
            <div className="d-flex flex-column gap-3 ">
              <div className="d-flex flex-column gap-2 mt-3">
                <div className=" d-flex justify-content-start align-items-center px-3 gap-4 w-100">
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Max profit</span>
                    <span className="strategy-menu-value text-success">
                      {maxProfit}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Max loss </span>
                    <span className="strategy-menu-value text-danger">
                      {maxLoss}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="creator-bottom-title">Risk reward </span>
                    <span className="strategy-menu-value">{riskReward}</span>
                  </div>
                  <button
                    type="button"
                    className="btn-close ms-auto"
                    onClick={() => {
                      onClose();
                    }}></button>
                </div>
                <div className=" d-flex justify-content-start align-items-center px-3 gap-4 w-100">
                  <div className="d-flex flex-column ">
                    <span className="creator-bottom-title">
                      Breakeven between
                    </span>
                    <span className="strategy-menu-value">
                      {breakevenRange}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="px-0"
                style={{ maxHeight: "360px", overflowY: "auto" }}>
                <div className="table-container">
                  <table className="strategy-tablee">
                    <thead>
                      <tr
                        className="text-center tr-strategy-headerr"
                        style={{ border: "1px solid #EFEFEF" }}>
                        <tr
                          className="d-flex justify-content-start align-items-center gap-3"
                          style={{ width: "98vw", padding: "0px 12px" }}>
                          <th scope="col" className="py-2">
                            B/S
                          </th>
                          <th scope="col" className="py-2">
                            Instrument
                          </th>
                          <th scope="col" className="py-2"></th>
                        </tr>
                        <tr
                          className="d-flex justify-content-between align-items-center "
                          style={{ width: "98vw", padding: "0px 12px" }}>
                          <th scope="col" className="pb-2">
                            Lots
                          </th>
                          <th scope="col">Product type</th>
                          <th scope="col">Price</th>
                        </tr>
                      </tr>
                    </thead>
                  </table>
                  <div style={{ overflowY: "auto", maxHeight: "300px" }}>
                    <table>
                      <tbody className="tbody">
                        {basketData.map((item, index) => (
                          <tr
                            key={index}
                            className="strategy-table-roww border-bottom"
                            style={{ height: "66px" }}>
                            <tr
                              className="d-flex  align-items-center justify-content-start px-2 pb-2"
                              style={{ width: "98vw", padding: "0px 12px" }}>
                              <td className="basket-table-cell text-center">
                                <div
                                  className={`bs-value d-flex align-items-center justify-content-center ${
                                    item["buy_sell"] === "B" ? "buy" : "sell"
                                  }`}
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    borderRadius: "4px",

                                    background:
                                      item["buy_sell"] === "B"
                                        ? "#70D11F"
                                        : "#FF3908",
                                    color: "white",
                                  }}
                                  onClick={() => toggleBuySellStatus(index)}
                                  role="button">
                                  {item["buy_sell"] === "B" ? "B" : "S"}
                                </div>
                              </td>
                              <td className="basket-table-cell text-start ">
                                <div className="d-flex align-items-center ps-2">
                                  {item.instrumentName}
                                </div>
                              </td>
                              <td className="basket-table-action basket-table-cell d-flex align-items-center justify-content-center gap-4 ms-auto">
                                <div className="d-flex align-items-center justify-content-center">
                                  <svg
                                    role="button"
                                    onClick={() => removeFromBasket(index)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                      d="M12.6364 3.90909V3.18182C12.6364 1.97683 11.6595 1 10.4545 1H7.54545C6.34047 1 5.36364 1.97683 5.36364 3.18182V3.90909M1 3.90909H17M11.1818 8.27273V11.9091M6.81818 8.27273V11.9091M2.45455 3.90909H15.5455L15.0636 13.5452C14.9669 15.4805 13.3695 17 11.4318 17H6.56818C4.63045 17 3.03312 15.4805 2.93635 13.5452L2.45455 3.90909Z"
                                      stroke="#C1C1C1"
                                      strokeLinecap="round"
                                    />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                            <tr
                              className="d-flex  align-items-center justify-content-between px-2 pb-2"
                              style={{ width: "98vw", padding: "0px 12px" }}>
                              <td className="basket-table-cell">
                                <StrategyLots
                                  row={item}
                                  index={index}
                                  handleIncrement={handleIncrement}
                                  handleDecrement={handleDecrement}
                                />
                              </td>

                              <td className="basket-table-cell">
                                <div className="toggle-container">
                                  <button
                                    className={`toggle-button ${
                                      activeOptions[index] === "intraday"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleOptionChange(index, "intraday")
                                    }>
                                    Intraday
                                  </button>
                                  <button
                                    className={`toggle-button ${
                                      activeOptions[index] === "normal"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleOptionChange(index, "normal")
                                    }>
                                    Normal
                                  </button>
                                </div>
                              </td>
                              <td className="basket-table-cell">
                                <div className="price d-flex align-items-center justify-content-between px-2">
                                  <input
                                    type="checkbox"
                                    checked={checkboxStates[index]}
                                    onChange={() => handleCheckboxChange(index)}
                                  />
                                  {checkboxStates[index] ? (
                                    <div style={{ color: "#383FEC" }}>
                                      MARKET
                                    </div>
                                  ) : (
                                    <input
                                      type="text"
                                      value={item.price}
                                      className="price-input pe-1"
                                      onChange={(e) =>
                                        handlePriceChange(e, index)
                                      } // Add onChange handler
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className="creator-bottom d-flex mb-2 gap-2 flex-column border-top "
                style={{ padding: "12px 12px", marginTop: "auto" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-2">
                    <span className="creator-bottom-title">
                      Margin available
                    </span>
                    <span className="creator-bottom-value">
                      {" "}
                      ₹{" "}
                      {formatNumberWithCommasAndFontWeight(
                        fundsData.marginAvailable
                      )}
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <span className="creator-bottom-title">
                      Charges{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none">
                        <path
                          d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                          stroke="#555555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                    <span className="creator-bottom-value">-</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-2">
                    <span className="creator-bottom-title">
                      Estmtd margin{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none">
                        <path
                          d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                          stroke="#555555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                    <span className="creator-bottom-value">
                      {formatNumberWithCommasAndFontWeight(marginRequired)}
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <span className="creator-bottom-title">
                      Premium{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none">
                        <path
                          d="M6.99992 8.16669V7.7172C6.99992 7.1379 7.44483 6.65972 7.94099 6.3607C8.42 6.07201 8.87738 5.55887 8.74992 4.66666C8.49992 2.91665 6.12492 3.79167 6.12492 3.79167M6.99992 9.91667V10.2083M13.4166 7C13.4166 10.5438 10.5437 13.4167 6.99992 13.4167C3.45609 13.4167 0.583252 10.5438 0.583252 7C0.583252 3.45617 3.45609 0.583332 6.99992 0.583332C10.5437 0.583332 13.4166 3.45617 13.4166 7Z"
                          stroke="#555555"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                    </span>
                    <span className="creator-bottom-value">₹ {premium}</span>
                  </div>
                </div>
              </div>
              <div
                className="d-flex mt-3 border-top justify-content-between gap-3"
                style={{
                  padding: "12px",
                  marginTop: "auto",
                }}>
                <button
                  className="clear-basket-btn bg-transparent w-50"
                  onClick={handleExecuteVirtualOrder}>
                  Add virtual position
                </button>
                <button
                  className="basket-order-btn w-50"
                  onClick={handleExecuteOrder}>
                  Execute all orders
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BasketMobile;
