import React, { useEffect, useState } from "react";

const ScreenerModal = ({ parentCallback }) => {
  // const [isBuy, setIsBuy] = useState(true);
  // const [isCALL, setIsCALL] = useState(true);
  //for dropdowns
  // const [selectedExpiy, setSelectedExpiry] = useState("Current Week");
  // const [selectedCondition, setSelectedCondition] = useState("Delta");
  const [isRowEnabled, setIsRowEnabled] = useState(true); // State variable to track row enable/disable
  const [rows, setRows] = useState([
    {
      isEnabled: true,
      hasTick: false,
      data: {
        isBuy: true,
        isCALL: true,
        selectedExpiry: "Current Week",
        lotsValue: 1,
        selectedCondition: "delta",
        inputValue: 15,
      },
    },
  ]);

  const handleWeekItemClick = (index, item) => {
    // console.log("Updating expiry for index", index);s
    const updatedRows = [...rows];
    updatedRows[index].data.selectedExpiry = item;
    setRows(updatedRows);
  };

  const handleOptionItemClick = (index, item) => {
    const updatedRows = [...rows];
    updatedRows[index].data.selectedCondition = item;
    setRows(updatedRows);
  };

  // Effect hook to enable the row when the component mounts
  useEffect(() => {
    setIsRowEnabled(true);
  }, []);

  const toggleRow = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].isEnabled = !updatedRows[index].isEnabled;
    setRows(updatedRows);
  };

  const toggleIcon = (index) => {
    const updatedRows = [...rows];

    if (updatedRows[index].hasTick) {
      updatedRows.splice(index, 1);
    } else {
      updatedRows[index].hasTick = true;
      updatedRows.splice(index + 1, 0, {
        isEnabled: false,
        hasTick: false,
        data: {
          isBuy: true,
          isCALL: true,
          selectedExpiry: "Current Week",
          lotsValue: 1,
          selectedCondition: "delta",
          inputValue: 15,
        },
      });
    }
    setRows(updatedRows);
  };

  const updateIsBuy = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].data.isBuy = !updatedRows[index].data.isBuy;
    setRows(updatedRows);
  };

  const updateIsCALL = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].data.isCALL = !updatedRows[index].data.isCALL;
    setRows(updatedRows);
  };

  // const updateSelectedExpiry = (index, value) => {
  //   console.log("Updating expiry for index", index, "to", value);
  //   const updatedRows = [...rows];
  //   updatedRows[index].selectedExpiry = value;
  //   setRows(updatedRows);
  // };

  // const updateSelectedCondition = (index, value) => {
  //   const updatedRows = [...rows];
  //   updatedRows[index].selectedCondition = value;
  //   setRows(updatedRows);
  // };

  const updateLotsValue = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].data.lotsValue = value;
    setRows(updatedRows);
  };

  const updateInputValue = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].data.inputValue = value;
    setRows(updatedRows);
  };
  // to store the generated array of objects
  const [generatedData, setGeneratedData] = useState([]);

  // Function to generate the array of objects
  const generateArray = () => {
    const newArray = rows.map((row) => row);
    parentCallback(newArray);
  };

  return (
    <div
      className="modal fade screener-modal"
      id="staticBackdropScreener"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="screener-name d-flex align-items-center justify-content-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="25"
                viewBox="0 0 18 25"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 0L0 15.75H8.99993V24.75L17.9999 9.00002H9V0Z"
                  fill="url(#paint0_linear_820_6681)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_820_6681"
                    x1="8.99997"
                    y1="0"
                    x2="8.99997"
                    y2="24.75"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF3908" />
                    <stop offset="1" stopColor="#FF7834" />
                  </linearGradient>
                </defs>
              </svg>{" "}
              Auto find
            </p>
            <button
              type="button"
              className="btn-close mb-2"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <div className="modal-body screener-modal-container p-0">
            <table className="screener-moadal-table">
              <thead>
                <tr className="d-flex align-items-center justify-content-start gap-4 text-start screener-tbl-header">
                  <th scope="col"></th>
                  <th scope="col" className="ps-4">
                    B/S
                  </th>
                  {/* <th scope="col" className="text-start ">
                    Expiry
                  </th> */}
                  <th scope="col" className="ps-2">
                    <span className="">Type</span>
                  </th>
                  <th scope="col">Lots</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr
                    key={index}
                    style={{ padding: "0.5px 1rem" }}
                    className={`d-flex align-items-center justify-content-between gap-1 text-center ${
                      row.isEnabled ? "" : "disabled-row"
                    }`}>
                    <td className={` text-center`}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={row.isEnabled}
                        onChange={() => toggleRow(index)}
                        style={{
                          width: "16px",
                          height: "16px",
                          cursor: "pointer",
                        }}
                      />
                    </td>
                    <td
                      className={`text-center ${
                        row.isEnabled ? "" : "disabled"
                      }`}>
                      <div
                        className="bs-value d-flex align-items-center justify-content-center"
                        style={{
                          height: "20px",
                          width: "20px",
                          background: row.data.isBuy ? "#70D11F" : "#FF3908",
                          color: "white",
                        }}
                        onClick={() => updateIsBuy(index)}
                        role="button">
                        {row.data.isBuy ? "B" : "S"}
                      </div>
                    </td>

                    {/* <td className={`${row.isEnabled ? "" : "disabled"}`}>
                      <div className="dropdown modal-dropdown screnner-modal-dropdown screening-condition ">
                        <button
                          className="btn dropdown-toggle text-start expiry-dropdown-screener "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          {row.data.selectedExpiry}
                        </button>
                        <ul className="dropdown-menu">
                          <li
                            className="pb-1"
                            onClick={() =>
                              handleWeekItemClick(index, "Current Week")
                            }>
                            Current Week
                          </li>
                          <li
                            className="pb-1"
                            onClick={() =>
                              handleWeekItemClick(index, "Current Month")
                            }>
                            Current Month
                          </li>
                        </ul>
                      </div>
                    </td> */}

                    <td className={` ${row.isEnabled ? "" : "disabled"}`}>
                      <div
                        className="ce d-flex align-items-center justify-content-center"
                        style={{
                          color: row.data.isCALL ? "#E2A838" : "#757AFA",
                          width: "30px",
                          height: "30px",
                          borderRadius: "4px",
                          border: `1px solid ${
                            row.data.isCALL ? "#E2A838" : "#757AFA"
                          }`,
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "0px",
                        }}
                        role="button"
                        onClick={() => updateIsCALL(index)}>
                        {row.data.isCALL ? "CE" : "PE"}
                      </div>
                    </td>
                    {/* Lots */}
                    <td className={` ${row.isEnabled ? "" : "disabled"}`}>
                      <div className="strategy-lots d-flex align-items-center justify-content-between px-2">
                        <span
                          role="button"
                          onClick={() =>
                            updateLotsValue(
                              index,
                              Math.max(0, row.data.lotsValue - 1)
                            )
                          }
                          style={{
                            color: "#C1C1C1",
                            fontSize: "20px",
                            fontWeight: "500",
                            lineHeight: "0px",
                          }}>
                          -
                        </span>
                        <span>{row.data.lotsValue}</span>
                        <span
                          role="button"
                          onClick={() =>
                            updateLotsValue(index, row.data.lotsValue + 1)
                          }
                          className="plus"
                          style={{
                            color: "#C1C1C1",
                            fontSize: "20px",
                            fontWeight: "500",
                            lineHeight: "0px",
                          }}>
                          +
                        </span>
                      </div>
                    </td>
                    <td className={` ${row.isEnabled ? "" : "disabled"}`}>
                      <div className="dropdown modal-dropdown screnner-modal-dropdown screening-condition">
                        <button
                          className="btn dropdown-toggle text-start greek-dropdown-screener"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          {row.data.selectedCondition}
                        </button>
                        <ul className="dropdown-menu ">
                          <li
                            className="pb-1"
                            onClick={() =>
                              handleOptionItemClick(index, "delta")
                            }>
                            Delta
                          </li>
                          <li
                            className="pb-1"
                            onClick={() =>
                              handleOptionItemClick(index, "theta")
                            }>
                            Theta
                          </li>
                          <li
                            className="pb-1"
                            onClick={() =>
                              handleOptionItemClick(index, "gamma")
                            }>
                            Gamma
                          </li>
                          <li
                            className="pb-1"
                            onClick={() =>
                              handleOptionItemClick(index, "vega")
                            }>
                            Vega
                          </li>
                          {/* <li
                            className="pb-1"
                            onClick={() => handleOptionItemClick(index, "ATM")}>
                            ATM
                          </li>
                          <li
                            className="pb-1"
                            onClick={() => handleOptionItemClick(index, "ITM")}>
                            ITM
                          </li>
                          <li
                            className="pb-1"
                            onClick={() => handleOptionItemClick(index, "OTM")}>
                            OTM
                          </li> */}
                        </ul>
                      </div>
                    </td>
                    <td className={` ${row.isEnabled ? "" : "disabled"}`}>
                      <input
                        type="number"
                        value={row.data.inputValue}
                        className="scr-con d-flex align-items-center justify-content-between px-2 text-center"
                        onChange={(e) =>
                          updateInputValue(index, e.target.value)
                        }
                      />
                    </td>
                    <td className={` ${row.isEnabled ? "" : "disabled"}`}>
                      <span
                        role="button"
                        onClick={() => toggleIcon(index)}
                        style={{ cursor: "pointer" }}>
                        {row.hasTick ? (
                          <svg
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none">
                            <path
                              d="M12.6364 3.90909V3.18182C12.6364 1.97683 11.6595 1 10.4545 1H7.54545C6.34047 1 5.36364 1.97683 5.36364 3.18182V3.90909M1 3.90909H17M11.1818 8.27273V11.9091M6.81818 8.27273V11.9091M2.45455 3.90909H15.5455L15.0636 13.5452C14.9669 15.4805 13.3695 17 11.4318 17H6.56818C4.63045 17 3.03312 15.4805 2.93635 13.5452L2.45455 3.90909Z"
                              stroke="#C1C1C1"
                              strokeLinecap="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none">
                            <path
                              d="M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
                              fill="#70D11F"
                            />
                            <path
                              d="M9.72727 12.9451L12.0414 15.2593L16.6698 10.631M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="modal-footer">
            <div className="d-flex gap-3">
              {/* <button className="cancel">Screen</button> */}
              <button className="saveandscreen" onClick={generateArray}>
                Screen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenerModal;
